import React from "react";
//materialUi
import { Typography, Grid, Stack } from "@mui/material";
import CustomSwitch from "./CustomSwitch";

function PolarQuestions(props) {
  const { question } = props;
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={6}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="font12">{question}</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="overline">NO</Typography>
        <CustomSwitch {...props} />
        <Typography variant="overline">YES</Typography>
      </Stack>
    </Grid>
  );
}

export default PolarQuestions;
