import React from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  styled,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));

function NNAProfileMatch({ setIsProfileMatched, setIsFetchProfile }) {
  const isLoader = useStateUpdate(false);
  const yesOrNo = useStateUpdate("yes");

  const fetchProfile = () => {
    setIsProfileMatched(false);
    setIsFetchProfile(yesOrNo.state === "yes");
  };

  return (
    <Container maxWidth="md">
      <Box
        mt={{ xs: 10, sm: 6, md: 5 }}
        p={4}
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} sm={12} md={12} mb={2}>
          <Item elevation={0}>
            <Typography variant="font20b" sx={{ mb: 5 }}>
              Account match found
            </Typography>
            <Typography variant="body2" color="#686868" sx={{ mb: 1, mt: 1 }}>
              We found a match for your email in NNA. Do you want to import the
              data from NNA?
            </Typography>
            <FormControl>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={yesOrNo.state}
                onChange={(e) => yesOrNo.update(e.target.value)}
              >
                <Stack direction="row" spacing={2}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              disabled={isLoader.state}
              onClick={(e) => {
                fetchProfile();
              }}
            >
              <Typography variant="buttonLabel" mx={4}>
                Continue
              </Typography>
              {isLoader.state && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    marginLeft: "-10px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Grid>
      </Box>
    </Container>
  );
}

export default NNAProfileMatch;
