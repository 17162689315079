import React from "react";
import { Box, Modal, Button, Typography, Grid } from "@mui/material/";
import { largeModalStyle } from "components/common/Styles";
import OwnedOrganization from "./OwnedOrganization";

function AdminOrganizationModal(props) {
  const {
    open,
    organizationId,
    organization,
    fetchOrganizationList,
    fetchOrganizationMembersList,
  } = props;

  const handleClose = () => {
    open.update(false);
    organizationId.update("");
  };

  return (
    <Modal open={open.state} onClose={handleClose}>
      <Box sx={largeModalStyle}>
        <OwnedOrganization
          organization={organization}
          fetchOrganizationList={fetchOrganizationList}
          fetchOrganizationMembersList={fetchOrganizationMembersList}
        />
        <Grid container justifyContent="flex-end" mt={2}>
          <Button variant="contained" size="small" onClick={handleClose}>
            <Typography variant="font14b" color="#000">
              Close
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}

export default AdminOrganizationModal;
