import { Badge } from "@mui/material";
import nssBanner from "assets/images/nss-banner.png";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { DRAWER_WIDTH } from "./Constant";
import { makeStyles } from "@material-ui/core";
const drawerWidth = DRAWER_WIDTH;

export const bannerStyle = {
  paperContainer: {
    backgroundImage: `url(${nssBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
};

export const iconButton = {
  border: {
    border: "solid",
    borderRadius: "5px",
    borderWidth: "thin",
    width: "25px",
    height: "25px",
  },
};

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: theme.spacing(1, 0, 0, 0),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2),
  backgroundColor: "#00000080",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const menuProps = {
  paperProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  transformOrigin: { horizontal: "right", vertical: "top" },
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
};

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export const largeModalStyle = {
  position: "absolute",
  top: "50%",
  left: { xs: "50%", md: "55%" },
  overflowY: "auto",
  maxHeight: "85%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "65%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
export const smallModalStyle = {
  position: "absolute",
  top: "50%",
  left: { xs: "50%", md: "55%" },
  overflowY: "auto",
  maxHeight: "85%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "50%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const rowHoverClickStyle = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
