import React, { Fragment, useContext, useEffect } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
//materialUi
import {
  Button,
  Stack,
  Typography,
  Grid,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
//icons
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircle, Cancel } from "@material-ui/icons";
//validation
import { isEmptyString } from "components/common/FormValidator";
//style
import { iconButton } from "components/common/Styles";
//service
import { sendSmsOtp, verifyPhone } from "service/ProfileService";

import { formatPhoneNumber } from "components/common/FormatPhoneNumber";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { useIsAdmin } from "components/common/GetUserDetail";
import { EditOutlined } from "@material-ui/icons";
import { isValidPhoneNumber } from "components/common/FormValidator";
import { appendCountryCode } from "components/common/FormatPhoneNumber";
import { updateProfile } from "service/ProfileService";

function PhoneNumber({ checkPercentage }) {
  const isAdmin = useIsAdmin();
  const profileValue = useContext(ProfileContext);
  const { profileDatas, fetchProfileData } = profileValue;
  const phoneNumber = useStateUpdate("");
  const changePhoneNumber = useStateUpdate("");
  const phoneVerifiedAt = useStateUpdate("");
  const otp = useStateUpdate("");
  const otpValidationError = useStateUpdate(false);
  const isSendOtp = useStateUpdate(false);
  const isEditPhoneNumber = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const phoneError = useStateUpdate("");

  useEffect(() => {
    if (isNotEmpty(profileDatas)) {
      phoneNumber.update(profileDatas.smsNumber ?? "");
      phoneVerifiedAt.update(profileDatas.phoneVerifiedAt ?? "");
    }
  }, [profileDatas]);

  useEffect(() => {
    changePhoneNumber.update(formatPhoneNumber(phoneNumber.state));
  }, [phoneNumber.state]);

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newPhoneError = "";
    if (!isValidPhoneNumber(changePhoneNumber.state)) {
      newPhoneError = "Phone Number is not valid";
      isValidated = false;
    }
    phoneError.update(newPhoneError);
    return isValidated;
  }

  async function updatePersonalDetails(e) {
    e.preventDefault();
    let data = {
      primaryNumber: profileDatas.primaryNumber,
      officeNumber: profileDatas.officeNumber,
      afterHoursNumber: profileDatas.afterHoursNumber,
      smsNumber: appendCountryCode(changePhoneNumber.state),
      profileCompletionPercentage: checkPercentage(),
    };
    var response = await updateProfile(profileDatas.profileId, data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        fetchProfileData();
        isEditPhoneNumber.update(false);
        calltoast("success", response.data.message);
      } else {
        calltoast("error", response.data.message);
      }
    } else {
      calltoast("error", response.data.message);
    }
  }

  function checkOtpValidatorOnSubmit() {
    let isValidated = true;
    if (isEmptyString(otp.state)) {
      otpValidationError.update(true);
      isValidated = false;
    }
    return isValidated;
  }

  const sendOtp = async () => {
    var response = await sendSmsOtp(profileDatas.profileId);
    if (response.status === 200) {
      isSendOtp.update(!isSendOtp.state);
      calltoast("success", response.data.message);
    } else {
      calltoast("error", response.data.message);
    }
  };

  function getVerifydata() {
    var getVerifyData = {
      profileId: profileDatas.profileId,
      secretCode: otp.state,
    };
    return getVerifyData;
  }

  const verifyPhoneNumber = async () => {
    let data = getVerifydata();
    var response = await verifyPhone(profileDatas.profileId, data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        isSendOtp.update(!isSendOtp.state);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else {
        calltoast("error", response.data.message);
      }
    } else {
      calltoast("error", response.data.message);
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Grid container>
      {isEditPhoneNumber.state ? (
        <Fragment>
          <Grid item xs={6}>
            <TextField
              id="sms-number"
              error={phoneError.state === "" ? false : true}
              helperText={phoneError.state}
              label={<Typography variant="font12">SMS Number</Typography>}
              inputProps={{ maxLength: 16 }}
              variant="standard"
              color="secondary"
              fullWidth
              value={changePhoneNumber.state}
              onChange={(e) => {
                phoneError.update("");
                changePhoneNumber.update(formatPhoneNumber(e.target.value));
              }}
              onBlur={(e) => {
                !isValidPhoneNumber(e.target.value) &&
                  phoneError.update("Phone Number is not valid");
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Fragment>
              <IconButton
                color="primary"
                size="small"
                onClick={(e) => {
                  checkValidatorOnSubmit() && updatePersonalDetails(e);
                }}
                sx={iconButton.border}
              >
                <DoneIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => isEditPhoneNumber.update(false)}
                sx={iconButton.border}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Fragment>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid item xs={6}>
            {isSendOtp.state ? (
              <TextField
                error={otpValidationError.state ? true : false}
                helperText={"OTP send to " + phoneNumber.state}
                fullWidth
                id="otp"
                label={<Typography variant="font12">OTP</Typography>}
                variant="standard"
                color="secondary"
                size="small"
                margin="dense"
                value={otp.state}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  otpValidationError.update(false);
                  otp.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    otpValidationError.update(true);
                  }
                }}
              />
            ) : (
              <Fragment>
                <Typography variant="font14">
                  {formatPhoneNumber(phoneNumber.state)}
                </Typography>
                {phoneNumber.state === "" ? (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      isEditPhoneNumber.update(true);
                    }}
                  >
                    <Typography variant="font11">Add SMS Number</Typography>
                  </Button>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => {
                      isEditPhoneNumber.update(true);
                    }}
                  >
                    <EditOutlined fontSize="small" />
                  </IconButton>
                )}
              </Fragment>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            {phoneVerifiedAt.state !== "" ? (
              <IconButton size="small" disabled>
                <CheckCircle fontSize="small" style={{ color: "#84D434" }} />
              </IconButton>
            ) : (
              <Stack direction="row" spacing={1}>
                {isSendOtp.state ? (
                  <Fragment>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        checkOtpValidatorOnSubmit() && verifyPhoneNumber();
                      }}
                      sx={iconButton.border}
                    >
                      <DoneIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => isSendOtp.update(!isSendOtp.state)}
                      sx={iconButton.border}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    {!isAdmin && phoneNumber.state !== "" && (
                      <Button
                        color="secondary"
                        size="small"
                        onClick={() => sendOtp()}
                      >
                        <Typography variant="font11">Verify now</Typography>
                      </Button>
                    )}

                    <IconButton size="small" disabled>
                      <Cancel fontSize="small" style={{ color: "#B21C1C" }} />
                    </IconButton>
                  </Fragment>
                )}
              </Stack>
            )}
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Grid>
  );
}

export default PhoneNumber;
