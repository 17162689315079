import React, { useEffect } from "react";

import { Typography, Box, Grid, Stack } from "@mui/material";

import AdminNotariesSummary from "./sections/AdminNotariesSummary";
import AdminNotariesTable from "./sections/AdminNotariesTable";
import { useIsAdmin } from "components/common/GetUserDetail";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

function AdminManageNotaries() {
  let navigate = useNavigate();
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search).get("search");
  const isAdmin = useIsAdmin();
  const showNotaries = useStateUpdate(searchParam ? "quickSearch" : "showall");
  const heading = useStateUpdate("Notaries");
  const quickSearchParam = useStateUpdate(searchParam ?? false);

  useEffect(() => {
    !isAdmin && navigate("/dashboard");
  }, []);

  return isAdmin ? (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Manage Notaries</Typography>
      </Grid>
      <Stack spacing={2}>
        <AdminNotariesSummary
          changeHeading={heading.update}
          showNotaries={showNotaries}
        />
        <AdminNotariesTable
          heading={heading.state}
          changeHeading={heading.update}
          showNotaries={showNotaries}
          quickSearchParam={quickSearchParam}
        />
      </Stack>
    </Box>
  ) : (
    <div />
  );
}

export default AdminManageNotaries;
