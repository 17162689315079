import React from "react";
import { Typography, Grid, Paper, Box } from "@mui/material";
import OrderTable from "components/dashboard/sections/OrderTable";

function SearchResult(props) {
  const { isSearching, searchResult, searchResultCount } = props;

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Box sx={{ p: 3, width: { xs: "90vw", md: "100%" } }}>
        <Grid mb={2}>
          {isSearching ? (
            <Typography variant="font17b">{"Searching..."}</Typography>
          ) : (
            <Typography variant="font17b">
              {searchResultCount + " Search Results"}
            </Typography>
          )}
        </Grid>
        {searchResult.length > 0 && (
          <Grid mb={2}>
            <OrderTable
              tableRows={searchResult}
              buttonColumn={false}
              label={"Search Results"}
              isSearchResult={true}
              {...props}
            />
          </Grid>
        )}
      </Box>
    </Paper>
  );
}

export default SearchResult;
