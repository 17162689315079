import "./App.css";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { basetheme } from "components/common/Theme";
import NssToolBar from "components/toolbar/NssToolbar";
import Landing from "components/landing/Landing";
import LogIn from "components/login/LogIn";
import Registration from "components/registration/Registration";
import MainDrawer from "components/main_drawer/MainDrawer";
import Profile from "components/profile/Profile";
import ProtectedRoute from "components/common/ProtectedRoute";
import UnProtectedRoute from "components/common/UnProtectedRoute";
import { UserContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import OrderSearch from "components/orderSearch/OrderSearch";
import Notifications from "components/notifications/Notifications";
import NotificationsPreferences from "components/notifications/NotificationsPreferences";
import OrderDetails from "components/orderDetails/OrderDetails";
import PrintOrderSummary from "components/print_order_summary/PrintOrderSummary";
import NoteTemplates from "components/note_templates/NoteTemplates";
import OrderCompletion from "components/order_completion/OrderCompletion";
import SessionTimeout from "components/common/SessionTimeout";
import ForgotPassword from "components/forgot_password/ForgotPassword";
import ResetPassword from "components/forgot_password/ResetPassword";
import AdminManageNotaries from "components/AdminManageNotaries/AdminManageNotaries";
import AdminManageUsers from "components/AdminManageUsers/AdminManageUsers";
import AdminAddNewUser from "components/AdminManageUsers/AdminAddNewUser";
import Organization from "components/organization/Organization";

import ChangePassword from "components/change_password/ChangePassword";
import AdminDashboard from "components/AdminDashboard/AdminDashboard";
import NotaryDashboard from "components/dashboard/NotaryDashboard";

function App() {
  const { pathname } = useLocation();
  const user = useStateUpdate([]);
  const dashboardOpen = useStateUpdate(window.innerWidth <= 900 ? false : true);
  let navigate = useNavigate();

  useEffect(() => {
    var localUser = JSON.parse(localStorage.getItem("user"));
    let userIs = [];
    localUser && (userIs = localUser);
    user.update(userIs);
  }, []);

  function setUserContext(result) {
    if (result) {
      const isAdmin = result.roles.indexOf("admin") > -1;
      user.update(result);
      isAdmin
        ? navigate("/admin-dashboard")
        : parseFloat(result.profileCompletionPercent) >= 80
        ? navigate("/dashboard")
        : navigate("/profile");
    } else {
      user.update([]);
    }
  }

  return (
    <ThemeProvider theme={basetheme}>
      <CssBaseline />
      <SessionTimeout />
      <UserContext.Provider value={user.state ? user.state : []}>
        {pathname.indexOf("/orders/summary/") > -1 ? null : (
          <NssToolBar open={dashboardOpen} />
        )}
        <Routes>
          <Route
            path="/"
            element={
              <UnProtectedRoute>
                <Landing />
              </UnProtectedRoute>
            }
          />
          <Route path="/login" element={<LogIn onLogin={setUserContext} />} />
          <Route
            path="/forgot-password"
            element={
              <UnProtectedRoute>
                <ForgotPassword />
              </UnProtectedRoute>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <UnProtectedRoute>
                <ResetPassword />
              </UnProtectedRoute>
            }
          />
          <Route
            path="/registration"
            element={
              <UnProtectedRoute>
                <Registration />
              </UnProtectedRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <Profile />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/:profileId"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <Profile />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <NotaryDashboard />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/manage-notaries"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <AdminManageNotaries />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <AdminDashboard />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/manage-users"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <AdminManageUsers />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/manage-users/add-new-user"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <AdminAddNewUser />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <OrderSearch />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <Notifications />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications/preferences"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <NotificationsPreferences />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:orderId"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <OrderDetails />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:orderId/completion"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <OrderCompletion />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/summary/:orderId"
            element={
              <ProtectedRoute>
                <PrintOrderSummary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notetemplates"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <NoteTemplates />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
          <Route
            path="/organization"
            element={
              <ProtectedRoute>
                <MainDrawer open={dashboardOpen}>
                  <Organization />
                </MainDrawer>
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
