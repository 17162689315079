import React, { Fragment } from "react";
import {
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import MultipleUpload from "./MultipleUpload";
import { useStateUpdate } from "UseStateUpdate";
import { UPLOAD_DOCUMENT_TYPES as uploadDocumentTypes } from "components/common/Constant";

function AddDocuments({ open }) {
  const selectedDocumentType = useStateUpdate(uploadDocumentTypes[0]);

  return (
    <Fragment>
      <Grid pb={1}>
        <Typography variant="font15b">Upload documents</Typography>
      </Grid>
      <Divider />
      <Grid container pt={3}>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="font15b">Document type</Typography>
            <FormControl
              variant="standard"
              color="secondary"
              sx={{ width: { sm: "60%" } }}
            >
              <InputLabel>Select document type</InputLabel>
              <Select
                label={
                  <Typography variant="font12">Select document type</Typography>
                }
                value={selectedDocumentType.state}
                onChange={(e) => {
                  selectedDocumentType.update(e.target.value);
                }}
              >
                {uploadDocumentTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      <Grid py={3}>
        <MultipleUpload />
      </Grid>
      <Divider />
      <Grid container py={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems="center"
        >
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => open.update(false)}
            >
              <Typography variant="font14b" color="#000" mx={{ xs: 0, sm: 3 }}>
                Cancel
              </Typography>
            </Button>
            <Button variant="contained" size="small" onClick={(event) => {}}>
              <Typography variant="font14b" mx={{ xs: 0, sm: 3 }}>
                Upload documents
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AddDocuments;
