export const getProfileId = () => {
  return JSON.parse(localStorage.getItem("user")).profile_id;
};

export const shouldChangePassword = () => {
  return JSON.parse(localStorage.getItem("user")).shouldChangePassword;
};

export const profileCompletionPercent = () => {
  return JSON.parse(localStorage.getItem("user")).profileCompletionPercent;
};

export const useIsAdmin = () => {
  try {
    return JSON.parse(localStorage.getItem("user")).roles.indexOf("admin") > -1
      ? true
      : false;
  } catch {
    return false;
  }
};

export const useIsNotary = () => {
  try {
    return JSON.parse(localStorage.getItem("user")).roles.indexOf("notary") > -1
      ? true
      : false;
  } catch {
    return false;
  }
};

export const roleAccess = (roles) => {
  let canAccess = false;
  roles.forEach((role) => {
    if (JSON.parse(localStorage.getItem("user")).roles.indexOf(role) > -1)
      canAccess = true;
  });

  return canAccess;
};
