import React, { useContext, useEffect } from "react";
import { ProfileContext } from "AppContext";
//materialUi
import {
  Stack,
  Button,
  Typography,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
//icons
import { CheckCircle, Cancel } from "@material-ui/icons";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { useIsAdmin } from "components/common/GetUserDetail";
import { useStateUpdate } from "UseStateUpdate";

function Email() {
  const isAdmin = useIsAdmin();
  const profileValue = useContext(ProfileContext);
  const { profileDatas } = profileValue;
  const emailSha = useStateUpdate("");
  const emailVerifiedAtSha = useStateUpdate("");

  useEffect(() => {
    if (isNotEmpty(profileDatas)) {
      emailSha.update(profileDatas.eMail ?? "");
      emailVerifiedAtSha.update(profileDatas.emailVerifiedAt ?? "");
    }
  }, [profileDatas]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography variant="font14">{emailSha.state}</Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end">
        {emailVerifiedAtSha.state !== "" ? (
          <IconButton size="small" disabled>
            <CheckCircle fontSize="small" style={{ color: "#84D434" }} />
          </IconButton>
        ) : (
          <Stack direction="row" spacing={1}>
            {!isAdmin && (
              <Button color="secondary" size="small">
                <Typography variant="font11">Verify now</Typography>
              </Button>
            )}
            <IconButton size="small" disabled>
              <Cancel fontSize="small" style={{ color: "#B21C1C" }} />
            </IconButton>
          </Stack>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default Email;
