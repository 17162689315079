import { authFetch, login } from "./AuthProvider";
import axios from "components/common/Axios";

const SERVICE_URL = "";

export const loginUser = async (userData) => {
  return await axios
    .post("/login", userData)
    .then(function (response) {
      if (response.data.code === 200) {
        let userData = response.data.data;
        localStorage.setItem("user", JSON.stringify(userData));
        let session = {
          //refreshToken: userData.refreshToken,
          accessToken: userData.access_token,
        };
        login(session);
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const forgotPasswordSendMail = async (data) => {
  let url = SERVICE_URL + "/forgot-password";
  return await authFetch("post", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const resetPassword = async (data) => {
  let url = SERVICE_URL + "/reset-password";
  return await authFetch("post", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateToken = async (refreshToken) => {
  let userId = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).userId
    : null;
  let data = {
    refreshToken: refreshToken,
    userId: userId,
  };
  return await axios
    .post(SERVICE_URL + "/renew-token", data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return {};
    });
};

export const resendOtp = async (body) => {
  return await axios
    .post(SERVICE_URL + "/resend-email-otp", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
