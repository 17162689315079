import axios from "components/common/Axios";
import { getToken } from "./TokenProvider";

const SERVICE_URL = "/document-service/v1/profiles/";

export const uploadFile = async (profileId, fileToUpload, fileType) => {
  const token = await getToken();
  const adminId = localStorage.getItem("adminId");
  const header = adminId
    ? {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
        "X-Admin": adminId,
      }
    : {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      };
  var formData = new FormData();
  formData.append("document", fileToUpload);
  formData.append("fileType", fileType);
  let url = SERVICE_URL + profileId + "/document-types/upload";
  return await axios({
    method: "post",
    url: url,
    data: formData,
    headers: header,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const downloadFile = async (profileId, documentId, fileName) => {
  const token = await getToken();
  const adminId = localStorage.getItem("adminId");
  const header = adminId
    ? {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
        "X-Admin": adminId,
      }
    : {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      };
  let url =
    SERVICE_URL +
    profileId +
    "/documents/" +
    documentId +
    "/download?fileType=profiles";
  return await axios({
    method: "get",
    url: url,
    responseType: "blob",
    headers: header,
  })
    .then(function (response) {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return response;
    })
    .catch(function (error) {
      return {};
    });
};
