import React from "react";
import {
  Paper,
  Box,
  Typography,
  Stack,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import OrderModal from "./OrderModal";
import DoneIcon from "@mui/icons-material/Done";
import ListOrderNotes from "./ListOrderNotes";

function Notes({ orderDetails }) {
  const openModal = useStateUpdate(false);

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <OrderModal
        open={openModal}
        orderDetails={orderDetails}
        type={"addNote"}
      />
      <Box sx={{ width: "100%" }} p={2}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="font15b" color="#686868">
              Notes
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            {orderDetails.notes && (
              <Button variant="text" onClick={() => {}}>
                <DoneIcon fontSize="small" sx={{ color: "#000" }} />
                <Typography variant="font12" color="#000" noWrap>
                  Acknowledge all
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Stack spacing={1} py={2}>
          {orderDetails.notes && <ListOrderNotes notes={orderDetails.notes} />}
        </Stack>
        {orderDetails.notes && (
          <Button variant="text" onClick={() => openModal.update(true)}>
            <Typography variant="font14" color="#000">
              + Add note
            </Typography>
          </Button>
        )}
      </Box>
    </Paper>
  );
}

export default Notes;
