import React, { Fragment } from "react";
import {
  Typography,
  Button,
  Stack,
  Grid,
  IconButton,
  Divider,
} from "@mui/material/";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStateUpdate } from "UseStateUpdate";
import FileTypeIcon from "components/profile/credentials/sections/FileTypeIcon";

function MultipleUpload() {
  const files = useStateUpdate([]);

  const handleChange = (event) => {
    let newFiles = Array.from(event.target.files || event.dataTransfer.files);
    if (!newFiles.length) return;
    files.update([...files.state, ...newFiles]);
  };

  const handleDelete = (index, deleted) => {
    files.update(files.state.filter((f) => f !== deleted));
  };

  return (
    <Stack>
      <Grid>
        <label>
          <input
            accept=""
            type="file"
            onChange={handleChange}
            style={{ display: "none" }}
            multiple
          />
          <Button
            variant="contained"
            component="span"
            color="secondary"
            sx={{ width: "100%" }}
          >
            <Typography variant="containedbuttonLabel" mx={2}>
              Browse files to upload
            </Typography>
          </Button>
        </label>
      </Grid>

      <Stack pt={5}>
        {files.state.map((file, index) => (
          <Fragment key={index}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={2}>
                <FileTypeIcon fileName={file.name} />
                <Typography variant="font12">{file.name}</Typography>
              </Stack>
              <IconButton
                onClick={() => handleDelete(index, file)}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Divider />
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
}

export default MultipleUpload;
