import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { updatePassword } from "service/ProfileService";
import { isValidPassword } from "components/common/FormValidator";
import { getProfileId } from "components/common/GetUserDetail";
import { shouldChangePassword } from "components/common/GetUserDetail";
import { profileCompletionPercent } from "components/common/GetUserDetail";

function ChangePassword() {
  const newPassword = useStateUpdate("");
  const showNewPassword = useStateUpdate(false);
  const confirmNewPassword = useStateUpdate("");
  const showConfirmNewPassword = useStateUpdate(false);
  const currentPassword = useStateUpdate("");
  const showCurrentPassword = useStateUpdate(false);
  const passwordHelperText = useStateUpdate(
    "Password must contain minimum 8 characters"
  );
  let navigate = useNavigate();

  useEffect(() => {
    !shouldChangePassword() &&
      navigate(profileCompletionPercent() >= 80 ? "/dashboard" : "/profile");
  }, []);

  const validationError = useStateUpdate({
    currentPassword: "",
    newPassword: false,
    confirmNewPassword: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      currentPassword: "",
      newPassword: false,
      confirmNewPassword: "",
    };
    if (!isValidPassword(newPassword.state)) {
      newValidationError = {
        ...newValidationError,
        newPassword: true,
      };
      isValidated = false;
    } else if (newPassword.state !== confirmNewPassword.state) {
      newValidationError = {
        ...newValidationError,
        confirmNewPassword: "Password not match",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  function getPasswordData() {
    var data = {
      currentPassword: currentPassword.state,
      newPassword: newPassword.state,
      confirmNewPassword: confirmNewPassword.state,
    };
    return data;
  }

  const changePassword = async () => {
    let data = getPasswordData();
    var response = await updatePassword(getProfileId(), data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        var userData = JSON.parse(localStorage.getItem("user"));
        userData = { ...userData, shouldChangePassword: false };
        localStorage.setItem("user", JSON.stringify(userData));
        navigate(profileCompletionPercent() >= 80 ? "/dashboard" : "/profile");
      } else if (response.data.code === 422) {
        validationError.update({
          ...validationError.state,
          currentPassword: response.data.error.currentPassword[0],
        });
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper>
        <Box mt={10} py={5} px={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={1}
          >
            <Typography variant="font20b">Change Password?</Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={3}
          >
            <Typography variant="font14" color={"error"}>
              Force reset password initiated by Admin.
            </Typography>
            <Typography variant="font14" color={"error"}>
              Please change your password.
            </Typography>
          </Grid>

          <Grid item xs={12} mb={3}>
            <Stack spacing={3}>
              <FormControl variant="standard" color="secondary">
                <InputLabel
                  error={validationError.state.currentPassword ? true : false}
                >
                  {<Typography variant="font12">Current Password*</Typography>}
                </InputLabel>
                <Input
                  id="current-password"
                  size="small"
                  error={validationError.state.currentPassword ? true : false}
                  type={showCurrentPassword.state ? "text" : "password"}
                  value={currentPassword.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      currentPassword: "",
                    });
                    currentPassword.update(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          showCurrentPassword.update(!showCurrentPassword.state)
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showCurrentPassword.state ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={
                    validationError.state.currentPassword === "" ? false : true
                  }
                >
                  {validationError.state.currentPassword}
                </FormHelperText>
              </FormControl>
              <FormControl variant="standard" color="secondary">
                <InputLabel
                  error={validationError.state.newPassword ? true : false}
                >
                  {<Typography variant="font12">New Password*</Typography>}
                </InputLabel>
                <Input
                  id="new-password"
                  size="small"
                  error={validationError.state.newPassword ? true : false}
                  type={showNewPassword.state ? "text" : "password"}
                  value={newPassword.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      newPassword: false,
                    });
                    newPassword.update(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          showNewPassword.update(!showNewPassword.state)
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showNewPassword.state ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={validationError.state.newPassword ? true : false}
                >
                  {
                    <Typography variant="font11">
                      {passwordHelperText.state}
                    </Typography>
                  }
                </FormHelperText>
              </FormControl>
              <FormControl variant="standard" color="secondary">
                <InputLabel
                  error={
                    validationError.state.confirmNewPassword === ""
                      ? false
                      : true
                  }
                >
                  {<Typography variant="font12">Confirm Password*</Typography>}
                </InputLabel>
                <Input
                  id="confirm-new-password"
                  size="small"
                  error={
                    validationError.state.confirmNewPassword === ""
                      ? false
                      : true
                  }
                  type={showConfirmNewPassword.state ? "text" : "password"}
                  value={confirmNewPassword.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      confirmNewPassword: "",
                    });
                    confirmNewPassword.update(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          showConfirmNewPassword.update(
                            !showConfirmNewPassword.state
                          )
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showConfirmNewPassword.state ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={
                    validationError.state.confirmNewPassword === ""
                      ? false
                      : true
                  }
                >
                  {validationError.state.confirmNewPassword}
                </FormHelperText>
              </FormControl>
              <Button
                variant="contained"
                onClick={(e) => {
                  checkValidatorOnSubmit() && changePassword();
                }}
              >
                <Typography variant="buttonLabel">Change</Typography>
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

export default ChangePassword;
