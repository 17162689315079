import { authFetch } from "service/AuthProvider";

const SERVICE_URL = "/organization-service/v1/organizations/";

export const addNotary = async (email, organizationId) => {
  var formData = new FormData();
  formData.append("email", email);
  let url = SERVICE_URL + organizationId + "/token";
  return await authFetch("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const acceptInvitation = async (token, profileId) => {
  var formData = new FormData();
  formData.append("token", token);
  let url = SERVICE_URL + "profiles/" + profileId + "/accept-invite";
  return await authFetch("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const leaveOrganization = async (organizationId, profileId) => {
  let url =
    SERVICE_URL +
    organizationId +
    "/profiles/" +
    profileId +
    "/leave-organization";
  return await authFetch("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const approveInvitation = async (organizationId, profileId) => {
  let url =
    SERVICE_URL +
    organizationId +
    "/profiles/" +
    profileId +
    "/approve-invitation";
  return await authFetch("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const rejectInvitation = async (organizationId, profileId) => {
  let url =
    SERVICE_URL +
    organizationId +
    "/profiles/" +
    profileId +
    "/reject-invitation";
  return await authFetch("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const removeNotary = async (organizationId, profileId) => {
  let url =
    SERVICE_URL + organizationId + "/profiles/" + profileId + "/remove-notary";
  return await authFetch("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const makeNotaryAdmin = async (organizationId, profileId) => {
  let url =
    SERVICE_URL + organizationId + "/profiles/" + profileId + "/notary-admin";
  return await authFetch("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getOrganizationMembersList = async (profileId) => {
  let url = SERVICE_URL + "profiles/" + profileId + "/organizations-members";
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateOrganizationName = async (organizationId, body) => {
  let url = SERVICE_URL + organizationId + "/update-organization";
  return await authFetch("put", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const removeNotaryAdmin = async (organizationId, profileId) => {
  let url =
    SERVICE_URL +
    organizationId +
    "/profiles/" +
    profileId +
    "/remove-notary-admin";
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
