import React from "react";

import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { useNavigate } from "react-router-dom";

function AdminNotaryQuickSearch() {
  let navigate = useNavigate();
  const searchParam = useStateUpdate("");

  const callSearch = () => {
    var param = searchParam.state.trim();
    navigate("/dashboard/manage-notaries?search=" + param);
  };

  return (
    <Grid item xs={12} sm={3}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
          <form>
            <Stack spacing={2}>
              <Typography variant="font15b" color="#686868" noWrap>
                Notary quick search
              </Typography>

              <TextField
                fullWidth
                size="small"
                label="Name/Email"
                color="secondary"
                value={searchParam.state}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  searchParam.update(e.target.value);
                }}
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    searchParam.update("");
                  }}
                >
                  <Typography variant="font12b">Clear</Typography>
                </Button>
                <Button
                  disabled={searchParam.state === ""}
                  type="submit"
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    callSearch();
                  }}
                >
                  <Typography variant="font12b">Search</Typography>
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Paper>
    </Grid>
  );
}

export default AdminNotaryQuickSearch;
