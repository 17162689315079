import React, { Fragment } from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  FormHelperText,
  IconButton,
  InputAdornment,
  Input,
  InputLabel,
  FormControl,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { isValidPassword } from "components/common/FormValidator";
import { isEmptyString } from "components/common/FormValidator";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "service/AuthService";

function ForgotPasswordReset(props) {
  const { token } = useParams();
  const navigate = useNavigate();
  const isLoader = useStateUpdate(false);
  const email = useStateUpdate("");
  const password = useStateUpdate("");
  const showPassword = useStateUpdate(false);
  const reEnterPassword = useStateUpdate("");
  const showReEnterPassword = useStateUpdate(false);
  const passwordHelperText = useStateUpdate(
    "Password must contain minimum 8 characters"
  );
  const isResetPassword = useStateUpdate(false);
  const isErrorOccured = useStateUpdate(false);
  const validationError = useStateUpdate({
    email: "",
    password: false,
    reEnterPassword: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      email: "",
      password: false,
      reEnterPassword: "",
    };
    if (isEmptyString(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is required",
      };
      isValidated = false;
    } else if (!isValidPassword(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is not valid.",
      };
      isValidated = false;
    }
    if (!isValidPassword(password.state)) {
      newValidationError = {
        ...newValidationError,
        password: true,
      };
      isValidated = false;
    } else if (password.state !== reEnterPassword.state) {
      newValidationError = {
        ...newValidationError,
        reEnterPassword: "Password not match",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  async function callResetPassword() {
    isLoader.update(true);
    var data = {
      email: email.state,
      token: token,
      password: password.state,
      password_confirmation: reEnterPassword.state,
    };

    var response = await resetPassword(data);
    response.status === 200
      ? isResetPassword.update(true)
      : isErrorOccured.update(true);
    isLoader.update(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper>
        <Box mt={10} py={5} px={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={1}
          >
            <Typography variant="font20b">Reset Password</Typography>
          </Grid>

          <Grid item xs={12} mb={3}>
            <TextField
              disabled={isResetPassword.state}
              fullWidth
              label="Email"
              variant="standard"
              color="secondary"
              value={email.state}
              inputProps={{ maxLength: 35 }}
              helperText={
                <Typography variant="font14" color="error">
                  {validationError.state.email}
                </Typography>
              }
              onChange={(e) => {
                isErrorOccured.update(false);
                validationError.update({
                  ...validationError.state,
                  email: "",
                });
                email.update(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} mb={3}>
            <FormControl
              disabled={isResetPassword.state}
              variant="standard"
              color="secondary"
              fullWidth
            >
              <InputLabel error={validationError.state.password ? true : false}>
                <Typography variant="font14">New Password</Typography>
              </InputLabel>
              <Input
                id="password"
                error={validationError.state.password ? true : false}
                type={showPassword.state ? "text" : "password"}
                value={password.state}
                onChange={(e) => {
                  isErrorOccured.update(false);
                  validationError.update({
                    ...validationError.state,
                    password: false,
                  });
                  password.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => showPassword.update(!showPassword.state)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword.state ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={validationError.state.password ? true : false}
                id="standard-weight-helper-text"
              >
                {passwordHelperText.state}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} mb={3}>
            <FormControl
              disabled={isResetPassword.state}
              variant="standard"
              color="secondary"
              fullWidth
            >
              <InputLabel
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
              >
                <Typography variant="font14">Confirm Password</Typography>
              </InputLabel>
              <Input
                id="re-enter-password"
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
                type={showReEnterPassword.state ? "text" : "password"}
                value={reEnterPassword.state}
                onChange={(e) => {
                  isErrorOccured.update(false);
                  validationError.update({
                    ...validationError.state,
                    reEnterPassword: "",
                  });
                  reEnterPassword.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        showReEnterPassword.update(!showReEnterPassword.state)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showReEnterPassword.state ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
                id="standard-weight-helper-text"
              >
                {validationError.state.reEnterPassword}
              </FormHelperText>
            </FormControl>
          </Grid>

          {isErrorOccured.state && (
            <Grid item xs={12} mb={3}>
              <Alert severity="error">error : check email and password!</Alert>
            </Grid>
          )}

          {isResetPassword.state && (
            <Grid item xs={12} mb={3}>
              <Alert severity="success">
                Success : Password reset successfully
              </Alert>
            </Grid>
          )}

          {isResetPassword.state ? (
            <Grid container justifyContent="center">
              <Button
                variant="text"
                color="secondary"
                onClick={() => navigate("/login", { replace: true })}
              >
                <Typography variant="buttonLabel">Login Here</Typography>
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" alignItems="center">
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={isLoader.state}
                  variant="contained"
                  onClick={(e) => {
                    checkValidatorOnSubmit() && callResetPassword();
                  }}
                >
                  <Typography variant="buttonLabel" mx={3}>
                    Reset
                  </Typography>
                  {isLoader.state && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Grid>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgotPasswordReset;
