import { authFetch } from "service/AuthProvider";

const SERVICE_URL = "/configuration-service/v1/configurations";

export const getConfigurationList = async () => {
  let url = SERVICE_URL;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
