import React, { useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  Stack,
  IconButton,
  Button,
  Tab,
  Tabs,
  Divider,
  Pagination,
} from "@mui/material/";
import { largeModalStyle } from "components/common/Styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useStateUpdate } from "UseStateUpdate";
import { getBlacklists } from "service/AdminService";
import Organization from "components/organization/Organization";

function NotaryOrganizationsModal(props) {
  const { open, notaryName, profileId } = props;

  const handleClose = () => () => {
    open.update(false);
  };

  const ModalHeader = () => {
    return (
      <Fragment>
        <Grid container p={2} pl={4}>
          <Grid item xs={8}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 1, md: 2 }}
            >
              <Typography
                sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                noWrap
              >
                {notaryName + "'s Organizations"}
              </Typography>
            </Stack>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end">
            <IconButton onClick={handleClose()}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </Fragment>
    );
  };

  const ModalFooter = () => {
    return (
      <Stack p={2} direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="contained"
          size="small"
          onClick={handleClose()}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography variant="font14b">Close</Typography>
        </Button>
      </Stack>
    );
  };

  const ModalBody = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Organization profileIdIs={profileId} isAdminView={true} />
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={open.state} onClose={handleClose()}>
      <Box sx={{ ...largeModalStyle, p: 0 }}>
        <ModalHeader />
        <ModalBody />
        <ModalFooter />
      </Box>
    </Modal>
  );
}

export default NotaryOrganizationsModal;
