export const BASEPATH = "https://dev-api-notaries.notarysuite.com/api/";

export const MAPBASEURL = "https://maps.google.com/?q=";

export const DEFAULT_COUNTRY = "US";

export const DRAWER_WIDTH = 200;

export const WARNING_RED = "#cc3300";
export const WARNING_YELLOW = "#ffcc00";
export const WARNING_LITE_GREEN = "#99cc33";
export const WARNING_BLUE = "#377edb";
export const WARNING_GREEN = "#339900";
export const WARNING_ORANGE = "#FF7A00";
export const WARNING_GREY = "#777777";

export const DASHBOARD_MENU = [
  { label: "Dashboard", path: "/dashboard", roles: ["notary"] },
  { label: "Admin Dashboard", path: "/admin-dashboard", roles: ["admin"] },
  {
    label: "User management",
    subMenu: [
      {
        label: "Manage Notaries",
        path: "/dashboard/manage-notaries",
        roles: ["admin"],
      },
      {
        label: "Manage Users",
        path: "/dashboard/manage-users",
        roles: ["admin"],
      },
      {
        label: " - Add New User",
        path: "/dashboard/manage-users/add-new-user",
        roles: ["admin"],
        isSub: true,
      },
    ],
    roles: ["admin"],
  },
  {
    label: "Order management",
    subMenu: [
      {
        label: "All Orders",
        path: "/orders?status=Any",
        roles: ["admin", "notary"],
      },
      {
        label: "Open Orders",
        path: "/orders?status=Open",
        roles: ["admin", "notary"],
      },
      {
        label: "Completed Orders",
        path: "/orders?status=Closed",
        roles: ["admin", "notary"],
      },
      {
        label: "Cancelled Orders",
        path: "/orders?status=Cancelled",
        roles: ["admin", "notary"],
      },
      {
        label: "Hold Orders",
        path: "/orders?status=Hold",
        roles: ["admin", "notary"],
      },
    ],
    roles: ["admin", "notary"],
  },
  {
    label: "Templates",
    subMenu: [
      {
        label: "Note Templates",
        path: "/notetemplates",
        roles: ["notary", "admin"],
      },
    ],
    roles: ["notary", "admin"],
  },
  {
    label: "Organization management",
    path: "/organization",
    roles: ["notary", "admin"],
  },
];

export const NORMAL_PAGES = [
  "/",
  "/login",
  "/registration",
  "/forgot-password",
  "/change-password",
];

export const DOCUMENT_TYPE_CODES = {
  npcExpiration: "NPCD",
  electronicNotaryExpiration: "ENPCD",
  backgroundDocuments: "BCD",
  w9: "W9D",
  fntgDocuments: "FNTGD",
  identification: "IDD",
  eandOInsurance: "EOID",
  signingAgentCertifications: "SACD",
  attorneyBarLicense: "ABLD",
  titleInsuranceProducersLicense: "TIPLD",
  licensedClosingagent: "LCAD",
};

export const NUMBER_OF_SIGNING_CLOSED = {
  over200: "over200",
  below200: "below200",
};

export const STATES = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "DC", name: "District of Columbia" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
];

export const CERTIFICATION_CODES = {
  pavaso: "PV",
};

export const UPLOAD_DOCUMENT_TYPES = ["Document", "Id", "Scanback"];
