import React, { Fragment, useEffect } from "react";
import {
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Stack,
  Button,
  TextField,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Alert,
  Hidden,
} from "@mui/material";
import Toast from "components/common/Toast";
import StatusIcons from "components/common/StatusIcons";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArticleIcon from "@mui/icons-material/Article";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  WARNING_YELLOW,
  WARNING_GREEN,
  WARNING_BLUE,
  WARNING_RED,
} from "components/common/Constant";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as SpectrumSvg } from "assets/images/spectrum.svg";
import { useStateUpdate } from "UseStateUpdate";
import { formattedTime, formattedDate } from "components/common/GetDateAndTime";
import OpenMap from "components/common/OpenMap";
import OrderModal from "./OrderModal";
import { updateOrder } from "service/OrderService";
import { isNotEmpty } from "components/common/IsEmptyObject";

function OrderInformation({ orderDetails, fetchOrderDetails }) {
  const openModal = useStateUpdate(false);
  const modalType = useStateUpdate("");
  const signingDate = useStateUpdate("");
  const signingTime = useStateUpdate("");
  const isLoading = useStateUpdate(true);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const showAlert = useStateUpdate(false);

  useEffect(() => {
    if (isNotEmpty(orderDetails)) {
      if (orderDetails.signingDate && orderDetails.signingTime) {
        signingDate.update(
          formattedDate(
            orderDetails.signingDate + " " + orderDetails.signingTime,
            "input"
          )
        );
        signingTime.update(
          formattedTime(
            orderDetails.signingDate + " " + orderDetails.signingTime,
            "input"
          )
        );
      }
      isLoading.update(false);
    }
  }, [orderDetails]);

  async function callOrderUpdate(e) {
    e.preventDefault();
    let data = getSigningAt();
    var response = await updateOrder(orderDetails.orderId, data);
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrderDetails();
  }

  const calltoast = (severityIs, messageIs) => {
    showAlert.update(severityIs);
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const getSigningAt = () => {
    let data = {
      signingDate: formattedDate(
        signingDate.state + " " + signingTime.state,
        "input"
      ),
      signingTime:
        formattedTime(signingDate.state + " " + signingTime.state, "input") +
        ":00",
    };
    return data;
  };

  const HeadGrid = () => {
    const iconProps = { color: "#fff", fontSize: 15 };
    return (
      <Grid container pb={1}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={4}
        >
          <Typography variant="font19b" color="#686868" noWrap>
            Order Information
          </Typography>
        </Grid>
        {!isLoading.state && (
          <Fragment>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={6}
              sm={4}
            >
              <Stack direction="row" spacing={0.3}>
                <StatusIcons
                  title={"Order Status: " + orderDetails.status}
                  color={
                    orderDetails.status.toLowerCase() === "pending"
                      ? WARNING_RED
                      : orderDetails.status.toLowerCase() === "hold"
                      ? WARNING_YELLOW
                      : orderDetails.status.toLowerCase() === "open"
                      ? WARNING_BLUE
                      : WARNING_GREEN
                  }
                  icon={<MenuBookIcon style={{ ...iconProps }} />}
                />
                <StatusIcons
                  title={
                    orderDetails.signingAt
                      ? "Signing is scheduled"
                      : "Signing is not scheduled"
                  }
                  color={orderDetails.signingAt ? WARNING_GREEN : WARNING_RED}
                  icon={<InsertInvitationIcon style={{ ...iconProps }} />}
                />
                <StatusIcons
                  title={
                    orderDetails.hasDocuments
                      ? "Order has documents"
                      : "Order does not have documents"
                  }
                  color={
                    orderDetails.hasDocuments ? WARNING_GREEN : WARNING_RED
                  }
                  icon={<ArticleIcon style={{ ...iconProps }} />}
                />
                <StatusIcons
                  title={
                    orderDetails.hasNotes
                      ? "Order has notes"
                      : "Order does not have notes"
                  }
                  color={orderDetails.hasNotes ? WARNING_GREEN : WARNING_RED}
                  icon={<AttachFileIcon style={{ ...iconProps }} />}
                />
              </Stack>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={6}
              sm={4}
            >
              <Stack direction="row" spacing={1}>
                <Link
                  href="#"
                  variant="font12"
                  color="secondary.light"
                  underline="none"
                  onClick={(e) => {
                    window.open("/orders/summary/" + orderDetails.orderId);
                  }}
                >
                  Print order summary
                </Link>
                <LocalPrintshopOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#000" }}
                />
              </Stack>
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  };

  const OriginateSection = () => {
    return (
      <Grid container rowSpacing={3} pt={2}>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Originating Company:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography variant="font12b">
                {orderDetails.originatingCompany}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Originator:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Stack>
                <Typography variant="font12b">
                  {orderDetails.originatorName}
                </Typography>
                <Link
                  href={"mailto: " + orderDetails.originatorEmail}
                  variant="font12"
                  color="secondary.light"
                  underline="none"
                >
                  {orderDetails.originatorEmail}
                </Link>
                <Link
                  href={"tel:" + orderDetails.originatorPhoneNumber}
                  variant="font12"
                  color="secondary.light"
                  underline="none"
                >
                  {orderDetails.originatorPhoneNumber}
                </Link>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Scheduler:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Stack>
                <Typography variant="font12b">
                  {orderDetails.scheduler}
                </Typography>
                <Link
                  href={"mailto: " + orderDetails.schedulerEmail}
                  variant="font12"
                  color="secondary.light"
                  underline="none"
                >
                  {orderDetails.schedulerEmail}
                </Link>
                <Link
                  href={"tel:" + orderDetails.schedulerPhone}
                  variant="font12"
                  color="secondary.light"
                  underline="none"
                >
                  {orderDetails.schedulerPhone}
                </Link>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Status:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography
                variant="font12b"
                color={
                  orderDetails.status.toLowerCase() === "pending"
                    ? WARNING_RED
                    : orderDetails.status.toLowerCase() === "hold"
                    ? WARNING_YELLOW
                    : orderDetails.status.toLowerCase() === "open"
                    ? WARNING_BLUE
                    : WARNING_GREEN
                }
              >
                {orderDetails.status.toLowerCase() === "pending"
                  ? "Pending"
                  : orderDetails.status.toLowerCase() === "hold"
                  ? "On hold"
                  : orderDetails.status.toLowerCase() === "open"
                  ? "open"
                  : "closed"}
              </Typography>
            )}
          </Stack>
        </Grid>

        {isLoading.state ? (
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rectangular" width={"60%"} />
          </Grid>
        ) : (
          orderDetails.requireScanBacks && (
            <Grid item xs={12}>
              <Typography variant="font14b" color="#B31C1C">
                Scanbacks are required for this signing
              </Typography>
            </Grid>
          )
        )}
      </Grid>
    );
  };

  const SignSection = () => {
    return (
      <Grid container rowSpacing={4} py={2}>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Signer Name:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography variant="font12b">
                {orderDetails.signerName}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Signer Phone:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Link
                href={"tel:" + orderDetails.signerPhoneNumber}
                variant="font12b"
                color="secondary.light"
                underline="none"
              >
                {orderDetails.signerPhoneNumber}
              </Link>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Signer Email:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Link
                href={"mailto: " + orderDetails.signerEmail}
                variant="font12b"
                color="secondary.light"
                underline="none"
              >
                {orderDetails.signerEmail}
              </Link>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          {isLoading.state ? (
            <Skeleton animation="wave" variant="rectangular" width={"60%"} />
          ) : (
            <TextField
              id="signing-date"
              disabled={orderDetails.status.toLowerCase() === "closed"}
              label={<Typography variant="font12">Signing Date:</Typography>}
              type="date"
              size="small"
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 16 }}
              helperText={
                signingDate.state === "" ? (
                  <Typography variant="font12" color="#CC5555">
                    Schedule signing date with signer!
                  </Typography>
                ) : null
              }
              sx={{ width: { xs: "100%", sm: "90%" } }}
              value={signingDate.state}
              onChange={(e) => {
                signingDate.update(e.target.value);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack direction="row">
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <TextField
                id="signing-time"
                disabled={orderDetails.status.toLowerCase() === "closed"}
                label={<Typography variant="font12">Signing Time:</Typography>}
                type="time"
                size="small"
                variant="standard"
                color="secondary"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ maxLength: 16, step: 300 }}
                helperText={
                  signingTime.state === "" ? (
                    <Typography variant="font12" color="#CC5555">
                      Schedule signing time with signer!
                    </Typography>
                  ) : null
                }
                sx={{ width: { xs: "100%", sm: "50%" } }}
                value={signingTime.state}
                onChange={(e) => {
                  signingTime.update(e.target.value);
                }}
              />
            )}
            <Hidden only={["xs"]}>
              {showAlert.state && (
                <Alert
                  severity={showAlert.state}
                  onClose={() => {
                    showAlert.update(false);
                  }}
                >
                  <Typography variant="font12" noWrap>
                    {showAlert.state === "success"
                      ? "Date and time updated"
                      : "Updation failed"}
                  </Typography>
                </Alert>
              )}
            </Hidden>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              Signing Type:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography variant="font12b">
                {orderDetails.signingType}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Stack>
            <Typography variant="font12" color="#777">
              In/Out of Office:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography variant="font12b">
                {orderDetails.outOfOffice ? "Out of office" : "In office"}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack>
            <Typography variant="font12" color="#777">
              File Number:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography variant="font12b">
                {orderDetails.fileNumber}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Stack>
            <Typography variant="font12" color="#777">
              Ordered By:
            </Typography>
            {isLoading.state ? (
              <Skeleton animation="wave" variant="rectangular" width={"60%"} />
            ) : (
              <Typography variant="font12b" color="secondary.light">
                {orderDetails.orderedBy}
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const ButtonSection = () => {
    return (
      <Grid
        container
        py={2}
        justifyContent={{ xs: "center", sm: "flex-end" }}
        alignItems="center"
      >
        <Stack direction="row" spacing={2}>
          <Button
            disabled={
              isLoading.state || orderDetails.status.toLowerCase() === "closed"
            }
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              signingDate.update("");
              signingTime.update("");
            }}
          >
            <Typography variant="buttonLabel" mx={{ xs: 1, sm: 4 }}>
              Reset fields
            </Typography>
          </Button>

          <Button
            disabled={
              signingDate.state === "" ||
              signingTime.state === "" ||
              orderDetails.status.toLowerCase() === "closed"
            }
            size="small"
            variant="contained"
            onClick={callOrderUpdate}
          >
            <Typography variant="buttonLabel" mx={{ xs: 1, sm: 6 }}>
              Save
            </Typography>
          </Button>
        </Stack>
      </Grid>
    );
  };

  const AddressSection = () => {
    return (
      <Grid py={2}>
        <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="font14b">Addresses</Typography>
          {isLoading.state ? (
            <Skeleton animation="wave" variant="rectangular" width={"60%"} />
          ) : (
            <Stack>
              {orderDetails.signingAddress.map((address, index) => (
                <Stack key={index} direction="row" spacing={1} py={1}>
                  <InsertDriveFileOutlinedIcon sx={{ color: "#2996AE" }} />
                  <Stack>
                    <Typography
                      variant="font12"
                      sx={{ width: { xs: "100%", sm: "80%" } }}
                    >
                      {"[" +
                        address.addressType +
                        "] " +
                        address.streetAddress1 +
                        " | " +
                        // address.streetAddress2 +
                        // " | " +
                        address.city +
                        " | " +
                        address.state +
                        " " +
                        address.zip +
                        " | " +
                        address.county}
                    </Typography>
                    {address.lat && address.long && (
                      <OpenMap lat={address.lat} long={address.long} />
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}

          <Button
            disabled={isLoading.state}
            variant="text"
            onClick={() => {
              openModal.update(true);
              modalType.update("addAddress");
            }}
          >
            <Typography variant="font14" color="#000">
              + Add another address
            </Typography>
          </Button>
        </Stack>
      </Grid>
    );
  };

  const DocumentSection = () => {
    return (
      <Grid py={2}>
        <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="font14b">Documents</Typography>
          {isLoading.state ? (
            <Skeleton animation="wave" variant="rectangular" width={"60%"} />
          ) : (
            <TableContainer component={Box}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {[
                      "Document Name",
                      "Uploader",
                      "Downloads",
                      "Date",
                      "Action",
                    ].map((heading) => (
                      <TableCell key={heading}>
                        <Typography variant="font12b" color="#787878">
                          {heading}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetails.documents &&
                    orderDetails.documents.map((document, index) => (
                      <TableRow key={index}>
                        <TableCell width="1%">
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                        </TableCell>
                        <TableCell>
                          <Link
                            color="secondary.light"
                            href="#"
                            variant="font12"
                            underline="none"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {document.fileName}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font12">
                            {document.uploadedBy}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Stack direction="row" spacing={1}>
                              <Link
                                color="secondary.light"
                                href="#"
                                variant="font12"
                                underline="none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModal.update(true);
                                  modalType.update("viewDocummentActivity");
                                }}
                              >
                                {document.numberOfDownloads}
                              </Link>
                              <SvgIcon>
                                <SpectrumSvg />
                              </SvgIcon>
                            </Stack>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Stack>
                            <Typography variant="font12">
                              {formattedDate(document.uploadedAt)}
                            </Typography>
                            <Typography variant="font11">
                              {formattedTime(document.uploadedAt)}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={isLoading.state}
                            variant="outlined"
                            color="secondary"
                            size="small"
                          >
                            <Typography variant="font12">Delete</Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Button
            disabled={isLoading.state}
            variant="text"
            onClick={() => {
              openModal.update(true);
              modalType.update("addDocuments");
            }}
          >
            <Typography variant="font14" color="#000">
              + Add another document
            </Typography>
          </Button>
        </Stack>
      </Grid>
    );
  };

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      {!isLoading.state && (
        <OrderModal
          open={openModal}
          type={modalType.state}
          orderDetails={orderDetails}
          fetchOrderDetails={fetchOrderDetails}
        />
      )}
      <Box sx={{ p: 3, width: { xs: "90vw", sm: "100%" } }}>
        <Fragment>
          <HeadGrid />
          <Divider />
          <OriginateSection />
          <Divider />
          <SignSection />
          <Divider />
          <ButtonSection />
          <Divider />
          <AddressSection />
          <Divider />
          <DocumentSection />
          <Divider />
        </Fragment>
      </Box>
      <Hidden smUp>
        <Toast
          severity={severity.state}
          toastMessage={toastMessage.state}
          toastOpen={toastOpen}
        />
      </Hidden>
    </Paper>
  );
}

export default OrderInformation;
