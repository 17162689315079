import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Container,
  Box,
  Grid,
  Paper,
  Link,
  Stack,
} from "@mui/material";
import { getOrder } from "service/OrderService";
import { useStateUpdate } from "UseStateUpdate";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { formattedDate } from "components/common/GetDateAndTime";
import { formattedTime } from "components/common/GetDateAndTime";
import { formatPhoneNumber } from "components/common/FormatPhoneNumber";
import { getProfileId } from "components/common/GetUserDetail";
import { MAPBASEURL } from "components/common/Constant";
import { useIsAdmin } from "components/common/GetUserDetail";

export const createData = (label, data, type) => {
  return { label, data, type };
};

function OrderSummary() {
  const isAdmin = useIsAdmin();
  const { orderId } = useParams();
  const orderDetails = useStateUpdate({});
  const notaryFee = useStateUpdate("");

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    isNotEmpty(orderDetails.state) &&
      orderDetails.state.notaries.forEach((notary) => {
        (getProfileId() === notary.profileId || isAdmin) &&
          notaryFee.update(notary.fee);
      });
  }, [orderDetails.state]);

  const fetchOrderDetails = async () => {
    var result = await getOrder(orderId);
    result.status === 200 && orderDetails.update(result.data.data);
  };

  const GridCell = ({ data }) => {
    return (
      <Grid item xs={6} sm={4} pb={{ xs: 2, sm: 3 }}>
        <Stack spacing={1}>
          <Typography variant="font20b">{data.label}</Typography>
          {data.type === "location" ? (
            data.data.map(
              (address, index) =>
                address.lat &&
                address.long && (
                  <Link
                    key={index}
                    href={MAPBASEURL + address.lat + "," + address.long}
                    target="_blank"
                    variant="font15"
                    color="secondary.light"
                    underline="none"
                  >
                    {address.streetAddress1 +
                      ", " +
                      // address.streetAddress2 +
                      // ", " +
                      address.city +
                      ", " +
                      address.state +
                      " " +
                      address.zip +
                      ", " +
                      address.county}
                  </Link>
                )
            )
          ) : data.type === "phone" ? (
            <Link
              href={"tel:" + data.data}
              variant="font15"
              color="secondary.light"
              underline="none"
            >
              {data.data}
            </Link>
          ) : data.type === "email" ? (
            <Link
              href={"mailto: " + data.data}
              variant="font15"
              color="secondary.light"
              underline="none"
            >
              {data.data}
            </Link>
          ) : (
            <Typography variant="font15">{data.data}</Typography>
          )}
        </Stack>
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg">
      <Box my={5}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          pb={4}
        >
          <Typography variant="font32b">Order Summary</Typography>
        </Grid>
        {isNotEmpty(orderDetails.state) && (
          <Fragment>
            <Grid container>
              {[
                createData(
                  "Signer(s) Name:",
                  orderDetails.state.signerName ?? ""
                ),
                createData(
                  "Signing Date:",
                  formattedDate(orderDetails.state.signingAt ?? "")
                ),
                createData(
                  "Signing Time:",
                  formattedTime(orderDetails.state.signingAt ?? "")
                ),
                createData(
                  "Signing Location(s):",
                  orderDetails.state.signingAddress,
                  "location"
                ),
                createData(
                  "Signer(s) Phone",
                  formatPhoneNumber(orderDetails.state.signerPhoneNumber ?? ""),
                  "phone"
                ),
                createData(
                  "Signing Type:",
                  orderDetails.state.signingType ?? ""
                ),
                createData(
                  "Originator Name:",
                  orderDetails.state.originatorName ?? ""
                ),
                createData(
                  "Originator Email:",
                  orderDetails.state.originatorEmail ?? "",
                  "email"
                ),
                createData(
                  "Originator Phone:",
                  formatPhoneNumber(
                    orderDetails.state.originatorPhoneNumber ?? ""
                  ),
                  "phone"
                ),
                createData(
                  "Organization:",
                  orderDetails.state.originatingCompany ?? ""
                ),
                createData(
                  "Originators Address:",
                  orderDetails.state.originatingCompany ?? ""
                ),
                createData("File Number:", orderDetails.state.fileNumber),
                createData(
                  "Notary Fee:",
                  "$" + parseFloat(notaryFee.state).toFixed(2)
                ),
                createData(
                  "Organization Phone:",
                  formatPhoneNumber(
                    orderDetails.state.originatingCompanyPhoneNumber ?? ""
                  ),
                  "phone"
                ),
                createData("Scheduler:", orderDetails.state.scheduler ?? ""),
              ].map((data, index) => (
                <GridCell data={data} key={index} />
              ))}
            </Grid>
            <Grid item xs={12} pb={2}>
              <Typography variant="font20b">NOTES:</Typography>
            </Grid>
            {orderDetails.state.notes &&
              orderDetails.state.notes.map((note, index) => (
                <Grid
                  key={index}
                  container
                  component={Paper}
                  sx={{ width: "95%" }}
                  p={3}
                  mb={2}
                >
                  <Grid item xs={6} pb={2}>
                    <Typography variant="font15b">{note.author}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography variant="font15b">
                      {formattedDate(note.date) +
                        " " +
                        formattedTime(note.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="font14">{note.message}</Typography>
                  </Grid>
                </Grid>
              ))}
          </Fragment>
        )}
      </Box>
    </Container>
  );
}

export default OrderSummary;
