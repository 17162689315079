import React, { useEffect } from "react";
import {
  Typography,
  Paper,
  Grid,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { isNotEmpty } from "components/common/IsEmptyObject";
import {
  isValidEmail,
  isValidPhoneNumber,
} from "components/common/FormValidator";
import { formatPhoneNumber } from "components/common/FormatPhoneNumber";
import CustomSwitch from "components/profile/common_componets/CustomSwitch";

function GeneralgeneralPreferences(props) {
  const { generalPreferences, changeData } = props;
  const allowSmsNotification = useStateUpdate(false);
  const smsNotificationNumber = useStateUpdate("");
  const emailNotificationAllowed = useStateUpdate(false);
  const notificationEmail = useStateUpdate("");
  const ccEmail = useStateUpdate("");
  const playNotificationSound = useStateUpdate(false);
  const isButtonEnable = useStateUpdate(true);

  const validationError = useStateUpdate({
    ccEmail: "",
    smsNotificationNumber: "",
  });

  useEffect(() => {
    if (isNotEmpty(generalPreferences)) {
      allowSmsNotification.update(
        generalPreferences.allowSmsNotification ?? false
      );
      smsNotificationNumber.update(
        formatPhoneNumber(generalPreferences.smsNotificationNumber ?? "")
      );
      emailNotificationAllowed.update(
        generalPreferences.emailNotificationAllowed ?? false
      );
      notificationEmail.update(generalPreferences.notificationEmail ?? "");
      ccEmail.update(generalPreferences.ccEmail ?? "");
      playNotificationSound.update(
        generalPreferences.playNotificationSound ?? false
      );
      isButtonEnable.update(false);
    }
  }, [generalPreferences]);

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      ccEmail: "",
      smsNotificationNumber: "",
    };
    if (!isValidEmail(ccEmail.state) && ccEmail.state !== "") {
      newValidationError = {
        ...newValidationError,
        ccEmail: "Email is not valid.",
      };
      isValidated = false;
    }
    if (
      !isValidPhoneNumber(smsNotificationNumber.state) &&
      smsNotificationNumber.state !== ""
    ) {
      newValidationError = {
        ...newValidationError,
        smsNotificationNumber: "Phone Number is not valid",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  const handleClick = () => {
    let data = {
      allowSmsNotification: allowSmsNotification.state,
      smsNotificationNumber: smsNotificationNumber.state,
      allowEmailNotification: emailNotificationAllowed.state,
      notificationEmail: notificationEmail.state,
      ccEmail: ccEmail.state,
      playNotificationSound: playNotificationSound.state,
    };
    changeData(data, "general");
  };
  const TogglePreference = ({ label, property, subLabel, display }) => {
    return (
      <Grid container sx={{ display: display }}>
        <Grid
          item
          xs={10}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack>
            <Typography variant="font12" color="#787878">
              {label}
            </Typography>
            <Typography variant="font11" color="#787878">
              {subLabel}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <CustomSwitch valueIs={property.state} toggle={property.update} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Stack spacing={2} p={2}>
        <Typography variant="font15b" color=" #686868" pb={3}>
          General Notification Preferences
        </Typography>
        <TogglePreference
          label="Play Notification Sounds?"
          property={playNotificationSound}
          display="none"
        />
        <TogglePreference
          label="Allow SMS Notifications?"
          property={allowSmsNotification}
        />

        <TextField
          disabled={!allowSmsNotification.state}
          label={
            <Typography variant="font12">SMS Notification Number:</Typography>
          }
          error={
            validationError.state.smsNotificationNumber === "" ? false : true
          }
          helperText={
            <Typography variant="font11">
              {validationError.state.smsNotificationNumber}
            </Typography>
          }
          variant="standard"
          color="secondary"
          value={smsNotificationNumber.state}
          inputProps={{ maxLength: 16 }}
          onChange={(e) => {
            validationError.update({
              ...validationError.state,
              smsNotificationNumber: "",
            });
            smsNotificationNumber.update(formatPhoneNumber(e.target.value));
          }}
        />
        <TogglePreference
          label="Allow Email Notifications?"
          property={emailNotificationAllowed}
          subLabel={notificationEmail.state}
        />

        <TextField
          label={
            <Typography variant="font12">CC Notification Email</Typography>
          }
          variant="standard"
          color="secondary"
          error={validationError.state.ccEmail === "" ? false : true}
          helperText={
            <Typography variant="font11">
              {validationError.state.ccEmail === ""
                ? "Send a copy of your notifications to the emails above (comma separated)."
                : validationError.state.ccEmail}
            </Typography>
          }
          value={ccEmail.state}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            validationError.update({
              ...validationError.state,
              ccEmail: "",
            });
            ccEmail.update(e.target.value);
          }}
        />

        <Grid
          item
          py={2}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="small"
            disabled={isButtonEnable.state}
            onClick={() => checkValidatorOnSubmit() && handleClick()}
          >
            <Typography variant="buttonLabel" mx={1}>
              Update preferences
            </Typography>
          </Button>
        </Grid>
      </Stack>
    </Paper>
  );
}

export default GeneralgeneralPreferences;
