import { authFetch } from "service/AuthProvider";

const SERVICE_URL = "/note-service/v1/notes/";

export const getNotes = async (profileId) => {
  let url = SERVICE_URL + "get-notes/" + profileId;
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
