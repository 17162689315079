import React, { useEffect } from "react";

import {
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Grid,
  Stack,
  Paper,
  Divider,
  InputBase,
  IconButton,
  TableHead,
  TableSortLabel,
  Box,
  Pagination,
  Button,
  CircularProgress,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SearchIcon from "@mui/icons-material/Search";
import { useStateUpdate } from "UseStateUpdate";
import ShowItemsPerPage from "components/common/ShowItemsPerPage";
import {
  WARNING_ORANGE as inactiveColor,
  WARNING_GREY as suspendedColor,
  WARNING_GREEN as activeColor,
  WARNING_RED as deletedColor,
} from "components/common/Constant";
import { rowHoverClickStyle } from "components/common/Styles";
import { listAdminUser } from "service/AdminService";
import AdminUpdateUserModal from "./AdminUpdateUserModal";
import CloseIcon from "@mui/icons-material/Close";
import { listAdminUserSummary } from "service/AdminService";
import { getLastActiveAt } from "components/common/GetDateAndTime";
import { useNavigate } from "react-router-dom";

const headCells = [
  {
    id: "first_name",
    label: "User Name",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "last_active_at",
    label: "Last activity",
  },
];

function AdminUsersTable({ showAdminUsers, heading, changeHeading }) {
  const classes = rowHoverClickStyle();
  let navigate = useNavigate();
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("desc");
  const orderBy = useStateUpdate("id");
  const filterValue = useStateUpdate("");
  const usersList = useStateUpdate([]);
  const isLoading = useStateUpdate(true);
  const openModal = useStateUpdate(false);
  const userDetails = useStateUpdate({
    email: "",
    lastName: "",
    firstName: "",
    roles: [],
    profileId: "",
  });

  useEffect(() => {
    callFetchAdminUsers();
  }, [
    rowsPerPage.state,
    page.state,
    orderBy.state,
    order.state,
    openModal.state,
    showAdminUsers.state,
  ]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  useEffect(() => {
    filterValue.state === "" && pageCount.state > 0 && callFetchAdminUsers();
  }, [filterValue.state]);

  const callFetchAdminUsers = () => {
    showAdminUsers.state === "showall"
      ? fetchListAdminUser()
      : fetchListAdminUserSummary();
  };

  const fetchListAdminUser = async () => {
    isLoading.update(true);
    var formData = new FormData();
    formData.append("page", page.state);
    formData.append("limit", rowsPerPage.state);
    formData.append("search", filterValue.state);
    formData.append("order", order.state);
    formData.append("column", orderBy.state);
    var result = await listAdminUser(formData);
    if (result.status === 200) {
      usersList.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
    }
    isLoading.update(false);
  };

  const fetchListAdminUserSummary = async () => {
    isLoading.update(true);
    var result = await listAdminUserSummary(
      page.state,
      rowsPerPage.state,
      filterValue.state,
      orderBy.state,
      order.state.toUpperCase(),
      showAdminUsers.state
    );
    if (result.status === 200) {
      usersList.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
    }
    isLoading.update(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.state === property && order.state === "asc";
    order.update(isAsc ? "desc" : "asc");
    orderBy.update(property);
  };

  const handleEdit = (user) => {
    openModal.update(true);
    userDetails.update({
      ...userDetails.state,
      email: user.email,
      lastName: user.lastName,
      firstName: user.firstName,
      roles: user.roleIds,
      profileId: user.profileId,
    });
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="font12b" noWrap>
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell>
            <Typography variant="font12b" noWrap>
              Role
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Grid container item xs={12}>
      <AdminUpdateUserModal open={openModal} userDetails={userDetails.state} />
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Grid container p={2}>
          <Grid item xs={6}>
            <Typography variant="font17b" color="secondary.light">
              {heading}
            </Typography>
          </Grid>
          {showAdminUsers.state === "showall" ? (
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 2, sm: 4 }}
                alignItems="center"
              >
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { xs: 250, sm: 350 },
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, width: { xs: 200, sm: 300 } }}
                    placeholder="Search by user name or ID"
                    value={filterValue.state}
                    onChange={(e) => {
                      filterValue.update(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        event.preventDefault();
                        event.target.value !== "" && callFetchAdminUsers();
                        event.target.value !== "" && changeHeading("Users");
                      }
                    }}
                  />
                  {filterValue.state !== "" && (
                    <IconButton
                      onClick={(e) => {
                        filterValue.update("");
                        changeHeading("Users");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton
                    sx={{
                      backgroundColor: "secondary.light",
                      borderRadius: "0%",
                    }}
                    onClick={(e) => {
                      filterValue.state !== "" && callFetchAdminUsers();
                      filterValue.state !== "" && changeHeading("Users");
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Stack>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" px={2}>
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  showAdminUsers.update("showall");
                  changeHeading("Users");
                }}
              >
                <Typography variant="font12">Show all</Typography>
              </Button>
            </Grid>
          )}
        </Grid>

        <Divider />
        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead
                order={order.state}
                orderBy={orderBy.state}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {isLoading.state ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : usersList.state.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="font15">NO DATA FOUND</Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : (
                  usersList.state.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        classes={{ hover: classes.hover }}
                        onClick={() => {
                          navigate("/profile/" + row.profileId);
                        }}
                      >
                        <TableCell>
                          <Typography variant="font12">
                            {row.userName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="font12"
                            color={
                              row.status.toLowerCase() === "active"
                                ? activeColor
                                : row.status.toLowerCase() === "inactive"
                                ? inactiveColor
                                : row.status.toLowerCase() === "deleted"
                                ? deletedColor
                                : suspendedColor
                            }
                          >
                            {row.status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font12">
                            {getLastActiveAt(row.lastActiveAt)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font12">{row.role}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}

                {usersList.state.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid
                        container
                        direction="row"
                        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        alignItems="center"
                      >
                        <Stack direction="row" spacing={2}>
                          <ShowItemsPerPage
                            perPage={rowsPerPage}
                            items={[10, 20, 50, 100]}
                          />
                          <Pagination
                            count={pageCount.state}
                            showFirstButton
                            showLastButton
                            size="small"
                            onChange={(e, value) => {
                              page.update(value);
                            }}
                          />
                        </Stack>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Divider />
      </Paper>
    </Grid>
  );
}

export default AdminUsersTable;
