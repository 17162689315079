import React, { useEffect } from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as PdfSvg } from "assets/images/pdfIcon.svg";
import { ReactComponent as JpgSvg } from "assets/images/jpgIcon.svg";
import { ReactComponent as DocSvg } from "assets/images/docIcon.svg";
import { useStateUpdate } from "UseStateUpdate";

function FileTypeIcon({ fileName }) {
  const fileType = useStateUpdate("");

  useEffect(() => {
    fileType.update(fileName.split(".").pop().toLowerCase());
  }, [fileName]);

  return (
    <SvgIcon style={{ fontSize: 15 }}>
      {fileType.state === "pdf" ? (
        <PdfSvg />
      ) : fileType.state === "jpeg" || fileType.state === "jpg" ? (
        <JpgSvg />
      ) : fileType.state === "doc" || fileType.state === "docx" ? (
        <DocSvg />
      ) : null}
    </SvgIcon>
  );
}

export default FileTypeIcon;
