import React from "react";

import { Paper, Grid, Divider } from "@mui/material";

import NotaryName from "./sections/Name";
import NotaryEmail from "./sections/Email";
import NotaryPhoneNumber from "./sections/PhoneNumber";
import NotaryPassword from "./sections/Password";
import ProfilePicture from "./sections/ProfilePicture";

function BasicDetails({ checkPercentage }) {
  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid container item xs={12} md={4} py={3} justifyContent="center">
          <ProfilePicture checkPercentage={checkPercentage} />
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid
          container
          item
          xs={12}
          md={7}
          p={4}
          direction="column"
          justifyContent="space-around"
        >
          <NotaryName checkPercentage={checkPercentage} />
          <NotaryEmail />
          <NotaryPhoneNumber checkPercentage={checkPercentage} />
          <NotaryPassword />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BasicDetails;
