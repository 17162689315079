import React, { useContext } from "react";
import { ProfileContext } from "AppContext";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
//materialUi
import { Typography, Paper, Stack, Link } from "@mui/material";
import { Box } from "@mui/system";

// Animation
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import FieldsToComplete from "./FieldsToComplete";
import { useStateUpdate } from "UseStateUpdate";

function PieChart() {
  const profileValue = useContext(ProfileContext);
  const { percentageCompleted } = profileValue;
  const openModal = useStateUpdate(false);

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <FieldsToComplete open={openModal} />
      <Stack spacing={2} alignItems="center" py={3}>
        <Typography variant="font16b">Profile Completion</Typography>
        <Stack alignItems="center">
          <Box
            sx={{
              width: 130,
            }}
          >
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={
                percentageCompleted.state.basicProfile.percentage * 0.2 +
                percentageCompleted.state.personalDetails.percentage * 0.4 +
                percentageCompleted.state.credentials.percentage * 0.4
              }
              duration={2.0}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    text={`${roundedValue}%`}
                    circleRatio={0.75}
                    styles={buildStyles({
                      textColor: "#006585",
                      pathColor: "#F9B41D",
                      trailColor: "",
                      rotation: 1 / 2 + 1 / 8,
                      strokeLinecap: "butt",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          </Box>
          <Link
            href=""
            variant="font12"
            color="secondary.light"
            onClick={(e) => {
              e.preventDefault();
              openModal.update(true);
            }}
          >
            Details
          </Link>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default PieChart;
