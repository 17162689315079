import moment from "moment-timezone";

export const getTimeZones = () => {
  const timeZones = moment.tz.names();
  var OffsetTmz = [];
  var OffsetTmzObject = [];
  for (var i in timeZones) {
    OffsetTmz.push(
      `(GMT${moment.tz(timeZones[i]).format("Z")}) ${timeZones[i]}`
    );
  }
  OffsetTmz = OffsetTmz.sort();
  OffsetTmz.forEach((timezone) => {
    OffsetTmzObject.push({ value: timezone.split(" ")[1], label: timezone });
  });
  return OffsetTmzObject;
};
