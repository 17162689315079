import { authFetch } from "service/AuthProvider";

const SERVICE_URL = "/profile-service/v1/profiles/";

export const getProfileDatas = async (profileId) => {
  let url = SERVICE_URL + profileId;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateProfile = async (profileId, data) => {
  let url = SERVICE_URL + profileId;
  return await authFetch("patch", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updatePassword = async (profileId, data) => {
  let url = SERVICE_URL + profileId + "/change-password";
  return await authFetch("put", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const sendSmsOtp = async (profileId) => {
  let url = SERVICE_URL + profileId + "/send-sms-otp";
  return await authFetch("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const verifyPhone = async (profileId, data) => {
  let url = SERVICE_URL + profileId + "/verify-phone";
  return await authFetch("post", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getNotificationPreferences = async (profileId) => {
  let url = SERVICE_URL + profileId + "/notification-preferences";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateNotificationPreferences = async (profileId, data) => {
  let url = SERVICE_URL + profileId + "/notification-preferences";
  return await authFetch("post", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getOrderSummary = async (profileId) => {
  let url = SERVICE_URL + profileId + "/order-summary";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getOrders = async (profileId, perPage, searchType, page) => {
  let url =
    SERVICE_URL +
    profileId +
    "/orders?perPage=" +
    perPage +
    "&searchType=" +
    searchType +
    "&page=" +
    page;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getNotifications = async (
  profileId,
  readStatus,
  perPage,
  page
) => {
  let url =
    SERVICE_URL +
    profileId +
    "/notifications?readStatus=" +
    readStatus +
    "&perPage=" +
    perPage +
    "&page=" +
    page;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getActivityLogs = async (profileId) => {
  let url = SERVICE_URL + profileId + "/activities";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const searchActivityLogs = async (profileId, body) => {
  let url = SERVICE_URL + profileId + "/activities-search";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getProfileOrders = async (profileId, perPage, page) => {
  let url =
    SERVICE_URL + profileId + "/all-orders?limit=" + perPage + "&page=" + page;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
