import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { logout } from "service/AuthProvider";
import { useAuth } from "service/AuthProvider";
import { useStateUpdate } from "UseStateUpdate";
import { profileCompletionPercent, useIsAdmin } from "./GetUserDetail";

function UnProtectedRoute({ children }) {
  let location = useLocation();
  const isAdmin = useIsAdmin();
  const search = useLocation().search;
  const regId = new URLSearchParams(search).get("regId");
  const code = new URLSearchParams(search).get("code");
  const isAuthenticated = useStateUpdate(false);
  const isAuth = useAuth();

  useEffect(() => {
    if (regId !== null && code !== null) {
      localStorage.removeItem("user");
      logout();
    } else {
      isAuthenticated.update(isAuth);
    }
  }, []);

  if (isAuthenticated.state) {
    return (
      <Navigate
        to={
          isAdmin
            ? "/admin-dashboard"
            : profileCompletionPercent() >= 80
            ? "/dashboard"
            : "/profile"
        }
        state={{ from: location }}
      />
    );
  }

  return children;
}

export default UnProtectedRoute;
