import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  IconButton,
  AccordionDetails,
  Stack,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Toast from "components/common/Toast";

import { iconButton } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import OrderSearchData from "./sections/OrderSearchData";
import SiginingSearchData from "./sections/SiginingSearchData";
import NotarySearchData from "./sections/NotarySearchData";
import SearchResult from "./sections/SearchResult";
import { createOrdersData } from "components/common/CreateObjects";
import { formattedDate, formattedTime } from "components/common/GetDateAndTime";
import { searchOrder } from "service/OrderService";
import { quickOrderSearch } from "service/AdminService";
import { useIsAdmin } from "components/common/GetUserDetail";
import { getProfileId } from "components/common/GetUserDetail";

export const createAccordion = (expand, heading, component) => {
  return { expand, heading, component };
};

const defaultSearchParams = {
  orderData: {
    status: "all",
    assignedStatus: "all",
    scheduledStatus: "all",
    signerName: "",
    signerPhone: "",
    signerEmail: "",
    orderNumber: "",
    signingAgencyName: "",
    fileNumber: "",
    receivedDateStart: "",
    receivedDateEnd: "",
    deletedOrders: "Exclude Deleted Orders",
    schedulerName: "",
    trackingNumber: "",
  },
  signingData: {
    signingDateStart: "",
    siginingDateEnd: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
  notaryData: { notaryStatus: "any" },
};

function OrderSearch() {
  const search = useLocation().search;
  const aboutSection = useRef(null);
  const isAdmin = useIsAdmin();
  const orderStatus = new URLSearchParams(search).get("status") ?? "any";
  const quickSearchParam = useStateUpdate(
    new URLSearchParams(search).get("search") ?? false
  );
  const orderDataExpand = useStateUpdate(true);
  const signingDataExpand = useStateUpdate(false);
  const notaryDataExpand = useStateUpdate(false);
  const searchResult = useStateUpdate([]);
  const dataForTable = useStateUpdate([]);
  const perPage = useStateUpdate(10);
  const pageCount = useStateUpdate(0);
  const currentPage = useStateUpdate(1);
  const searchResultCount = useStateUpdate(0);
  const showResult = useStateUpdate(false);
  const currentStatus = useStateUpdate(false);
  const isSearching = useStateUpdate(false);
  const viewAsNotaryAdmin = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const searchParams = useStateUpdate({
    ...defaultSearchParams,
    orderData: {
      ...defaultSearchParams.orderData,
      status: orderStatus.toLowerCase(),
    },
  });

  useEffect(() => {
    if (quickSearchParam.state) {
      handleQuickSearch();
    } else {
      searchParams.update({
        ...defaultSearchParams,
        orderData: {
          ...defaultSearchParams.orderData,
          status: orderStatus.toLowerCase(),
        },
      });
      currentStatus.update(orderStatus);
    }
  }, [orderStatus]);

  useEffect(() => {
    dataForTable.state.length > 0 && scrollDown();
  }, [dataForTable.state]);

  const scrollDown = () => {
    window.scrollTo({
      top: aboutSection.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    currentStatus.state && !quickSearchParam.state && handleSearch();
  }, [currentStatus.state, viewAsNotaryAdmin.state]);

  useEffect(() => {
    showResult.state &&
      (quickSearchParam.state
        ? callQuickOrderSearch()
        : callSearchOrder(currentPage.state));
  }, [currentPage.state]);

  useEffect(() => {
    createDataForTable();
  }, [searchResult.state]);

  const clearParams = () => {
    searchParams.update({
      ...defaultSearchParams,
      orderData: {
        ...defaultSearchParams.orderData,
        status: "any",
      },
    });
    searchResult.update([]);
    pageCount.update(0);
    searchResultCount.update(0);
  };

  const getSearchParams = () => {
    const orderDatas = searchParams.state.orderData;
    const signingDatas = searchParams.state.signingData;
    const notaryDatas = searchParams.state.notaryData;
    return {
      orderStatus: orderDatas.status,
      scheduledStatus: orderDatas.scheduledStatus,
      assignedStatus: orderDatas.assignedStatus,
      signerName: orderDatas.signerName,
      signerPhoneNumber: orderDatas.signerPhone,
      signerEmail: orderDatas.signerEmail,
      orderNumber: orderDatas.orderNumber,
      signingAgencyName: orderDatas.signingAgencyName,
      fileNumber: orderDatas.fileNumber,
      receivedStartDate: orderDatas.receivedDateStart,
      receivedEndDate: orderDatas.receivedDateEnd,
      trackingNumber: orderDatas.trackingNumber,
      scheduler: orderDatas.schedulerName,
      signingStartDate: signingDatas.signingDateStart,
      signingEndDate: signingDatas.siginingDateEnd,
      notaryOrderstatus: notaryDatas.notaryStatus,
      streetAddress: signingDatas.streetAddress,
      city: signingDatas.city,
      state: signingDatas.state,
      county: signingDatas.county,
      zipCode: signingDatas.zip,
      viewAllOrders: viewAsNotaryAdmin.state,
    };
  };

  const handleSearch = () => {
    quickSearchParam.update(false);
    isSearching.update(true);
    showResult.update(true);
    currentPage.state === 1 ? callSearchOrder(1) : currentPage.update(1);
  };

  const callSearchOrder = async (page) => {
    var data = getSearchParams();
    var result = await searchOrder(data, perPage.state, page);
    if (result.status === 200) {
      searchResult.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
      searchResultCount.update(result.data.data.pagination.total);
    }
  };

  const callQuickOrderSearch = async () => {
    isSearching.update(true);
    showResult.update(true);
    var result = await quickOrderSearch(
      quickSearchParam.state,
      perPage.state,
      currentPage.state
    );
    if (result.status === 200) {
      searchResult.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
      searchResultCount.update(result.data.data.pagination.total);
    }
  };

  const handleQuickSearch = () => {
    isSearching.update(true);
    showResult.update(true);
    callQuickOrderSearch();
  };

  const isAcceptOrder = (orderDataForNotaries) => {
    var acceptOrderStatus = "open";

    if (viewAsNotaryAdmin.state) {
      acceptOrderStatus = "pending";
      var isOpen = false;
      var isPending = false;
      orderDataForNotaries.forEach((notary) => {
        (notary.notaryOrderStatus === "open" ||
          notary.notaryOrderStatus === "closed" ||
          notary.notaryOrderStatus === "hold") &&
          (isOpen = true);
        notary.notaryOrderStatus === "pending" && (isPending = true);
      });
      isOpen && isPending && (acceptOrderStatus = "openandpending");
      !isOpen && isPending && (acceptOrderStatus = "pending");
      isOpen && !isPending && (acceptOrderStatus = "open");
    } else {
      orderDataForNotaries.forEach((notary) => {
        notary.profileId === getProfileId() &&
          notary.notaryOrderStatus === "pending" &&
          (acceptOrderStatus = "pending");
      });
    }

    return acceptOrderStatus;
  };

  const createDataForTable = () => {
    var rows = [];
    searchResult.state.forEach((rowData) => {
      rows.push(
        createOrdersData(
          isAdmin ? "open" : isAcceptOrder(rowData.orderDataForNotaries),
          rowData.orderId,
          rowData.orderNumber,
          rowData.status,
          rowData.hasNotes,
          rowData.hasDocuments,
          rowData.signingType,
          formattedDate(rowData.signingAt),
          formattedTime(rowData.signingAt),
          rowData.signerName,
          rowData.signerPhoneNumber,
          rowData.originatorName,
          rowData.originatorEmail,
          rowData.originatingCompany,
          rowData.originatingCompanyPhoneNumber,
          rowData.fileNumber,
          rowData.notaries === undefined || rowData.notaries.length === 0
            ? ""
            : rowData.notaries[0].name,
          rowData.notaries === undefined || rowData.notaries.length === 0
            ? ""
            : rowData.notaries[0].phoneNumber,
          rowData.scheduler,
          rowData.signingAgencyName
        )
      );
    });
    dataForTable.update(rows);
    isSearching.update(false);
  };

  return (
    <Box mx={{ xs: 2, sm: 3 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Orders</Typography>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" mb={1}>
          <Stack direction="row" spacing={3} alignItems="center">
            {!isAdmin && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="font14b">View as:</Typography>
                <FormControl size="small" color="secondary" variant="standard">
                  <Select
                    value={viewAsNotaryAdmin.state}
                    onChange={(e) => {
                      viewAsNotaryAdmin.update(e.target.value);
                    }}
                  >
                    <MenuItem value={false}>
                      <Typography variant="font14">Notary</Typography>
                    </MenuItem>
                    <MenuItem value={true}>
                      <Typography variant="font14">Notary Admin</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            )}
            <Button
              disabled={
                orderDataExpand.state === false &&
                signingDataExpand.state === false &&
                notaryDataExpand.state === false
              }
              variant="text"
              size="small"
              sx={{ color: "#000" }}
              onClick={() => {
                orderDataExpand.update(false);
                signingDataExpand.update(false);
                notaryDataExpand.update(false);
              }}
            >
              <Typography variant="font14b">Collapse all</Typography>
            </Button>
          </Stack>
        </Grid>{" "}
        <form>
          <Stack spacing={2}>
            {[
              createAccordion(
                orderDataExpand,
                "Order data",
                <OrderSearchData searchParams={searchParams} />
              ),
              createAccordion(
                signingDataExpand,
                "Signing data",
                <SiginingSearchData searchParams={searchParams} />
              ),
              createAccordion(
                notaryDataExpand,
                "Notary data",
                <NotarySearchData searchParams={searchParams} />
              ),
            ].map((accordion, index) => (
              <Accordion
                key={index}
                expanded={accordion.expand.state}
                onChange={(event, isExpanded) =>
                  accordion.expand.update(isExpanded)
                }
              >
                <AccordionSummary
                  expandIcon={
                    accordion.expand.state ? (
                      <IconButton sx={iconButton.border}>
                        <RemoveIcon />
                      </IconButton>
                    ) : (
                      <IconButton sx={iconButton.border}>
                        <AddIcon />
                      </IconButton>
                    )
                  }
                >
                  <Typography
                    variant="font17b"
                    color="#2996AE"
                    sx={{ flexShrink: 0 }}
                  >
                    {accordion.heading}
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>{accordion.component}</AccordionDetails>
              </Accordion>
            ))}
          </Stack>
          <Grid container justifyContent="flex-end" alignItems="center" p={2}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={clearParams}
              >
                <Typography variant="buttonLabel" mx={4}>
                  Clear
                </Typography>
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <Typography variant="buttonLabel" mx={4}>
                  Search
                </Typography>
              </Button>
            </Stack>
          </Grid>
        </form>
      </Grid>
      <Grid my={3} ref={aboutSection}>
        <SearchResult
          isSearching={isSearching.state}
          searchResult={dataForTable.state}
          pageCount={pageCount.state}
          page={currentPage}
          searchResultCount={searchResultCount.state}
          callSearchOrder={callSearchOrder}
          severity={severity}
          toastMessage={toastMessage}
          toastOpen={toastOpen}
          viewAsNotaryAdmin={viewAsNotaryAdmin.state}
        />
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  );
}

export default OrderSearch;
