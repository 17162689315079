import { createTheme } from "@mui/material";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const basetheme = createTheme({
  palette: {
    primary: { main: "#F9B41D" },
    secondary: { main: "#006585", light: "#2996AE" },
    white: { main: "#fff" },
  },
  typography: {
    buttonLabel: {
      fontWeight: "700",
      fontSize: "14px",
      textTransform: "none",
      display: "block",
    },
    containedbuttonLabel: {
      fontWeight: "700",
      fontSize: "14px",
      textTransform: "none",
      display: "block",
      color: "#fff",
    },
    banner: {
      fontWeight: "bold",
      color: "#fff",
      textTransform: "none",
      fontSize: "40px",
      [breakpoints.down("sm")]: {
        fontSize: "35px",
      },
      [breakpoints.down("xs")]: {
        fontSize: "30px",
      },
      [breakpoints.down("md")]: {
        fontSize: "40px",
      },
    },
    font32: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "32px",
      display: "block",
    },
    font32b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "32px",
      display: "block",
    },
    font25: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "25px",
      display: "block",
    },
    font25b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "25px",
      display: "block",
    },
    font20: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "20px",
      display: "block",
    },
    font20b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "20px",
      display: "block",
    },
    font19: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "19px",
      display: "block",
    },
    font19b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "19px",
      display: "block",
    },
    font17: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "17px",
    },
    font17b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "17px",
    },
    font16: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "16px",
    },
    font16b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "16px",
    },
    font15: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "15px",
    },
    font15b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "15px",
    },
    font14: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "14px",
    },
    font14b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "14px",
    },
    font13: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "13px",
    },
    font13b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "13px",
    },
    font12: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "12px",
    },
    font12b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "12px",
    },
    font11: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "11px",
    },
    font11b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "11px",
    },
    font10: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "10px",
    },
    font10b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "10px",
    },
    font8: {
      fontWeight: "400",
      textTransform: "none",
      fontSize: "8px",
    },
    font8b: {
      fontWeight: "700",
      textTransform: "none",
      fontSize: "8px",
    },
  },
});

export const toolbarTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
});
