import React from "react";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import { STATES as states } from "components/common/Constant";
import CloseIcon from "@mui/icons-material/Close";

function SiginingSearchData({ searchParams }) {
  return (
    <Grid container rowSpacing={{ xs: 2, sm: 3, md: 4 }} columnSpacing={5}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          id="signing-start-date"
          label={<Typography variant="font14">Signing Date Start:</Typography>}
          type="date"
          fullWidth
          variant="standard"
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
          value={searchParams.state.signingData.signingDateStart}
          inputProps={{ maxLength: 16 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              signingData: {
                ...searchParams.state.signingData,
                signingDateStart: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          id="signing-end-date"
          label={<Typography variant="font14">Signing Date End:</Typography>}
          type="date"
          fullWidth
          variant="standard"
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
          value={searchParams.state.signingData.siginingDateEnd}
          inputProps={{ maxLength: 16 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              signingData: {
                ...searchParams.state.signingData,
                siginingDateEnd: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <TextField
          label={<Typography variant="font14">Street Address:</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.signingData.streetAddress}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              signingData: {
                ...searchParams.state.signingData,
                streetAddress: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">City:</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.signingData.city}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              signingData: {
                ...searchParams.state.signingData,
                city: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font14">State:</Typography>
          </InputLabel>
          <Select
            labelId="state-select-label"
            id="state"
            label={<Typography variant="font14">State:</Typography>}
            value={searchParams.state.signingData.state}
            endAdornment={
              <IconButton
                sx={{
                  display:
                    searchParams.state.signingData.state !== "" ? "" : "none",
                  marginRight: "10px",
                }}
                onClick={() => {
                  searchParams.update({
                    ...searchParams.state,
                    signingData: {
                      ...searchParams.state.signingData,
                      state: "",
                    },
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
            }
            onChange={(e) => {
              searchParams.update({
                ...searchParams.state,
                signingData: {
                  ...searchParams.state.signingData,
                  state: e.target.value,
                },
              });
            }}
          >
            {states.map((state) => (
              <MenuItem key={state.code} value={state.code}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Zip code:</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.signingData.zip}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              signingData: {
                ...searchParams.state.signingData,
                zip: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">County:</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.signingData.county}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              signingData: {
                ...searchParams.state.signingData,
                county: e.target.value,
              },
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default SiginingSearchData;
