import React from "react";
import { Box, Modal } from "@mui/material/";
import { smallModalStyle } from "components/common/Styles";
import AdminAddNewUser from "../AdminAddNewUser";

function OrderModal(props) {
  const { open, userDetails } = props;

  const handleClose = () => {
    open.update(false);
  };

  return (
    <Modal open={open.state} onClose={handleClose}>
      <Box sx={smallModalStyle}>
        <AdminAddNewUser
          isUpdate={true}
          userDetails={userDetails}
          closeModal={handleClose}
        />
      </Box>
    </Modal>
  );
}

export default OrderModal;
