import React, { useContext } from "react";
import { ProfileContext } from "AppContext";

import {
  Box,
  Typography,
  Modal,
  Grid,
  Stack,
  IconButton,
  Button,
} from "@mui/material/";
import { smallModalStyle } from "components/common/Styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Divider from "@mui/material/Divider";

function FieldsToComplete({ open }) {
  const profileValue = useContext(ProfileContext);
  const { percentageCompleted } = profileValue;

  const handleClose = () => () => {
    open.update(false);
  };

  const ModalHeader = () => {
    return (
      <Grid container p={2}>
        <Grid item xs={8}>
          <Typography variant="font14b">
            Items needed for profile 100% completion
          </Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end">
          <IconButton size="small" onClick={handleClose()}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const ModalFooter = () => {
    return (
      <Stack p={2} direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="contained"
          size="small"
          onClick={handleClose()}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography variant="font14b">Close</Typography>
        </Button>
      </Stack>
    );
  };

  const ModalBody = () => {
    return (
      <Box p={2}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="font14b">In Basic details</Typography>
            <Box pl={3}>
              <Stack spacing={1}>
                {percentageCompleted.state.basicProfile.toComplete.map(
                  (item, index) => (
                    <Typography variant="font14" key={index}>
                      {index + 1 + ". " + item}
                    </Typography>
                  )
                )}
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="font14b">In Personal details</Typography>
            <Box pl={3}>
              <Stack spacing={1}>
                {percentageCompleted.state.personalDetails.toComplete.map(
                  (item, index) => (
                    <Typography variant="font14" key={index}>
                      {index + 1 + ". " + item}
                    </Typography>
                  )
                )}
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="font14b">In Credentials</Typography>
            <Box pl={3}>
              <Stack spacing={1}>
                {percentageCompleted.state.credentials.toComplete.map(
                  (item, index) => (
                    <Typography variant="font14" key={index}>
                      {index + 1 + ". " + item}
                    </Typography>
                  )
                )}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Modal open={open.state} onClose={handleClose()}>
      <Box sx={{ ...smallModalStyle, p: 0 }}>
        <ModalHeader />
        <Divider />
        <ModalBody />
        <Divider />
        <ModalFooter />
      </Box>
    </Modal>
  );
}

export default FieldsToComplete;
