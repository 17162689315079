import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Hidden,
  Link,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  Grid,
  styled,
  IconButton,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import logInPoster from "assets/images/logInPoster.png";
import { isEmptyString } from "components/common/FormValidator";
import { loginUser } from "service/AuthService";
import { useAuth } from "service/AuthProvider";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  alignItems: "left",
}));

function SignIn(props) {
  const navigate = useNavigate();
  const errorMsg = useStateUpdate("");
  const email = useStateUpdate("");
  const password = useStateUpdate("");
  const showPassword = useStateUpdate(false);
  const isAuthenticated = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/profile");
    }
  }, []);

  const validationError = useStateUpdate({
    email: "",
    password: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      email: "",
      password: "",
    };
    if (isEmptyString(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is required*",
      };
      isValidated = false;
    }
    if (isEmptyString(password.state)) {
      newValidationError = {
        ...newValidationError,
        password: "Password is required*",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  function getUserData() {
    var userData = {
      email: email.state.trim(),
      password: password.state.trim(),
    };
    return userData;
  }

  async function callLogin(e) {
    e.preventDefault();
    let resultSet = null;
    let userData = getUserData();
    var response = await loginUser(userData);

    if (response.status === 200) {
      localStorage.removeItem("adminId");
      if (response.data.code === 200) {
        resultSet = response.data.data;
        props.onLogin(resultSet);
      } else {
        errorMsg.update("Username or password is incorrect.");
      }
    } else {
      errorMsg.update("Username or password is incorrect.");
    }
  }

  return (
    <Container maxWidth="md">
      <Box mt={{ xs: 10, sm: 6, md: 5 }}>
        <Grid container sx={{ backgroundColor: "#fff" }}>
          <Grid item xs={12} sm={10} md={6}>
            <form>
              <Stack spacing={2} sx={{ px: 5 }}>
                <Typography
                  variant="font20b"
                  align="left"
                  sx={{ mb: 5, mt: 7 }}
                >
                  Log in to your account
                </Typography>

                <TextField
                  id="login-email"
                  label="Email"
                  variant="standard"
                  color="secondary"
                  value={email.state}
                  inputProps={{ maxLength: 35 }}
                  helperText={
                    <Typography variant="font14" color="error">
                      {validationError.state.email}
                    </Typography>
                  }
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      email: "",
                    });
                    errorMsg.update("");
                    email.update(e.target.value);
                  }}
                />

                <FormControl variant="standard" color="secondary">
                  <InputLabel
                    error={validationError.state.password ? true : false}
                  >
                    Password
                  </InputLabel>
                  <Input
                    id="login-password"
                    error={validationError.state.password === "" ? false : true}
                    type={showPassword.state ? "text" : "password"}
                    value={password.state}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        password: "",
                      });
                      errorMsg.update("");
                      password.update(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            showPassword.update(!showPassword.state)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword.state ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    error={validationError.state.password === "" ? false : true}
                    id="standard-weight-helper-text"
                  >
                    <Typography variant="font14" color="error">
                      {validationError.state.password}
                    </Typography>
                  </FormHelperText>
                </FormControl>

                {/* <TextField
                  type="password"
                  label="Password"
                  variant="standard"
                  color="secondary"
                  value={password.state}
                  inputProps={{ maxLength: 35 }}
                  helperText={
                    <Typography variant="font14" color="error">
                      {validationError.state.password}
                    </Typography>
                  }
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      password: "",
                    });
                    errorMsg.update("");
                    password.update(e.target.value);
                  }}
                /> */}
                <Stack>
                  <Typography variant="font12" color="error">
                    {errorMsg.state}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ pt: errorMsg.state === "" ? 5 : 3, pb: 2 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(e) => {
                      checkValidatorOnSubmit() && callLogin(e);
                    }}
                  >
                    <Typography variant="buttonLabel">Log in</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/registration", { replace: true })}
                  >
                    <Typography variant="buttonLabel">
                      Create Account
                    </Typography>
                  </Button>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="font12">
                    Forgot your login details?
                  </Typography>
                  <Link
                    href=""
                    color="#000000"
                    variant="font12b"
                    underline="none"
                    onClick={() => navigate("/forgot-password")}
                  >
                    {"Get help Signing In"}
                  </Link>
                </Stack>
              </Stack>
            </form>
          </Grid>
          <Hidden only={["sm", "xs"]}>
            <Grid
              container
              item
              md={6}
              p={2}
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <img src={logInPoster} alt="log-in-poster" loading="lazy" />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Container>
  );
}

export default SignIn;
