import React, { useContext, useEffect } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
//materialUi
import {
  TextField,
  Stack,
  Typography,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
//icons
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { EditOutlined } from "@material-ui/icons";
//validation
import { isEmptyString } from "components/common/FormValidator";
//style
import { iconButton } from "components/common/Styles";
//service
import { updateProfile } from "service/ProfileService";
import { isNotEmpty } from "components/common/IsEmptyObject";

function Name({ checkPercentage }) {
  const profileValue = useContext(ProfileContext);
  const { profileDatas, fetchProfileData } = profileValue;
  const isEditName = useStateUpdate(false);

  const firstName = useStateUpdate("");
  const middleName = useStateUpdate("");
  const lastName = useStateUpdate("");
  const firstNameToEdit = useStateUpdate("");
  const middleNameToEdit = useStateUpdate("");
  const lastNameToEdit = useStateUpdate("");
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const nameValidationError = useStateUpdate({
    firstName: "",
    middleName: "",
    lastName: "",
  });

  useEffect(() => {
    if (isNotEmpty(profileDatas)) {
      firstName.update(profileDatas.firstName ?? "");
      middleName.update(profileDatas.middleName ?? "");
      lastName.update(profileDatas.lastName ?? "");
    }
  }, [profileDatas]);

  function getFullName() {
    var fullNameIs = {
      firstName: firstNameToEdit.state.trim(),
      middleName: middleNameToEdit.state.trim(),
      lastName: lastNameToEdit.state.trim(),
      profileCompletionPercentage: checkPercentage(),
    };
    return fullNameIs;
  }

  const updateName = async () => {
    let data = getFullName();
    var response = await updateProfile(profileDatas.profileId, data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        firstName.update(firstNameToEdit.state);
        middleName.update(middleNameToEdit.state);
        lastName.update(lastNameToEdit.state);
        isEditName.update(!isEditName.state);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else calltoast("error", response.data.message);
    } else calltoast("error", response.data.message);
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  function checkNameValidatorOnSubmit() {
    let isValidated = true;
    let newNamenameValidationError = {
      firstName: "",
      middleName: "",
      lastName: "",
    };
    if (isEmptyString(firstNameToEdit.state)) {
      newNamenameValidationError = {
        ...newNamenameValidationError,
        firstName: "First Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(lastNameToEdit.state)) {
      newNamenameValidationError = {
        ...newNamenameValidationError,
        lastName: "Last Name is required",
      };
      isValidated = false;
    }
    nameValidationError.update(newNamenameValidationError);
    return isValidated;
  }

  return (
    <Grid container>
      {isEditName.state ? (
        <Grid item xs={12}>
          <Stack spacing={2}>
            <TextField
              required
              error={nameValidationError.state.firstName === "" ? false : true}
              helperText={nameValidationError.state.firstName}
              id="first-name"
              label={<Typography variant="font12">First Name</Typography>}
              inputProps={{ maxLength: 35 }}
              variant="standard"
              color="secondary"
              fullWidth
              size="small"
              margin="dense"
              value={firstNameToEdit.state}
              onChange={(e) => {
                nameValidationError.update({
                  ...nameValidationError.state,
                  firstName: "",
                });
                firstNameToEdit.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  nameValidationError.update({
                    ...nameValidationError.state,
                    firstName: "First Name is required",
                  });
                }
              }}
            />

            <TextField
              id="middle-name"
              label={<Typography variant="font12">Middle Name</Typography>}
              inputProps={{ maxLength: 35 }}
              variant="standard"
              color="secondary"
              fullWidth
              size="small"
              margin="dense"
              value={middleNameToEdit.state}
              onChange={(e) => {
                nameValidationError.update({
                  ...nameValidationError.state,
                  middleName: "",
                });
                middleNameToEdit.update(e.target.value);
              }}
            />

            <TextField
              required
              error={nameValidationError.state.lastName === "" ? false : true}
              helperText={nameValidationError.state.lastName}
              id="last-name"
              label={<Typography variant="font12">Last Name</Typography>}
              inputProps={{ maxLength: 35 }}
              variant="standard"
              color="secondary"
              fullWidth
              size="small"
              margin="dense"
              value={lastNameToEdit.state}
              onChange={(e) => {
                nameValidationError.update({
                  ...nameValidationError.state,
                  lastName: "",
                });
                lastNameToEdit.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  nameValidationError.update({
                    ...nameValidationError.state,
                    lastName: "Last Name is required",
                  });
                }
              }}
            />
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                disabled={
                  firstNameToEdit.state === "" || lastNameToEdit.state === ""
                }
                color="primary"
                size="small"
                onClick={(e) => {
                  checkNameValidatorOnSubmit() && updateName();
                }}
                sx={iconButton.border}
              >
                <DoneIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => isEditName.update(!isEditName.state)}
                sx={iconButton.border}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid item xs={10}>
            <Typography variant="font14b">
              {firstName.state + " " + middleName.state + " " + lastName.state}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={2}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <IconButton
              size="small"
              onClick={() => {
                firstNameToEdit.update(firstName.state);
                middleNameToEdit.update(middleName.state);
                lastNameToEdit.update(lastName.state);
                isEditName.update(!isEditName.state);
              }}
            >
              <EditOutlined fontSize="small" />
            </IconButton>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Grid>
  );
}

export default Name;
