import React, { useContext } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
//materialUi
import {
  Button,
  Typography,
  Grid,
  Input,
  IconButton,
  Link,
  Stack,
  CircularProgress,
} from "@mui/material";
//icons
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
//style
import { iconButton } from "components/common/Styles";
//services
import { uploadFile, downloadFile } from "service/DocumentService";
import { updateProfile } from "service/ProfileService";
import FileTypeIcon from "./FileTypeIcon";

function UploadFile(props) {
  const profileValue = useContext(ProfileContext);
  const { profileDatas, fetchProfileData } = profileValue;
  const { id, label, fileIs, checkPercentage, isButtonDisabled } = props;
  const isLoader = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  async function uploadDocument() {
    isLoader.update(true);
    var response = await uploadFile(
      profileDatas.profileId,
      fileIs.state.file,
      "profiles"
    );
    if (response.status === 200) {
      if (response.data.code === 200) {
        let documents = [
          {
            documentId: response.data.data.documentId,
            documentTypeCode: fileIs.state.documentTypeCode,
            fileName: response.data.data.name,
            expiresAt: fileIs.state.expiresAt,
          },
        ];
        let data = {
          documents: documents,
          profileCompletionPercentage: checkPercentage(),
        };
        response = await updateProfile(profileDatas.profileId, data);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else calltoast("error", response.data.message);
    } else calltoast("error", response.data.message);
    isLoader.update(false);
  }

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  async function downloadDocument(e) {
    e.preventDefault();
    var response = await downloadFile(
      profileDatas.profileId,
      fileIs.state.documentId,
      fileIs.state.fileName
    );
    response.status !== 200 && calltoast("error", response.data.message);
  }

  const handleChange = (event) => {
    isButtonDisabled.update(false);
    let files = event.target.files || event.dataTransfer.files;
    if (!files.length) return;
    fileIs.update({
      ...fileIs.state,
      file: files[0],
      fileName: files[0].name,
      documentId: "",
    });
    event.target.value = "";
  };

  return (
    <Grid container item xs={12} sm={12} md={6}>
      <Grid
        container
        item
        xs={6}
        justifyContent="flex-start"
        alignItems="center"
      >
        {fileIs.state.file === "" ? (
          <Typography variant="font12">{label}</Typography>
        ) : fileIs.state.documentId === "" ? (
          <Stack>
            <Typography variant="font12" color="#777">
              {label}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="font10" color="#777777">
                {fileIs.state.fileName}
              </Typography>
              <FileTypeIcon fileName={fileIs.state.fileName} />
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Typography variant="font12" color="#777">
              {label}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="font10" color="#777777">
                <Link color="secondary" href="#" onClick={downloadDocument}>
                  {fileIs.state.fileName}
                </Link>
              </Typography>
              <FileTypeIcon fileName={fileIs.state.fileName} />
            </Stack>
          </Stack>
        )}
      </Grid>
      <Grid
        direction="row"
        spacing={1}
        container
        item
        xs={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            component="span"
            color="secondary"
            disabled={isLoader.state}
            size="small"
            sx={{
              display:
                fileIs.state.file === ""
                  ? "none"
                  : fileIs.state.documentId === ""
                  ? "block"
                  : "none",
            }}
            onClick={(e) => uploadDocument()}
          >
            <Typography variant="containedbuttonLabel" mx={2}>
              Upload
            </Typography>
            {isLoader.state && (
              <CircularProgress
                size={15}
                color="secondary"
                sx={{
                  position: "absolute",
                  marginLeft: "30px",
                  marginTop: "-20px",
                }}
              />
            )}
          </Button>
          <label htmlFor={id}>
            <Input
              inputProps={{ accept: ".jpg,.jpeg,.pdf,.doc,.docx,.png" }}
              id={id}
              type="file"
              onChange={handleChange}
              sx={{ display: "none" }}
            />
            <Button
              variant="contained"
              component="span"
              color="secondary"
              size="small"
              sx={{ display: fileIs.state.file === "" ? "block" : "none" }}
            >
              <Typography variant="containedbuttonLabel" mx={2}>
                Choose file
              </Typography>
            </Button>
            {fileIs.state.file === "" ? null : fileIs.state.documentId ===
              "" ? (
              <IconButton
                color="secondary"
                aria-label="upload picture"
                component="span"
                size="small"
                sx={iconButton.border}
              >
                <RefreshIcon />
              </IconButton>
            ) : null}
          </label>
          {fileIs.state.documentId === "" ? null : (
            <IconButton
              color="secondary"
              aria-label="upload picture"
              component="span"
              size="small"
              sx={iconButton.border}
              onClick={() => {
                fileIs.update({
                  ...fileIs.state,
                  file: "",
                  fileName: "",
                  documentId: "",
                });
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Stack>
      </Grid>

      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Grid>
  );
}

export default UploadFile;
