import React, { useEffect } from "react";

import {
  AccordionDetails,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { taggedSigningAgencyList } from "service/AdminService";
import { getNotaryTags } from "service/AdminService";
import { deleteNotaryTags } from "service/AdminService";
import { createNotaryTags } from "service/AdminService";

const globalTag = {
  id: 0,
  name: "Global",
};

function NotaryTags({ profileId, fetchActivityLogs }) {
  const selectedAgency = useStateUpdate(globalTag);
  const newTag = useStateUpdate("");
  const agencyList = useStateUpdate([]);
  const tags = useStateUpdate([]);

  useEffect(() => {
    getAgencyList();
  }, []);

  useEffect(() => {
    getTags();
  }, [selectedAgency.state]);

  const getAgencyList = async () => {
    var result = await taggedSigningAgencyList(profileId);
    var list = [];
    list.push(globalTag);
    if (result.status === 200) list = list.concat(result.data.data);
    agencyList.update(list);
  };

  const getTags = async () => {
    var params =
      (selectedAgency.state.name !== "Global"
        ? "signingAgencyId=" + selectedAgency.state.id + "&"
        : "") +
      "profileId=" +
      profileId;

    var result = await getNotaryTags(params);
    result.status === 200 &&
      tags.update(
        selectedAgency.state.name === "Global"
          ? result.data.data.globalTags
          : result.data.data.agencyTags
      );
  };

  const deleteTag = async (tagId) => {
    var result = await deleteNotaryTags(profileId, tagId);
    if (result.status === 200) {
      getTags();
      fetchActivityLogs();
    }
  };

  const addTag = async () => {
    const body =
      selectedAgency.state.name === "Global"
        ? {
            tag_name: newTag.state,
          }
        : {
            tag_name: newTag.state,
            agencyId: selectedAgency.state.id,
          };
    var result = await createNotaryTags(profileId, body);
    if (result.status === 200) {
      if (result.data.code === 200) {
        fetchActivityLogs();
        getTags();
      }
    }
    newTag.update("");
  };

  return (
    <AccordionDetails>
      <Grid container spacing={3} px={2} mb={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="standard" color="secondary">
            <InputLabel>
              <Typography variant="font12">Select Agency</Typography>
            </InputLabel>
            <Select
              labelId="agency-select-label"
              id="agency"
              label={<Typography variant="font12">Select Agency</Typography>}
              value={selectedAgency.state}
              onChange={(e) => {
                selectedAgency.update(e.target.value);
              }}
            >
              {agencyList.state.map((agency) => (
                <MenuItem key={agency.id} value={agency}>
                  {agency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="font15b">
            {selectedAgency.state.name + " tags"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {tags.state.map((tag) => (
            <Chip
              key={tag.tagId}
              label={tag.tagName}
              onDelete={() => deleteTag(tag.tagId)}
              sx={{ margin: "8px" }}
            />
          ))}

          <Stack direction="row" spacing={2} alignItems="center">
            <form>
              <TextField
                label={<Typography variant="font12">Add tag</Typography>}
                variant="outlined"
                size="small"
                color="secondary"
                value={newTag.state}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => {
                  newTag.update(e.target.value);
                }}
                sx={{ margin: "8px" }}
              />
              <Button
                disabled={newTag.state === ""}
                size="small"
                type="submit"
                color="secondary"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  addTag();
                }}
              >
                <Typography variant="font12b">Add</Typography>
              </Button>
            </form>
          </Stack>
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}

export default NotaryTags;
