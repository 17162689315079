import React from "react";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

export const createInput = (label, stateIs, type, data) => {
  return { label, stateIs, type, data };
};

function OrderSearchData(props) {
  const { searchParams } = props;

  return (
    <Grid container rowSpacing={{ xs: 2, sm: 3, md: 4 }} columnSpacing={5}>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font14">Status</Typography>
          </InputLabel>
          <Select
            label={<Typography variant="font14">Status</Typography>}
            value={searchParams.state.orderData.status}
            onChange={(e) => {
              searchParams.update({
                ...searchParams.state,
                orderData: {
                  ...searchParams.state.orderData,
                  status: e.target.value,
                },
              });
            }}
          >
            {["Any", "Open", "Hold", "Cancelled", "Closed"].map((value) => (
              <MenuItem key={value} value={value.toLowerCase()}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font14">Assigned Status</Typography>
          </InputLabel>
          <Select
            label={<Typography variant="font14">Assigned Status</Typography>}
            value={searchParams.state.orderData.assignedStatus}
            onChange={(e) => {
              searchParams.update({
                ...searchParams.state,
                orderData: {
                  ...searchParams.state.orderData,
                  assignedStatus: e.target.value,
                },
              });
            }}
          >
            {[
              { value: "all", label: "Show All Orders" },
              { value: "unassigned", label: "Show Unassigned Orders Only" },
              { value: "assigned", label: "Show Assigned Orders Only" },
            ].map((element) => (
              <MenuItem key={element.value} value={element.value}>
                {element.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font14">Scheduled Status</Typography>
          </InputLabel>
          <Select
            label={<Typography variant="font14">Scheduled Status</Typography>}
            value={searchParams.state.orderData.scheduledStatus}
            onChange={(e) => {
              searchParams.update({
                ...searchParams.state,
                orderData: {
                  ...searchParams.state.orderData,
                  scheduledStatus: e.target.value,
                },
              });
            }}
          >
            {[
              { value: "all", label: "Show All Orders" },
              { value: "unscheduled", label: "Show Unscheduled Orders Only" },
              { value: "scheduled", label: "Show Scheduled Orders Only" },
            ].map((element) => (
              <MenuItem key={element.value} value={element.value}>
                {element.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Signer Name</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.signerName}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                signerName: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Signer phone</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.signerPhone}
          inputProps={{ maxLength: 16 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                signerPhone: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Signer Email</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.signerEmail}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                signerEmail: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Signing agency</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.signingAgencyName}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                signingAgencyName: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Order Number</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.orderNumber}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                orderNumber: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">File Number</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.fileNumber}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                fileNumber: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          id="received-date-start"
          label={<Typography variant="font14">Received Date Start</Typography>}
          type="date"
          fullWidth
          variant="standard"
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
          value={searchParams.state.orderData.receivedDateStart}
          inputProps={{ maxLength: 16 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                receivedDateStart: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          id="received-date-end"
          label={<Typography variant="font14">Received Date End</Typography>}
          type="date"
          fullWidth
          variant="standard"
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 16 }}
          value={searchParams.state.orderData.receivedDateEnd}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                receivedDateEnd: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font14">Deleted Orders</Typography>
          </InputLabel>
          <Select
            label={<Typography variant="font14">Deleted Orders</Typography>}
            value={searchParams.state.orderData.deletedOrders}
            onChange={(e) => {
              searchParams.update({
                ...searchParams.state,
                orderData: {
                  ...searchParams.state.orderData,
                  deletedOrders: e.target.value,
                },
              });
            }}
          >
            {[
              "Exclude Deleted Orders",
              "Include Deleted Orders",
              "Show Only Deleted Orders",
            ].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Scheduler Name</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.schedulerName}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                schedulerName: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label={<Typography variant="font14">Tracking Number</Typography>}
          variant="standard"
          color="secondary"
          fullWidth
          value={searchParams.state.orderData.trackingNumber}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            searchParams.update({
              ...searchParams.state,
              orderData: {
                ...searchParams.state.orderData,
                trackingNumber: e.target.value,
              },
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default OrderSearchData;
