import React, { Fragment, useEffect } from "react";
import {
  Box,
  Stack,
  Button,
  Typography,
  Grid,
  Modal,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import { acceptOrder, declineOrder } from "service/OrderService";
import OpenMap from "components/common/OpenMap";
import { largeModalStyle } from "components/common/Styles";
import { getOrder } from "service/OrderService";
import { useStateUpdate } from "UseStateUpdate";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { formattedDate, formattedTime } from "components/common/GetDateAndTime";
import ListOrderNotes from "components/orderDetails/sections/ListOrderNotes";
import { isImage } from "components/common/FormValidator";
import { getProfileId } from "components/common/GetUserDetail";
import { getPendingOrderDetails } from "service/OrderService";
import { useNavigate } from "react-router-dom";

function OrderDetailsModal(props) {
  const {
    open,
    orderId,
    severity,
    toastMessage,
    toastOpen,
    fetchOrders,
    isSearchResult,
    callSearchOrder,
    viewAsNotaryAdmin,
    openOrPending,
  } = props;
  const navigate = useNavigate();
  const orderDetails = useStateUpdate({});
  const notaryName = useStateUpdate("");
  const notaryFee = useStateUpdate("");
  const pendingInvites = useStateUpdate([]);
  const selectedContractGroup = useStateUpdate(null);
  const selectedContractGroupIndex = useStateUpdate(0);
  const selectedNotary = useStateUpdate("");

  useEffect(() => {
    open.state && fetchOrderDetails();
  }, [open.state]);

  useEffect(() => {
    if (pendingInvites.state.length > 0) {
      pendingInvites.state.forEach((invites, index) => {
        if (invites.contractGroup === selectedContractGroup.state) {
          selectedNotary.update(invites.notaries[0]);
          selectedContractGroupIndex.update(index);
        }
      });
    }
  }, [selectedContractGroup.state]);

  useEffect(() => {
    if (isNotEmpty(orderDetails.state)) {
      orderDetails.state.notaries.forEach((notary) => {
        if (notary.profileId === getProfileId()) {
          notaryName.update(notary.name);
          notaryFee.update(notary.fee);
          if (!viewAsNotaryAdmin) {
            selectedNotary.update(notary.profileId);
            selectedContractGroup.update(notary.contractGroup);
          }
        }
      });
      viewAsNotaryAdmin &&
        CheckPendingInvitations(orderDetails.state.pendingInvites);
    }
  }, [orderDetails.state]);

  const fetchOrderDetails = async () => {
    var result = await getPendingOrderDetails(orderId, viewAsNotaryAdmin);
    result.status === 200 && orderDetails.update(result.data.data);
  };

  const callAcceptOrder = async (orderId) => {
    const body = {
      contractGroup: selectedContractGroup.state,
      profileId: selectedNotary.state,
    };

    var response = await acceptOrder(orderId, body);
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    open.update(false);
    isSearchResult ? callSearchOrder(1) : fetchOrders();
  };

  const callDeclineOrder = async (orderId) => {
    const body = {
      profileId: selectedNotary.state,
    };

    var response = await declineOrder(orderId, body);
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    open.update(false);
    isSearchResult ? callSearchOrder(1) : fetchOrders();
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const AcceptRejectSection = () => {
    return (
      <Grid container spacing={2} pb={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography variant="font15b">
              {"Hello " + notaryName.state}
            </Typography>
            {viewAsNotaryAdmin ? (
              <Fragment>
                {pendingInvites.state.length > 0 &&
                  (pendingInvites.state.length === 1 ? (
                    pendingInvites.state[0].notaries.length === 1 ? (
                      <Typography variant="font12">
                        {"Do you want to accept order for the notary " +
                          NotaryName(pendingInvites.state[0].notaries[0])}
                      </Typography>
                    ) : (
                      <Fragment>
                        <Typography variant="font12">
                          Do you want to accept order for a notary
                        </Typography>
                        <FormControl
                          fullWidth
                          variant="standard"
                          color="secondary"
                        >
                          <InputLabel>
                            <Typography variant="font12">Notary</Typography>
                          </InputLabel>
                          <Select
                            label={
                              <Typography variant="font12">Notary</Typography>
                            }
                            value={selectedNotary.state}
                            onChange={(e) => {
                              selectedNotary.update(e.target.value);
                            }}
                          >
                            {pendingInvites.state[0].notaries.map(
                              (notaryId) => (
                                <MenuItem key={notaryId} value={notaryId}>
                                  <Typography variant="font12">
                                    {NotaryName(notaryId)}
                                  </Typography>
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Fragment>
                    )
                  ) : (
                    <Fragment>
                      <Typography variant="font12">
                        Do you want to accept order for a notary
                      </Typography>
                      <FormControl
                        fullWidth
                        variant="standard"
                        color="secondary"
                      >
                        <InputLabel>
                          <Typography variant="font12">
                            Contract group
                          </Typography>
                        </InputLabel>
                        <Select
                          label={
                            <Typography variant="font12">
                              Contract group
                            </Typography>
                          }
                          value={selectedContractGroup.state}
                          onChange={(e) => {
                            selectedContractGroup.update(e.target.value);
                          }}
                        >
                          {pendingInvites.state.map((pendingInvite) => (
                            <MenuItem
                              key={pendingInvite.contractGroup}
                              value={pendingInvite.contractGroup}
                            >
                              <Typography variant="font12">
                                {pendingInvite.contractGroup}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        fullWidth
                        variant="standard"
                        color="secondary"
                      >
                        <InputLabel>
                          <Typography variant="font12">Notary</Typography>
                        </InputLabel>
                        <Select
                          label={
                            <Typography variant="font12">Notary</Typography>
                          }
                          value={selectedNotary.state}
                          onChange={(e) => {
                            selectedNotary.update(e.target.value);
                          }}
                        >
                          {pendingInvites.state[
                            selectedContractGroupIndex.state
                          ].notaries.map((notaryId) => (
                            <MenuItem key={notaryId} value={notaryId}>
                              <Typography variant="font12">
                                {NotaryName(notaryId)}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Fragment>
                  ))}
              </Fragment>
            ) : (
              <Fragment>
                <Typography variant="font12">
                  Are you available to service the following order?
                </Typography>
                <Typography variant="font12b">
                  {"Notary fee: $" + parseFloat(notaryFee.state).toFixed(2)}
                </Typography>
              </Fragment>
            )}
          </Stack>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems="flex-end"
        >
          <Stack spacing={1}>
            {openOrPending === "openandpending" && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={(event) => {
                  navigate("/orders/" + orderId);
                }}
              >
                <Typography variant="font14b" color="#000" mx={4}>
                  View order
                </Typography>
              </Button>
            )}
            <Stack
              direction={{ xs: "row", sm: "column", md: "row" }}
              spacing={1}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={(event) => {
                  callDeclineOrder(orderId);
                }}
              >
                <Typography variant="font14" color="#000" mx={4}>
                  Reject
                </Typography>
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={(event) => {
                  callAcceptOrder(orderId);
                }}
              >
                <Typography variant="font14" mx={4}>
                  Accept
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const OrderInformationSection = () => {
    const createData = (label, value) => {
      return { label, value };
    };
    return (
      <Grid container py={2} rowSpacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={3}>
          <Typography variant="font15b">Order Information</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          {isImage(orderDetails.state.signingAgencyLogoUrl) ? (
            <img
              src={orderDetails.state.signingAgencyLogoUrl}
              alt="agency-logo"
              width={{ xs: 100, sm: 125, md: 150 }}
            ></img>
          ) : (
            <Stack direction="row" spacing={1}>
              <Typography variant="font12b">Agency:</Typography>
              <Typography variant="font12">
                {orderDetails.state.signingAgencyName}
              </Typography>
            </Stack>
          )}
        </Grid>
        {[
          createData("Signer name", orderDetails.state.signerName),
          createData("Signer phone", orderDetails.state.signerPhoneNumber),
          createData(
            "Signing date",
            formattedDate(orderDetails.state.signingAt)
          ),
          createData(
            "Signing time",
            formattedTime(orderDetails.state.signingAt)
          ),
        ].map((element, index) => (
          <Grid key={index} item xs={6} sm={4} md={3}>
            <Stack spacing={1}>
              <Typography variant="font12b">{element.label}</Typography>
              <Typography variant="font12">{element.value}</Typography>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={6} sm={4} md={3}>
          {orderDetails.state.signingAddress &&
            orderDetails.state.signingAddress.length > 0 && (
              <Stack spacing={1}>
                <Typography variant="font12b">Signing location</Typography>
                <Stack>
                  <Typography variant="font12">
                    {orderDetails.state.signingAddress[0].streetAddress1 +
                      ", " +
                      orderDetails.state.signingAddress[0].streetAddress2 +
                      ", "}
                  </Typography>
                  <Typography variant="font12">
                    {orderDetails.state.signingAddress[0].city +
                      ", " +
                      orderDetails.state.signingAddress[0].state +
                      " " +
                      orderDetails.state.signingAddress[0].zip +
                      ", "}
                  </Typography>
                  <Typography variant="font12">
                    {orderDetails.state.signingAddress[0].county}
                  </Typography>
                </Stack>
                {orderDetails.state.signingAddress[0].lat &&
                  orderDetails.state.signingAddress[0].long && (
                    <OpenMap
                      lat={orderDetails.state.signingAddress[0].lat}
                      long={orderDetails.state.signingAddress[0].long}
                    />
                  )}
              </Stack>
            )}
        </Grid>
      </Grid>
    );
  };

  const OrderNotesSection = () => {
    return (
      <Grid container py={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="font15b">Order Notes</Typography>
        </Grid>
        <Grid item xs={12}>
          {orderDetails.state.notes && (
            <ListOrderNotes notes={orderDetails.state.notes} />
          )}
        </Grid>
      </Grid>
    );
  };

  const OrderNoteAddSection = () => {
    return (
      <Grid container py={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="font15b">Note</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            color="secondary"
            label={<Typography variant="font12">Enter note</Typography>}
            multiline
            rows={2}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={(event) => {
              // callDeclineOrder(row.orderNumber);
            }}
          >
            <Typography variant="font14" color="#000">
              Add note
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  function CheckPendingInvitations(pendingInvitesData) {
    var tempPendingInvites = [];
    for (const [contractGroup, profileIds] of Object.entries(
      pendingInvitesData
    )) {
      tempPendingInvites.push({
        contractGroup: contractGroup,
        notaries: profileIds,
      });
    }
    selectedContractGroup.update(tempPendingInvites[0].contractGroup);
    selectedNotary.update(tempPendingInvites[0].notaries[0]);
    pendingInvites.update(tempPendingInvites);
  }

  const NotaryName = (notaryId) => {
    var name = "";
    orderDetails.state.notaries.forEach((notary) => {
      notary.profileId === notaryId && (name = notary.name);
    });
    return name;
  };

  return (
    <Modal open={open.state} onClose={() => open.update(false)}>
      <Box sx={largeModalStyle}>
        {isNotEmpty(orderDetails.state) && (
          <Fragment>
            <AcceptRejectSection />
            <Divider />
            <OrderInformationSection />
            <Divider />
            <OrderNotesSection />
            <Divider />
            <OrderNoteAddSection />
          </Fragment>
        )}
      </Box>
    </Modal>
  );
}

export default OrderDetailsModal;
