import React from "react";
import Chart from "react-google-charts";
import { Box } from "@mui/material";

function DrawChart({ chartType, data, width }) {
  const options = {
    legend: { position: "none" },
  };

  return (
    <Box sx={{ width: { xs: "80vw", sm: "100%" } }}>
      <Chart
        chartType={chartType}
        data={data}
        width={width}
        options={options}
      />
    </Box>
  );
}
export default DrawChart;
