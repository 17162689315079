import React, { Fragment, useEffect } from "react";

import { Grid, Paper, Typography, Box, Stack, Skeleton } from "@mui/material";

import { useStateUpdate } from "UseStateUpdate";
import { getNotarySummary } from "service/AdminService";
import { getOrderSummary } from "service/AdminService";
import DrawChart from "components/common/DrawChart";
import { getBarChartData } from "service/AdminService";
import { getLineChartData } from "service/AdminService";

function createFigures(title, value, type) {
  return { title, value, type };
}

const skeleton = () => {
  return (
    <Fragment>
      {[1, 2, 3, 4].map((index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Paper variant="outlined">
            <Box p={1} pb={{ xs: 1, md: 4 }}>
              <Stack spacing={2}>
                <Skeleton animation="wave" variant="rectangular" />
                <Grid container pt={{ xs: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Fragment>
  );
};

function AdminDashboardSummary() {
  const notaryFigures = useStateUpdate([]);
  const orderFigures = useStateUpdate([]);
  const BarChartData = useStateUpdate([]);
  const LineChartData = useStateUpdate([]);

  useEffect(() => {
    fetchSummary();
    fetchBarChartData();
    fetchLineChartData();
  }, []);

  const fetchSummary = async () => {
    var notarySummary = await getNotarySummary();

    if (notarySummary.status === 200) {
      notaryFigures.update([
        createFigures("New Notaries", notarySummary.data.data.newCount, "new"),
        createFigures(
          "Total Notaries",
          notarySummary.data.data.totalCount,
          "total"
        ),
      ]);
    }

    var orderSummary = await getOrderSummary();
    if (orderSummary.status === 200) {
      orderFigures.update([
        createFigures("New Orders", orderSummary.data.data.newOrderCount),
        createFigures("Total Orders", orderSummary.data.data.totalOrderCount),
      ]);
    }
  };

  const fetchBarChartData = async () => {
    var result = await getBarChartData();
    var data = [["Status", "Count"]];
    if (result.status === 200) {
      Object.keys(result.data.data).forEach((key) => {
        data.push([key, result.data.data[key]]);
      });

      BarChartData.update(data);
    }
  };

  const fetchLineChartData = async () => {
    var result = await getLineChartData();
    var data = [["Date", "count"]];
    if (result.status === 200) {
      Object.keys(result.data.data).forEach((key) => {
        data.push([key, result.data.data[key]]);
      });

      LineChartData.update(data);
    }
  };

  return notaryFigures.state.length > 0 && orderFigures.state.length > 0 ? (
    <Fragment>
      {notaryFigures.state.map((figure) => (
        <Grid key={figure.title} container item xs={6} sm={3}>
          <Paper variant="outlined" sx={{ width: "100%" }}>
            <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
              <Stack spacing={2}>
                <Typography variant="font15b" color="#686868" noWrap>
                  {figure.title}
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  pt={{ xs: 1, md: 2 }}
                >
                  <Typography variant="font32b" color="#686868">
                    {figure.value}
                  </Typography>
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
      {orderFigures.state.map((figure) => (
        <Grid key={figure.title} container item xs={12} md={6}>
          <Paper variant="outlined" sx={{ width: "100%" }}>
            <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
              <Stack spacing={2}>
                <Typography variant="font15b" color="#686868" noWrap>
                  {figure.title === "Total Orders"
                    ? figure.title + " - " + figure.value
                    : figure.title}
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  pt={{ xs: 1, md: 2 }}
                >
                  {figure.title === "New Orders" ? (
                    <DrawChart
                      chartType="Line"
                      width="100%"
                      data={LineChartData.state}
                    />
                  ) : (
                    <DrawChart chartType="Bar" data={BarChartData.state} />
                  )}
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Fragment>
  ) : (
    skeleton()
  );
}

export default AdminDashboardSummary;
