import React from "react";
import { useNavigate } from "react-router-dom";
//MaterialUi
import {
  Container,
  Grid,
  Paper,
  Button,
  Stack,
  Typography,
} from "@mui/material";
//Styles
import { bannerStyle } from "../common/Styles";

function Landing() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Paper style={bannerStyle.paperContainer}>
        <Grid py={6} pr={{ xs: 8, sm: 25, md: 40 }} align="right">
          <Grid item xs={12}>
            <Typography variant="banner" display="block">
              Become a Notary
            </Typography>
            <Typography variant="banner" display="block" mb={2}>
              Signing Agent
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/registration", { replace: true })}
            >
              <Typography variant="buttonLabel" mx={4}>
                Get started
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Container maxWidth="md">
        <Grid align="center" justify="center">
          <Grid item xs={12} mt={5}>
            <Typography variant="body2" align="justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </Grid>
          <Grid item xs={12} mt={5} alignContent="center">
            <Button variant="contained" color="secondary" p={5}>
              <Typography variant="buttonLabel" mx={5} my={1} color="#ffffff">
                Read More
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
}

export default Landing;
