import axios from "components/common/Axios";

const SERVICE_URL = "registration/v1/";

export const postRegisterData = async (registerData) => {
  return await axios
    .post(SERVICE_URL + "register", registerData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const postSecretCode = async (secretCodeData) => {
  return await axios
    .post(
      SERVICE_URL +
        "registration/" +
        secretCodeData.registrationId +
        "/verify-email",
      secretCodeData
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const postPasswords = async (passwordData) => {
  return await axios
    .post(
      SERVICE_URL +
        "registration/" +
        passwordData.registrationId +
        "/set-password",
      passwordData
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const checkNNAProfile = async (email) => {
  return await axios
    .get(SERVICE_URL + "registration/check-email-exists?emailId=" + email, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const fetchNNAProfile = async (personGuid) => {
  return await axios
    .get(SERVICE_URL + "nna-data/" + personGuid, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateFromNnaProfile = async (registrationId, fetchedData) => {
  return await axios
    .put(
      SERVICE_URL + "profile/" + registrationId + "/update-profile",
      fetchedData
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
