import React from "react";
// mui
import { Box, Tooltip } from "@mui/material/";

function StatusIcons({ title, color, icon }) {
  return (
    <Tooltip title={title}>
      <Box
        sx={{
          width: 25,
          height: 20,
          backgroundColor: color,
          borderRadius: "40%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Box>
    </Tooltip>
  );
}

export default StatusIcons;
