import React, { Fragment, useEffect } from "react";
import {
  Divider,
  Typography,
  Grid,
  Stack,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material/";
import { useStateUpdate } from "UseStateUpdate";
import { getNotes } from "service/NoteService";
import { getProfileId } from "components/common/GetUserDetail";
import CloseIcon from "@mui/icons-material/Close";

function AddNote({ open, orderDetails }) {
  const selectedTemplate = useStateUpdate("");
  const noteTemplates = useStateUpdate([]);
  const note = useStateUpdate("");

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    noteTemplates.state.forEach((noteTemplate) => {
      noteTemplate.noteTemplateId === selectedTemplate.state &&
        note.update(noteTemplate.body);
    });
  }, [selectedTemplate.state]);

  const fetchNotes = async () => {
    var result = await getNotes(getProfileId());
    result.status === 200 && noteTemplates.update(result.data.data.records);
  };

  return (
    <Fragment>
      <Grid pb={1}>
        <Typography variant="font15b">Add note</Typography>
      </Grid>
      <Divider />
      <Grid container pt={3} spacing={2}>
        <Grid item xs={6} sm={4}>
          <Stack spacing={1}>
            <Typography variant="font12b">Signer name</Typography>
            <Typography variant="font12">{orderDetails.signerName}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack spacing={1}>
            <Typography variant="font12b">Originator</Typography>
            <Typography variant="font12">
              {orderDetails.originatorName}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Stack spacing={1}>
            <Typography variant="font12b">Notaries</Typography>
            <Stack>
              {orderDetails.notaries.map((notary, index) => (
                <Typography key={index} variant="font12">
                  {notary.name}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid container pt={3}>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="font15b">Note template lookup</Typography>
            <FormControl
              variant="standard"
              color="secondary"
              sx={{ width: { sm: "60%" } }}
            >
              <InputLabel>Template name</InputLabel>
              <Select
                label={<Typography variant="font12">Template name</Typography>}
                value={selectedTemplate.state}
                onChange={(e) => {
                  selectedTemplate.update(e.target.value);
                }}
                endAdornment={
                  <IconButton
                    sx={{
                      display: selectedTemplate.state !== "" ? "" : "none",
                      marginRight: "10px",
                    }}
                    onClick={() => selectedTemplate.update("")}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {noteTemplates.state.map((noteTemplate, index) => (
                  <MenuItem key={index} value={noteTemplate.noteTemplateId}>
                    {noteTemplate.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      <Grid container py={3} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="font15b">Note</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="secondary"
            label={<Typography variant="font12">Enter note</Typography>}
            multiline
            rows={2}
            value={note.state}
            onChange={(e) => {
              note.update(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container py={3}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="font14">
                {"Send to " + orderDetails.originatingCompany}
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          alignItems="center"
        >
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => open.update(false)}
            >
              <Typography variant="font14b" color="#000" mx={{ xs: 0, sm: 3 }}>
                Cancel
              </Typography>
            </Button>
            <Button variant="contained" size="small" onClick={(event) => {}}>
              <Typography variant="font14b" mx={{ xs: 0, sm: 3 }}>
                Add note
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AddNote;
