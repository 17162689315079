import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  Hidden,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  CircularProgress,
} from "@mui/material";

import Toast from "components/common/Toast";
import { useIsAdmin } from "components/common/GetUserDetail";
import { useNavigate } from "react-router-dom";
import { isEmptyString } from "components/common/FormValidator";
import { useStateUpdate } from "UseStateUpdate";
import { isValidEmail } from "components/common/FormValidator";
import CustomMultipleSelect from "components/profile/common_componets/CustomMultipleSelect";
import { getAllRoleList } from "service/AdminService";
import { createUser } from "service/AdminService";
import { updateUser } from "service/AdminService";
import { forceResetOrTempPasswordGenerate } from "service/AdminService";

function AdminAddNewUser({ isUpdate, userDetails, closeModal }) {
  let navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const firstName = useStateUpdate("");
  const lastName = useStateUpdate("");
  const email = useStateUpdate("");
  const roles = useStateUpdate([]);
  const selectedRoles = useStateUpdate([]);
  const generateTempPassword = useStateUpdate(true);
  const isLoader = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const temporaryPassoword = useStateUpdate(null);

  useEffect(() => {
    !isAdmin ? navigate("/dashboard") : fetchRoleList();
  }, []);

  const fetchRoleList = async () => {
    var result = await getAllRoleList();
    if (result.status === 200) {
      roles.update(result.data.data);
    }
  };

  useEffect(() => {
    if (userDetails && roles.state.length > 0) {
      email.update(userDetails.email);
      firstName.update(userDetails.firstName);
      lastName.update(userDetails.lastName);
      selectedRoles.update(roleList(userDetails.roles));
    }
  }, [userDetails, roles.state]);

  const roleList = (roleIds) => {
    var roleListIs = [];
    roles.state.forEach((role) => {
      roleIds.indexOf(role.id) > -1 && roleListIs.push(role);
    });
    return roleListIs;
  };

  useEffect(() => {
    validationError.update({
      ...validationError.state,
      role: "",
    });
  }, [selectedRoles.state]);

  const validationError = useStateUpdate({
    email: "",
    firstName: "",
    lastName: "",
    role: "",
  });

  function checkValidatorOnAddUser() {
    let isValidated = true;
    let newValidationError = {
      email: "",
      firstName: "",
      lastName: "",
      role: "",
    };
    if (isEmptyString(firstName.state)) {
      newValidationError = {
        ...newValidationError,
        firstName: "First Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(lastName.state)) {
      newValidationError = {
        ...newValidationError,
        lastName: "Last Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is required",
      };
      isValidated = false;
    } else if (!isValidEmail(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is not valid.",
      };
      isValidated = false;
    }
    if (selectedRoles.state.length === 0) {
      newValidationError = {
        ...newValidationError,
        role: "Select atleast one role",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  const changeUserRoles = (profileId) => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user.profile_id === profileId) {
      var newRoles = [];
      selectedRoles.state.forEach((role) => newRoles.push(role.code));
      user = { ...user, roles: newRoles };
      localStorage.setItem("user", JSON.stringify(user));
      window.location.reload();
    }
  };

  const callCreateOrUpdateUser = async () => {
    isLoader.update(true);
    let data = getUserDetails();
    if (isUpdate) {
      var response = await updateUser(userDetails.profileId, data);
      if (response.status === 200) {
        if (response.data.code === 200) {
          calltoast("success", response.data.message);
          changeUserRoles(userDetails.profileId);
          navigate("/dashboard/manage-users");
        } else {
          calltoast("error", response.data.message);
        }
      } else {
        calltoast("error", response.data.message);
      }
      closeModal();
    } else {
      var response = await createUser(data);

      if (response.status === 200) {
        if (response.data.code === 200) {
          calltoast("success", response.data.message);
          navigate("/dashboard/manage-users");
        } else if (response.data.code === 422) {
          calltoast("error", response.data.message);
          validationError.update({
            ...validationError.state,
            email: response.data.error.email[0],
          });
        } else {
          calltoast("error", response.data.message);
        }
      } else {
        calltoast("error", response.data.message);
      }
    }

    isLoader.update(false);
  };

  const passwordChange = async (event) => {
    var body = {
      isTempPassword: event === "trigger" ? false : true,
    };

    var response = await forceResetOrTempPasswordGenerate(
      userDetails.profileId,
      body
    );

    if (response.status === 200) {
      event === "temporary" &&
        temporaryPassoword.update(response.data.data.tempPassword);

      calltoast("success", response.data.message);
    } else {
      calltoast("error", response.data.message);
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const getUserDetails = () => {
    const details = {
      first_name: firstName.state,
      last_name: lastName.state,
      roles: getRoles(),
      isPassword: generateTempPassword.state,
    };
    return isUpdate ? details : { ...details, email: email.state };
  };

  const getRoles = () => {
    var roles = [];
    selectedRoles.state.forEach((role) => roles.push(role.id));
    return roles;
  };

  const Footer = () => {
    return (
      <Box p={!isUpdate && 4} pt={isUpdate && 2}>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={isLoader.state}
            size="small"
            onClick={(e) => {
              checkValidatorOnAddUser() && callCreateOrUpdateUser();
            }}
          >
            <Typography variant="font14b" mx={4} noWrap>
              {isUpdate ? "Update User" : "Add User"}
            </Typography>
            {isLoader.state && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  marginLeft: "-10px",
                }}
              />
            )}
          </Button>
        </Grid>
      </Box>
    );
  };

  return isAdmin ? (
    <Box ml={!isUpdate && { xs: 2, md: 3 }} mr={!isUpdate && { xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b">
          {isUpdate ? "Update user" : "Add user"}
        </Typography>
      </Grid>
      <Paper variant={isUpdate ? "" : "outlined"}>
        <Box p={isUpdate ? 1 : 4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={isUpdate ? 12 : 6}>
              <TextField
                disabled={isUpdate}
                error={validationError.state.email === "" ? false : true}
                helperText={
                  validationError.state.email === ""
                    ? ""
                    : validationError.state.email
                }
                type="email"
                id="email"
                label={<Typography variant="font15">Email</Typography>}
                variant="standard"
                color="secondary"
                fullWidth
                value={email.state}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    email: "",
                  });
                  email.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      email: "Email is required",
                    });
                  } else if (!isValidEmail(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      email: "Email is not valid.",
                    });
                  }
                }}
              />
            </Grid>
            {!isUpdate && (
              <Hidden mdDown>
                <Grid item xs={6}></Grid>
              </Hidden>
            )}

            <Grid item xs={12} md={isUpdate ? 12 : 6}>
              <TextField
                error={validationError.state.firstName === "" ? false : true}
                helperText={validationError.state.firstName}
                id="register-first-name"
                label={<Typography variant="font15">First Name</Typography>}
                variant="standard"
                color="secondary"
                fullWidth
                value={firstName.state}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    firstName: "",
                  });
                  firstName.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      firstName: "First Name is required",
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={isUpdate ? 12 : 6}>
              <TextField
                error={validationError.state.lastName === "" ? false : true}
                helperText={validationError.state.lastName}
                id="register-last-name"
                label={<Typography variant="font15">Last Name</Typography>}
                variant="standard"
                color="secondary"
                fullWidth
                value={lastName.state}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    lastName: "",
                  });
                  lastName.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      lastName: "Last Name is required",
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={isUpdate ? 12 : 6}>
              <CustomMultipleSelect
                variant="standard"
                size="normal"
                label={<Typography variant="font15">Roles</Typography>}
                options={roles.state}
                selectedOptions={selectedRoles.state}
                setSelectedOptions={selectedRoles.update}
                error={validationError.state.role === "" ? false : true}
              />
              <Typography variant="font13" color="error">
                {validationError.state.role}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={3}>
              {!isUpdate ? (
                <Grid item xs={12} mt={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        color="secondary"
                        checked={generateTempPassword.state}
                        onChange={(e) => {
                          generateTempPassword.update(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Stack>
                        <Typography variant="font15">
                          Generate temporary password
                        </Typography>
                        <Typography variant="font11">
                          A temporary password will be shared along the welcome
                          email to the user
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      passwordChange("trigger");
                    }}
                  >
                    <Typography variant="font12">
                      Trigger password change
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      passwordChange("temporary");
                    }}
                  >
                    <Typography variant="font12">
                      Generate temporary password
                    </Typography>
                  </Button>
                </Stack>
              )}
            </Grid>
            {temporaryPassoword.state !== null && (
              <Grid item xs={12} mt={3}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="font12">Temporary Password:</Typography>

                  <Typography variant="font14b">
                    {temporaryPassoword.state}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Footer />
      </Paper>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  ) : (
    <div />
  );
}

export default AdminAddNewUser;
