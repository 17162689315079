import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Box,
  Stack,
  Button,
  Typography,
  Grid,
  Pagination,
  Link,
} from "@mui/material/";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArticleIcon from "@mui/icons-material/Article";
import {
  WARNING_YELLOW,
  WARNING_GREEN,
  WARNING_BLUE,
  WARNING_RED,
} from "components/common/Constant";
import StatusIcons from "components/common/StatusIcons";
import OrderDetailsModal from "./OrderDetailsModal";
import { useStateUpdate } from "UseStateUpdate";
import { MAPBASEURL } from "components/common/Constant";
import { rowHoverClickStyle } from "components/common/Styles";

const columnHeading = (heading) => {
  return (
    <TableCell>
      <Typography key={heading} variant="font12b" noWrap>
        {heading}
      </Typography>
    </TableCell>
  );
};

const columnValues = (isPending, component) => {
  return (
    <TableCell
      style={{
        borderBottom: isPending ? "none" : "block",
      }}
    >
      {component}
    </TableCell>
  );
};

const iconProps = { color: "#fff", fontSize: 15 };

function OrderTable(props) {
  let navigate = useNavigate();
  const openModal = useStateUpdate(false);
  const orderId = useStateUpdate("");
  const openOrPending = useStateUpdate("");

  const classes = rowHoverClickStyle();

  const { label, tableRows, buttonColumn, pageCount, page } = props;

  const isPending = label === "Pending signings";

  return (
    <Fragment>
      <OrderDetailsModal
        open={openModal}
        openOrPending={openOrPending.state}
        orderId={orderId.state}
        {...props}
      />
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {buttonColumn && columnHeading("Action")}
              {!isPending && columnHeading("Order #")}
              {columnHeading("Signing Agency")}
              {!isPending && columnHeading("Order Status")}
              {columnHeading("Signing Type")}
              {columnHeading("Signing Date")}
              {columnHeading("Signer")}
              {!isPending && columnHeading("Originator")}
              {columnHeading("Originating Company")}
              {!isPending && columnHeading("File Number")}
              {!isPending && columnHeading("Notaries")}
              {columnHeading("Scheduler")}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.length > 0 ? (
              tableRows.map((row, index) => (
                <Fragment key={index}>
                  <TableRow
                    hover
                    classes={{
                      hover: !isPending ? classes.hover : null,
                    }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={(event) => {
                      if (!isPending && row.isAccepted === "open") {
                        navigate("/orders/" + row.orderId);
                      } else {
                        openModal.update(true);
                        openOrPending.update(row.isAccepted);
                        orderId.update(row.orderId);
                        event.stopPropagation();
                      }
                    }}
                  >
                    {buttonColumn &&
                      columnValues(
                        isPending,

                        <Button
                          variant="contained"
                          size="small"
                          onClick={(event) => {
                            openModal.update(true);
                            orderId.update(row.orderId);
                            event.stopPropagation();
                          }}
                        >
                          <Typography variant="font8">View details</Typography>
                        </Button>
                      )}
                    {!isPending &&
                      columnValues(
                        isPending,
                        <Typography variant="font12">
                          {row.orderNumber}
                        </Typography>
                      )}
                    {columnValues(
                      isPending,
                      <Stack>
                        <Typography variant="font12">
                          {row.signingAgency}
                        </Typography>
                      </Stack>
                    )}
                    {!isPending &&
                      columnValues(
                        isPending,
                        <Stack direction="row" spacing={0.3}>
                          <StatusIcons
                            title={"Order Status: " + row.orderStatus}
                            color={
                              row.orderStatus.toLowerCase() === "pending"
                                ? WARNING_RED
                                : row.orderStatus.toLowerCase() === "hold"
                                ? WARNING_YELLOW
                                : row.orderStatus.toLowerCase() === "open"
                                ? WARNING_BLUE
                                : WARNING_GREEN
                            }
                            icon={<MenuBookIcon style={{ ...iconProps }} />}
                          />
                          <StatusIcons
                            title={
                              row.signingDate
                                ? "Signing is scheduled"
                                : "Signing is not scheduled"
                            }
                            color={
                              row.signingDate ? WARNING_GREEN : WARNING_RED
                            }
                            icon={
                              <InsertInvitationIcon style={{ ...iconProps }} />
                            }
                          />
                          <StatusIcons
                            title={
                              row.hasDocuments
                                ? "Order has documents"
                                : "Order does not have documents"
                            }
                            color={
                              row.hasDocuments ? WARNING_GREEN : WARNING_RED
                            }
                            icon={<ArticleIcon style={{ ...iconProps }} />}
                          />
                          <StatusIcons
                            title={
                              row.hasNotes
                                ? "Order has notes"
                                : "Order does not have notes"
                            }
                            color={row.hasNotes ? WARNING_GREEN : WARNING_RED}
                            icon={<AttachFileIcon style={{ ...iconProps }} />}
                          />
                        </Stack>
                      )}
                    {columnValues(
                      isPending,
                      <Typography variant="font12">
                        {row.signingType}
                      </Typography>
                    )}
                    {columnValues(
                      isPending,
                      <Stack>
                        <Typography variant="font12">
                          {row.signingDate}
                        </Typography>
                        <Typography variant="font11">
                          {row.signingTime}
                        </Typography>
                      </Stack>
                    )}
                    {columnValues(
                      isPending,
                      <Stack>
                        <Typography variant="font12">{row.signer}</Typography>
                        <Typography variant="font10" noWrap>
                          {row.signerPhone}
                        </Typography>
                      </Stack>
                    )}
                    {!isPending &&
                      columnValues(
                        isPending,
                        <Stack>
                          <Typography variant="font12">
                            {row.originator}
                          </Typography>
                          <Typography variant="font10">
                            {row.originatorEmail}
                          </Typography>
                        </Stack>
                      )}
                    {columnValues(
                      isPending,
                      <Stack>
                        <Typography variant="font12">
                          {row.originating}
                        </Typography>
                        <Typography variant="font10" noWrap>
                          {row.originatingPhone}
                        </Typography>
                      </Stack>
                    )}
                    {!isPending && (
                      <Fragment>
                        {columnValues(
                          isPending,
                          <Typography variant="font12">
                            {row.fileNumber}
                          </Typography>
                        )}
                        {columnValues(
                          isPending,
                          <Stack>
                            <Typography variant="font12">
                              {row.notaries}
                            </Typography>
                            <Typography variant="font10" noWrap>
                              {row.notariesPhone}
                            </Typography>
                          </Stack>
                        )}
                      </Fragment>
                    )}
                    {columnValues(
                      isPending,
                      <Typography variant="font12">{row.scheduler}</Typography>
                    )}
                  </TableRow>
                  {isPending && (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        size="small"
                        colSpan={9}
                        style={{ paddingBottom: "10px" }}
                      >
                        {row.address && row.lat && row.long && (
                          <Link
                            href={MAPBASEURL + row.lat + "," + row.long}
                            target="_blank"
                            variant="font11"
                            color="secondary.light"
                            underline="none"
                          >
                            {row.address}
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={isPending ? 7 : 11}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="font15">NO DATA FOUND</Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          item
          px={4}
        >
          <Stack spacing={1}>
            <Pagination
              count={pageCount}
              showFirstButton
              showLastButton
              size="small"
              onChange={(e, value) => {
                page.update(value);
              }}
            />
          </Stack>
        </Grid>
      )}
    </Fragment>
  );
}

export default OrderTable;
