import React, { useEffect, Fragment } from "react";
import {
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  Stack,
  AccordionDetails,
} from "@mui/material";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ArrowsExpandSvg } from "assets/images/arrows-expand.svg";
import { useStateUpdate } from "UseStateUpdate";

function OrderInstructions({ orderDetails }) {
  const instructions = useStateUpdate([]);

  useEffect(() => {
    instructions.update(
      orderDetails.instructions ? orderDetails.instructions : []
    );
  }, [orderDetails]);

  return instructions.state.length === 0 ? null : (
    <Paper variant="outlined" sx={{ width: "100%", borderColor: "#CC5555" }}>
      <Box sx={{ width: "100%" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <SvgIcon>
                <ArrowsExpandSvg />
              </SvgIcon>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack>
              <Typography variant="font15b" color="#CC5555">
                Order Instructions
              </Typography>
              <Typography variant="font15b">NOTARY - PLEASE READ</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {instructions.state.map((instruction) => (
              <Fragment key={instruction.id}>
                <Typography variant="font14b">{instruction.title}</Typography>
                <Typography variant="font14">{instruction.body}</Typography>
              </Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paper>
  );
}

export default OrderInstructions;
