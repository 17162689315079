import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";
import {
  Box,
  Drawer,
  CssBaseline,
  List,
  Divider,
  ListItem,
  ListItemText,
  Hidden,
  Typography,
} from "@mui/material";

import { Main, DrawerHeader } from "components/common/Styles";

import {
  DRAWER_WIDTH as drawerWidth,
  DASHBOARD_MENU as dashBoardMenu,
} from "components/common/Constant";

import logo from "logo.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { roleAccess } from "components/common/GetUserDetail";

function MainDrawer(props) {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const urlParam = useLocation().search;
  const { open, window, children } = props;
  const openUserSubMenu = useStateUpdate(false);
  const openOrderSubMenu = useStateUpdate(false);
  const openTemplateSubMenu = useStateUpdate(false);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    switch (pathname) {
      case "/notetemplates":
        openTemplateSubMenu.update(true);
        break;
      case "/orders":
        openOrderSubMenu.update(true);
        break;
      default:
        if (pathname.indexOf("/dashboard/manage") > -1) {
          openUserSubMenu.update(true);
        } else {
          openTemplateSubMenu.update(false);
          openOrderSubMenu.update(false);
          openUserSubMenu.update(false);
        }
        break;
    }
  }, [pathname]);

  const subMenu = (subMenuState, subMenuItem, isMobile) => {
    return (
      <Collapse in={subMenuState} timeout="auto" unmountOnExit>
        <List>
          {subMenuItem.map(
            (item) =>
              roleAccess(item.roles) && (
                <ListItem
                  selected={item.path === pathname + urlParam}
                  sx={{
                    color: item.path === pathname + urlParam && "#93E1EB",
                    pl: 4,
                  }}
                  button
                  key={item.label}
                  onClick={(e) => {
                    isMobile && open.update(!open.state);
                    navigate(item.path);
                  }}
                >
                  <Typography variant="font15" noWrap>
                    {item.label}
                  </Typography>
                </ListItem>
              )
          )}
        </List>
      </Collapse>
    );
  };

  const drawer = (isMobile) => {
    const expandOrCollapseMenu = (expand, path) => {
      path && isMobile && open.update(!open.state);
      path && navigate(path);
      openOrderSubMenu.update(
        expand === "Order management" && !openOrderSubMenu.state
      );
      openTemplateSubMenu.update(
        expand === "Templates" && !openTemplateSubMenu.state
      );
      openUserSubMenu.update(
        expand === "User management" && !openUserSubMenu.state
      );
    };

    return (
      <div>
        <DrawerHeader>
          <img src={logo} alt="logo" />
        </DrawerHeader>
        <Divider />
        <List>
          {dashBoardMenu.map(
            (items, index) =>
              roleAccess(items.roles) && (
                <Fragment key={index}>
                  <ListItem
                    selected={items.path === pathname + urlParam}
                    sx={{
                      color: items.path === pathname + urlParam && "#93E1EB",
                    }}
                    button
                    key={items.label}
                    onClick={(e) => {
                      expandOrCollapseMenu(items.label, items.path);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="font15" noWrap>
                          {items.label}
                        </Typography>
                      }
                    />
                    {items.label === "User management" &&
                      (openUserSubMenu.state ? <ExpandLess /> : <ExpandMore />)}
                    {items.label === "Order management" &&
                      (openOrderSubMenu.state ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                    {items.label === "Templates" &&
                      (openTemplateSubMenu.state ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </ListItem>
                  {items.label === "User management" &&
                    subMenu(openUserSubMenu.state, items.subMenu, isMobile)}
                  {items.label === "Order management" &&
                    subMenu(openOrderSubMenu.state, items.subMenu, isMobile)}
                  {items.label === "Templates" &&
                    subMenu(openTemplateSubMenu.state, items.subMenu, isMobile)}
                </Fragment>
              )
          )}
        </List>
      </div>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Hidden mdUp>
        <Drawer
          container={container}
          variant="temporary"
          open={open.state}
          onClose={() => open.update(!open.state)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#1D2C48",
              color: "#fff",
            },
          }}
        >
          {drawer(true)}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "#1D2C48",
              color: "#fff",
            },
          }}
          sx={{
            display: { xs: "none", md: "block" },
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open.state}
        >
          {drawer(false)}
        </Drawer>
      </Hidden>
      <Main
        open={open.state}
        sx={{
          marginLeft: open.state
            ? { xs: "0px" }
            : { xs: "0px", md: `-${drawerWidth}px` },
        }}
      >
        {children}
      </Main>
    </Box>
  );
}

export default MainDrawer;
