import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  Stack,
  Rating,
  IconButton,
  Button,
  Pagination,
} from "@mui/material/";
import { largeModalStyle } from "components/common/Styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useStateUpdate } from "UseStateUpdate";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
// import "components/common/hideScrollBar.css";
import { getAverageRating, getAgenciesRating } from "service/AdminService";
import { formatRating } from "components/common/FormatRating";
import { formattedDate } from "components/common/GetDateAndTime";

const drawerWidth = 240;

function NotaryRatingDetailModal({
  open,
  profileId,
  notaryName,
  overallRating,
  window,
}) {
  const expanded = useStateUpdate(1);
  const mobileOpen = useStateUpdate(false);
  const averageRating = useStateUpdate([]);
  const agencyRating = useStateUpdate([]);
  const signingAgencyId = useStateUpdate(null);
  const rowsPerPage = useStateUpdate(4);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(1);

  useEffect(() => {
    fetchAverageRating();
  }, []);

  useEffect(() => {
    averageRating.state.length > 0 &&
      signingAgencyId.update(averageRating.state[0].signingAgencyId);
  }, [averageRating.state]);

  useEffect(() => {
    signingAgencyId.state !== null && fetchAgenciesRating();
  }, [signingAgencyId.state, page.state]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  const fetchAverageRating = async () => {
    var result = await getAverageRating(profileId);
    result.status === 200 && averageRating.update(result.data.data);
  };

  const fetchAgenciesRating = async () => {
    var result = await getAgenciesRating(
      profileId,
      signingAgencyId.state,
      page.state,
      rowsPerPage.state
    );
    if (result.status === 200) {
      agencyRating.update(result.data.data.data);
      pageCount.update(result.data.data.pagination.total_pages);
    }
  };

  const handleDrawerToggle = () => {
    mobileOpen.update(!mobileOpen.state);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List sx={{ pt: { xs: 5, sm: 0 } }}>
        {averageRating.state.map((rating, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              backgroundColor:
                rating.signingAgencyId === signingAgencyId.state ? "#fff" : "",
            }}
          >
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                signingAgencyId.update(rating.signingAgencyId);
              }}
            >
              <Stack>
                <Typography variant="font15b">
                  {rating.signingAgencyName}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="font15">Average rating:</Typography>
                  <Typography variant="font15b">
                    {formatRating(rating.averageRating)}
                  </Typography>
                </Stack>
                <Rating
                  defaultValue={parseFloat(rating.averageRating) / 2}
                  precision={0.5}
                  readOnly
                />
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleChange = (panel) => (event, newExpanded) => {
    expanded.update(newExpanded ? panel : false);
  };

  const handleClose = () => () => {
    open.update(false);
  };

  const RatingDetails = () => {
    return (
      <Box>
        <Stack spacing={3}>
          {agencyRating.state.map((rating, index) => (
            <Stack key={index} spacing={2}>
              <Stack>
                <Typography variant="font15b">
                  {rating.originatorName}
                </Typography>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <Stack direction="row" spacing={2}>
                    <Typography variant="font15">
                      Rating : <b>{formatRating(rating.rating)}</b>
                    </Typography>
                    <Rating
                      defaultValue={parseFloat(rating.rating) / 2}
                      precision={0.5}
                      readOnly
                    />
                  </Stack>
                  <Typography variant="font15">
                    {formattedDate(rating.reviewedOn)}
                  </Typography>
                </Stack>
              </Stack>
              <Typography variant="font13" width={"90%"}>
                {rating.review}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  };

  const ModalHeader = () => {
    return (
      <Grid container>
        <Grid item xs={11}>
          <Toolbar>
            <Stack direction="row">
              <IconButton
                color="inherit"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ sm: 2 }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography
                    sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                  >
                    Rating
                  </Typography>
                  <Typography
                    sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                    color="secondary.light"
                    noWrap
                  >
                    {notaryName}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography
                    sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                  >
                    {formatRating(overallRating)}
                  </Typography>
                  <Rating
                    defaultValue={parseFloat(overallRating) / 2}
                    precision={0.5}
                    readOnly
                  />
                </Stack>
              </Stack>
            </Stack>
          </Toolbar>
        </Grid>
        <Grid
          container
          item
          xs={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton onClick={handleClose()}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const ModalFooter = () => {
    return (
      <Stack p={2} direction="row" spacing={2} justifyContent="flex-end">
        <Pagination
          count={pageCount.state}
          showFirstButton
          showLastButton
          size="small"
          page={page.state}
          onChange={(e, value) => {
            page.update(value);
          }}
        />
        <Button
          variant="contained"
          size="small"
          onClick={handleClose()}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography variant="font14b">Close</Typography>
        </Button>
      </Stack>
    );
  };

  return (
    <Modal open={open.state} onClose={handleClose()}>
      <Box sx={{ ...largeModalStyle, p: 0 }}>
        <Stack>
          <Grid container py={2} pl={4} pr={0}>
            <Box sx={{ display: "flex" }}>
              <AppBar
                position="fixed"
                sx={{
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                color="white"
              >
                <ModalHeader />
              </AppBar>
              <Box
                component="nav"
                sx={{
                  width: { sm: drawerWidth },
                  flexShrink: { sm: 0 },
                }}
              >
                <Drawer
                  container={container}
                  variant="persistent"
                  open={mobileOpen.state}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },

                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                      backgroundColor: "#F6F6F6",
                    },
                  }}
                >
                  {drawer}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "#F6F6F6",
                    },
                  }}
                  open
                >
                  {drawer}
                </Drawer>
              </Box>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                  overflow: "auto",
                  height: "60vh",
                  pt: { xs: 5, sm: 0 },
                }}
              >
                <Toolbar />
                <RatingDetails />
              </Box>
            </Box>
          </Grid>
          <ModalFooter />
        </Stack>
      </Box>
    </Modal>
  );
}

export default NotaryRatingDetailModal;
