import React from "react";
import { Box, Modal, Button, Typography, Stack } from "@mui/material/";
import { smallModalStyle } from "components/common/Styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

function ConfirmationModal(props) {
  const {
    open,
    onConfirm,
    type,
    icon,
    heading,
    message,
    approveLabel,
    cancelLabel,
  } = props;

  return (
    <Modal open={open.state} onClose={() => open.update(false)}>
      <Box sx={smallModalStyle} py={3}>
        <Stack spacing={5} justifyContent="center" alignItems="center">
          <Stack justifyContent="center" alignItems="center">
            {icon === "error" && (
              <CancelOutlinedIcon color="error" style={{ fontSize: "100px" }} />
            )}
            {icon === "success" && (
              <CheckCircleOutlineOutlinedIcon
                color="success"
                style={{ fontSize: "100px" }}
              />
            )}
            {icon === "warning" && (
              <ErrorOutlineOutlinedIcon
                color="warning"
                style={{ fontSize: "100px" }}
              />
            )}
            <Typography variant="font25" color="#777" noWrap>
              {heading}
            </Typography>
            <Typography variant="font15" color="#777" align="center">
              {message}
            </Typography>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Button
              variant="contained"
              size="small"
              onClick={() => onConfirm(type)}
            >
              <Typography variant="font15">{approveLabel}</Typography>
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={(event) => {
                open.update(false);
              }}
            >
              <Typography variant="font15">{cancelLabel}</Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ConfirmationModal;
