export function isEmptyString(data) {
  data = data.toString();
  if (data.trim().length) {
    return false;
  } else {
    return true;
  }
}

export function isValidPhoneNumber(phoneNumber) {
  if (phoneNumber.length !== 16) {
    return false;
  }
  return true;
}

export function isValidPassword(password) {
  if (password.length < 8) {
    return false;
  }
  return true;
}

export function isValidEmail(email) {
  if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
    return true;
  }
  return false;
}

export function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
