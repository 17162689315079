import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "service/AuthProvider";
import { shouldChangePassword } from "./GetUserDetail";

function ProtectedRoute({ children }) {
  let location = useLocation();

  const isAuthenticated = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (
    shouldChangePassword() &&
    location.pathname !== "/change-password"
  ) {
    return <Navigate to="/change-password" state={{ from: location }} />;
  }

  return children;
}

export default ProtectedRoute;
