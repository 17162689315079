import React, { useEffect } from "react";

import { Typography, Box, Grid, Stack } from "@mui/material";

import AdminUsersSummary from "./sections/AdminUsersSummary";
import AdminUsersTable from "./sections/AdminUsersTable";
import { useIsAdmin } from "components/common/GetUserDetail";
import { useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

function AdminManageUsers() {
  let navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const showAdminUsers = useStateUpdate("showall");
  const heading = useStateUpdate("Users");

  useEffect(() => {
    !isAdmin && navigate("/dashboard");
  }, []);

  return isAdmin ? (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Manage Users</Typography>
      </Grid>
      <Stack spacing={2}>
        <AdminUsersSummary
          changeHeading={heading.update}
          showAdminUsers={showAdminUsers}
        />
        <AdminUsersTable
          heading={heading.state}
          changeHeading={heading.update}
          showAdminUsers={showAdminUsers}
        />
      </Stack>
    </Box>
  ) : (
    <div />
  );
}

export default AdminManageUsers;
