import React from "react";
import { useNavigate } from "react-router-dom";
//materialUi
import { Button, Container, Stack, Typography, Box, Grid } from "@mui/material";
//Services

function AccountCreated() {
  const navigate = useNavigate();
  return (
    <Container maxWidth="md">
      <Box
        mt={{ xs: 10, sm: 6, md: 5 }}
        p={5}
        sx={{ backgroundColor: "white" }}
      >
        <Grid item xs={11} mt={4}>
          <Stack spacing={2}>
            <Typography variant="font20b">Done. All set!</Typography>
            <Typography variant="body2" color="#686868">
              Your account has been successfully created.<br></br>
              <br></br>We need few more details about you before proceeding with
              notary signing services. <br></br>Please visit your profile page
              and fill in your information
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={8} my={6}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Button
              variant="contained"
              onClick={() => navigate("/login", { replace: true })}
            >
              <Typography variant="buttonLabel" mx={5}>
                Update Profile
              </Typography>
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/", { replace: true })}
            >
              <Typography variant="buttonLabel" mx={5}>
                I will update later
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Box>
    </Container>
  );
}

export default AccountCreated;
