import React, { useEffect } from "react";
import {
  Paper,
  Box,
  Grid,
  CircularProgress,
  Typography,
  Stack,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Toast from "components/common/Toast";
import { useStateUpdate } from "UseStateUpdate";
import { isEmptyString } from "components/common/FormValidator";
import { createNoteTemplates } from "service/TemplatesService";
import { updateNoteTemplates } from "service/TemplatesService";

function AddNoteToTemplate(props) {
  const {
    templates,
    editTemplate,
    noteTemplate,
    fetchNoteTemplates,
    expanded,
  } = props;
  const templateId = useStateUpdate(0);
  const title = useStateUpdate("");
  const noteBody = useStateUpdate("");
  const isLoader = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  useEffect(() => {
    templates.length > 0 &&
      !editTemplate.state &&
      templateId.update(templates[0].templateId);
  }, [templates]);

  useEffect(() => {
    templateId.update(editTemplate.state ? noteTemplate.templateId : 0);
    title.update(editTemplate.state ? noteTemplate.title : "");
    noteBody.update(editTemplate.state ? noteTemplate.body : "");
  }, [editTemplate.state, noteTemplate]);

  const callEditOrCreateNoteTemplates = async () => {
    isLoader.update(true);
    let data = {
      title: title.state,
      body: noteBody.state,
    };
    if (editTemplate.state) {
      var response = await updateNoteTemplates(
        templateId.state,
        noteTemplate.noteTemplateId,
        data
      );
      if (response.status === 200) {
        if (response.data.code === 200) {
          isLoader.update(false);
          title.update("");
          noteBody.update("");
          fetchNoteTemplates(templateId.state);
          calltoast("success", response.data.message);
          editTemplate.update(false);
        } else {
          calltoast("error", response.data.message);
          isLoader.update(false);
        }
      } else {
        calltoast("error", response.data.message);
        isLoader.update(false);
      }
    } else {
      var response = await createNoteTemplates(templateId.state, data);
      if (response.status === 200) {
        if (response.data.code) {
          isLoader.update(false);
          title.update("");
          noteBody.update("");
          expanded.update(templateId.state);
          fetchNoteTemplates(templateId.state);
          calltoast("success", response.data.message);
        } else {
          calltoast("error", response.data.message);
          isLoader.update(false);
        }
      } else {
        calltoast("error", response.data.message);
        isLoader.update(false);
      }
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }} p={2}>
        <Grid>
          <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
              <Typography variant="font15b" color="#686868">
                {editTemplate.state ? "Edit Note" : "Add Note"}
              </Typography>
              {editTemplate.state && (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    editTemplate.update(false);
                  }}
                >
                  <Typography variant="font12b">Add Note</Typography>
                </Button>
              )}
            </Stack>
            <FormControl fullWidth variant="standard" color="secondary">
              <InputLabel>
                <Typography variant="font12">Save to</Typography>
              </InputLabel>
              <Select
                disabled={editTemplate.state}
                label={<Typography variant="font12">Save to</Typography>}
                value={templateId.state}
                onChange={(e) => {
                  templateId.update(e.target.value);
                }}
              >
                {templates.map((template) => (
                  <MenuItem
                    key={template.templateId}
                    value={template.templateId}
                  >
                    <Typography variant="font12">
                      {template.templateName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={<Typography variant="font12">Title</Typography>}
              variant="standard"
              color="secondary"
              value={title.state}
              inputProps={{ maxLength: 35 }}
              onChange={(e) => {
                title.update(e.target.value);
              }}
            />
            <TextField
              fullWidth
              color="secondary"
              placeholder="Write your note here..."
              label={<Typography variant="font12">Note body</Typography>}
              multiline
              rows={2}
              value={noteBody.state}
              onChange={(e) => {
                noteBody.update(e.target.value);
              }}
            />
          </Stack>
        </Grid>
        <Grid
          container
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems="center"
          pt={4}
        >
          <Button
            disabled={
              isLoader.state ||
              isEmptyString(title.state) ||
              isEmptyString(noteBody.state)
            }
            variant="contained"
            onClick={(event) => {
              callEditOrCreateNoteTemplates();
            }}
          >
            <Typography variant="font14b">
              {editTemplate.state ? "Edit template" : "Save to template"}
            </Typography>
            {isLoader.state && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  marginLeft: "-10px",
                }}
              />
            )}
          </Button>
        </Grid>
      </Box>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Paper>
  );
}

export default AddNoteToTemplate;
