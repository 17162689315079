import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Stack,
  Button,
  TextField,
  Tab,
  Tabs,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { getOrder } from "service/OrderService";
import { useStateUpdate } from "UseStateUpdate";
import OrderModal from "components/orderDetails/sections/OrderModal";
import { useIsNotary } from "components/common/GetUserDetail";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { completeOrder } from "service/OrderService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function OrderCompletion() {
  let navigate = useNavigate();
  const search = useLocation().search;
  const profileId = new URLSearchParams(search).get("profileId");
  const isNotary = useIsNotary();
  const { orderId } = useParams();
  const tabValue = useStateUpdate(0);
  const orderDetails = useStateUpdate({});
  const openModal = useStateUpdate(false);
  const canCompleteOrder = useStateUpdate(false);
  const orderCompleted = useStateUpdate(false);
  const isClosed = useStateUpdate(false);

  useEffect(() => {
    profileId ? fetchOrderDetails() : navigate("/orders/" + orderId);
  }, []);

  useEffect(() => {
    isNotEmpty(orderDetails.state) &&
      !orderDetails.state.orderNumber &&
      navigate("/orders/" + orderId);
  }, [orderDetails.state]);

  const handleOrderComplete = async () => {
    const body = { userId: profileId };
    var result = await completeOrder(orderId, body);
    result.status === 200 && orderCompleted.update(true);
  };

  const fetchOrderDetails = async () => {
    var result = await getOrder(orderId);
    if (result.status === 200) {
      orderDetails.update(result.data.data);
      canCompleteOrder.update(result.data.data.canCompleteOrder);
      isClosed.update(result.data.data.status.toLowerCase() === "closed");
    } else {
      orderDetails.update(result.data);
    }
  };

  const handleChange = (event, newValue) => {
    tabValue.update(newValue);
  };

  const Instructions = () => {
    return (
      <Stack spacing={1}>
        <Typography variant="font13b">
          Please use this page to complete your signing and thank you for being
          a {orderDetails.state.signingAgencyName} Signing Services
          representative.
        </Typography>
      </Stack>
    );
  };

  const UploadImagesTabPanel = () => {
    return (
      <TabPanel value={tabValue.state} index={0}>
        <Box sx={{ p: 3 }}>
          <Grid container my={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <Typography variant="font13b">
                  Indentification file(s)*
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!canCompleteOrder.state}
                      defaultChecked
                    />
                  }
                  label={
                    <Typography variant="font13">
                      Click here if the ID(s) copies are attached to the
                      documents
                    </Typography>
                  }
                />
              </Stack>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              item
              xs={12}
              sm={6}
            >
              <Button
                disabled={!canCompleteOrder.state}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  openModal.update(true);
                }}
              >
                <Typography variant="font13b" color="#000" mx={4}>
                  Upload
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Grid container my={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="font13b">Scanback File(s)</Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              item
              xs={12}
              sm={6}
            >
              <Button
                disabled={!canCompleteOrder.state}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  openModal.update(true);
                }}
              >
                <Typography variant="font13b" color="#000" mx={4}>
                  Upload
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Grid container my={2}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              item
              xs={12}
            >
              <Button
                disabled={!canCompleteOrder.state}
                variant="contained"
                size="small"
                onClick={(event) => {}}
              >
                <Typography variant="font13b" color="#000" mx={5}>
                  Proceed
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    );
  };

  const DocumentDeliveryTabPanel = () => {
    return (
      <TabPanel value={tabValue.state} index={1}>
        <Box sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Stack spacing={1} sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Typography variant="font15b">Completion option</Typography>
              <FormControl size="small" variant="standard" color="secondary">
                <InputLabel>
                  <Typography variant="font12">
                    Select completion option
                  </Typography>
                </InputLabel>
                <Select
                  disabled={!canCompleteOrder.state}
                  size="small"
                  label={
                    <Typography variant="font12">
                      Select completion option
                    </Typography>
                  }
                  // value={}
                  onChange={(e) => {}}
                >
                  {[
                    "Document dropped at local",
                    "Documents were hand over",
                  ].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Grid container my={2}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              item
              xs={12}
            >
              <Button
                disabled={!canCompleteOrder.state}
                variant="contained"
                size="small"
                onClick={(event) => {}}
              >
                <Typography variant="font13b" color="#000" mx={5}>
                  Proceed
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    );
  };

  const NotaryNotesTabPanel = () => {
    return (
      <TabPanel value={tabValue.state} index={2}>
        <Box p={3} pb={5}>
          <Stack spacing={5}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Stack spacing={1} sx={{ width: { xs: "100%", sm: "50%" } }}>
                <Typography variant="font15b">Note</Typography>

                <TextField
                  disabled={!canCompleteOrder.state}
                  fullWidth
                  color="secondary"
                  label={<Typography variant="font12">Enter note</Typography>}
                  multiline
                  rows={2}
                />
              </Stack>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              xs={12}
            >
              <Button
                disabled={!canCompleteOrder.state}
                variant="contained"
                size="small"
                onClick={() => handleOrderComplete()}
              >
                <Typography variant="font13b" color="#000" mx={3}>
                  Complete order
                </Typography>
              </Button>
            </Grid>
          </Stack>
        </Box>
      </TabPanel>
    );
  };

  return (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <OrderModal open={openModal} type="addDocuments" />
      <Grid container mb={2}>
        {orderDetails.state.orderNumber && (
          <Typography variant="font20b">
            {"Complete Order #: " + orderDetails.state.orderNumber}
          </Typography>
        )}
      </Grid>

      {canCompleteOrder.state &&
      !isClosed.state &&
      !orderCompleted.state &&
      isNotary ? (
        <Fragment>
          <Grid container py={3}>
            <Instructions />
          </Grid>
          <Grid container>
            <Paper variant="outlined" sx={{ width: "100%" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue.state}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    onChange={handleChange}
                  >
                    {[
                      "1. Upload Images",
                      "2. Document delivery",
                      "3. Notary notes",
                    ].map((label, index) => (
                      <Tab
                        key={index}
                        label={
                          <Typography variant="font13b">{label}</Typography>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
                <UploadImagesTabPanel />
                <DocumentDeliveryTabPanel />
                <NotaryNotesTabPanel />
              </Box>
            </Paper>
          </Grid>
        </Fragment>
      ) : (
        isNotEmpty(orderDetails.state) && (
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Alert
              severity={
                isClosed.state
                  ? "warning"
                  : orderCompleted.state
                  ? "success"
                  : "error"
              }
            >
              {isClosed.state
                ? "This order is already completed."
                : orderCompleted.state
                ? "Successfully completed order"
                : "Cant complete this order now!"}
            </Alert>
            <Button
              variant="text"
              color="secondary"
              onClick={() => navigate("/orders/" + orderId)}
            >
              Back to Order page
            </Button>
          </Stack>
        )
      )}
    </Box>
  );
}

export default OrderCompletion;
