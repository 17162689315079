import React, { useEffect } from "react";

import { Typography, Paper, Grid, Stack, Button, Divider } from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import CustomSwitch from "components/profile/common_componets/CustomSwitch";

function IndividualNotificationsPreference(props) {
  const { individualPreferences, changeData } = props;
  const isAllEmailChecked = useStateUpdate(false);
  const isAllSmsChecked = useStateUpdate(false);
  const preferences = useStateUpdate([]);

  useEffect(() => {
    individualPreferences.length > 0 &&
      preferences.update(individualPreferences);
  }, [individualPreferences]);

  useEffect(() => {
    if (preferences.state.length > 0) {
      checkAllSmsPreferences();
      checkAllEmailPreferences();
    }
  }, [preferences.state]);

  const handleChange = (isChecked, id, checkBoxIs, isCheckAll) => {
    let individual = [];
    preferences.state.forEach((data) => {
      let newData = data;
      if (data.notificationId === id || isCheckAll) {
        newData = {
          ...data,
          emailEnabled:
            checkBoxIs === "email"
              ? isChecked
                ? "Y"
                : "N"
              : data.emailEnabled,
          smsEnabled:
            checkBoxIs === "sms" ? (isChecked ? "Y" : "N") : data.smsEnabled,
        };
      }
      individual.push(newData);
    });
    preferences.update(individual);
  };

  const checkAllSmsPreferences = () => {
    var isCheck = true;
    preferences.state.forEach((data) => {
      data.smsEnabled === "N" && (isCheck = false);
    });
    isAllSmsChecked.update(isCheck);
  };
  const checkAllEmailPreferences = () => {
    var isCheck = true;
    preferences.state.forEach((data) => {
      data.emailEnabled === "N" && (isCheck = false);
    });
    isAllEmailChecked.update(isCheck);
  };

  function preferenceColumns(data, index) {
    return (
      <div key={index}>
        <Grid container>
          <Grid item xs={8}>
            <Stack>
              <Typography variant="font10b" color="#787878">
                {data.title}
              </Typography>
              <Typography variant="font10" color="#787878" width="80%">
                {data.description}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={2}
          >
            <CustomSwitch
              valueIs={data.smsEnabled === "N" ? false : true}
              onChange={handleChange}
              id={data.notificationId}
              checkBoxIs="sms"
              isCheckAll={false}
            />
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={2}
          >
            <CustomSwitch
              valueIs={data.emailEnabled === "N" ? false : true}
              onChange={handleChange}
              id={data.notificationId}
              checkBoxIs="email"
              isCheckAll={false}
            />
          </Grid>
        </Grid>
        <Divider />
      </div>
    );
  }

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Stack spacing={1} p={2}>
        <Typography variant="font15b" color=" #686868">
          Individual Notifications Preference
        </Typography>
        <Grid container item pt={3}>
          <Grid
            item
            xs={8}
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="font12b">Notification</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={2}
          >
            <Typography variant="font12b">SMS</Typography>
            <CustomSwitch
              valueIs={isAllSmsChecked.state}
              onChange={handleChange}
              id={null}
              checkBoxIs="sms"
              isCheckAll={true}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={2}
          >
            <Typography variant="font12b">Email</Typography>
            <CustomSwitch
              valueIs={isAllEmailChecked.state}
              onChange={handleChange}
              id={null}
              checkBoxIs="email"
              isCheckAll={true}
            />
          </Grid>
        </Grid>
        <Divider />
        {preferences.state.map((data, index) => preferenceColumns(data, index))}
        <Grid
          item
          py={2}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="small"
            disabled={individualPreferences.length === 0}
            onClick={(e) => {
              changeData(preferences.state, "individual");
            }}
          >
            <Typography variant="buttonLabel" mx={1}>
              Save preferences
            </Typography>
          </Button>
        </Grid>
      </Stack>
    </Paper>
  );
}

export default IndividualNotificationsPreference;
