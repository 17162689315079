import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import Toast from "components/common/Toast";
import { useStateUpdate } from "UseStateUpdate";
import GeneralNotificationPreferences from "./sections/GeneralNotificationPreferences";
import IndividualNotificationsPreference from "./sections/IndividualNotificationsPreference";
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "service/ProfileService";
import { getProfileId } from "components/common/GetUserDetail";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { useNavigate } from "react-router-dom";
import { useIsNotary } from "components/common/GetUserDetail";

function NotificationsPreferences() {
  const navigate = useNavigate();
  const isNotary = useIsNotary();
  const notificationPreferences = useStateUpdate({});
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  useEffect(() => {
    !isNotary && navigate("/dashboard");
    fetchNotificationPreferences();
  }, []);

  const fetchNotificationPreferences = async () => {
    var result = await getNotificationPreferences(getProfileId());
    if (result.status === 200) {
      var datas = result.data.data;
      notificationPreferences.update(datas);
    }
  };

  const changeNotificationPreferences = async (dataIs, preferenceType) => {
    let data;
    preferenceType === "individual"
      ? (data = { ...notificationPreferences.state, individual: dataIs })
      : (data = { ...notificationPreferences.state, general: dataIs });
    var response = await updateNotificationPreferences(getProfileId(), data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        fetchNotificationPreferences();
        calltoast("success", response.data.message);
      } else {
        fetchNotificationPreferences();
        calltoast("error", response.data.message);
      }
    } else {
      fetchNotificationPreferences();
      calltoast("error", response.data.message);
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Box mx={{ xs: 2, sm: 3 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Notifications Preferences</Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <IndividualNotificationsPreference
            individualPreferences={
              isNotEmpty(notificationPreferences.state)
                ? notificationPreferences.state.individual
                : []
            }
            changeData={changeNotificationPreferences}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GeneralNotificationPreferences
            generalPreferences={
              isNotEmpty(notificationPreferences.state)
                ? notificationPreferences.state.general
                : {}
            }
            changeData={changeNotificationPreferences}
          />
        </Grid>
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  );
}

export default NotificationsPreferences;
