import React, { useState, useCallback, useEffect, useContext } from "react";
import Cropper from "react-easy-crop";
import {
  Typography,
  Input,
  Button,
  Slider,
  Modal,
  Box,
  Grid,
  Stack,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import getCroppedImg from "./cropImage";
import { uploadFile } from "service/DocumentService";
import { updateProfile } from "service/ProfileService";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { smallModalStyle } from "components/common/Styles";
import { ProfileContext } from "AppContext";

const CropImageDemo = ({ classes, open, calltoast, checkPercentage }) => {
  const profileValue = useContext(ProfileContext);
  const { profileDatas, fetchProfileData } = profileValue;
  const [imageToCrop, setImageToCrop] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSource, setImageSource] = useState("?not-from-cache-please");

  useEffect(() => {
    setImageToCrop(profileDatas.photoUrl ?? "");
  }, [profileDatas]);

  useEffect(() => {
    croppedImage !== null && uploadDocument(croppedImage);
  }, [croppedImage]);

  useEffect(() => {
    setZoom(1);
  }, [imageToCrop]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleZoom = (step) => {
    ((step === -0.1 && zoom > 1) || (step === 0.1 && zoom < 3)) &&
      setZoom(zoom + step);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSource,
        imageToCrop,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  async function uploadDocument(fileIs) {
    var response = await uploadFile(profileDatas.profileId, fileIs, "images");
    if (response.status === 200) {
      if (response.data.code === 200) {
        let data = {
          photoUrl: response.data.data.path,
          profileCompletionPercentage: checkPercentage(),
        };
        response = await updateProfile(profileDatas.profileId, data);
        open.update(false);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else {
        calltoast("error", response.data.message);
      }
    } else {
      calltoast("error", response.data.message);
    }
    setCroppedImage(null);
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSource("");
      setImageToCrop(imageDataUrl);
    }
  };

  return (
    <Modal open={open.state} onClose={() => open.update(false)}>
      <Box sx={smallModalStyle}>
        <Grid item xs={12} container justifyContent="flex-end">
          <IconButton
            onClick={(e) => {
              open.update(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <div>
          {imageToCrop !== "" && (
            <div className={classes.cropContainer}>
              <Cropper
                image={imageToCrop}
                crop={crop}
                cropShape="round"
                rotation={rotation}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}

          <div className={classes.controls}>
            <Grid container>
              {imageToCrop !== "" && (
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => handleZoom(-0.1)}>
                      <RemoveIcon />
                    </IconButton>
                    <Slider
                      color="secondary"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                    <IconButton onClick={() => handleZoom(+0.1)}>
                      <AddIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} container justifyContent="center">
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 1, md: 3 }}
                >
                  <label htmlFor="upload-photo">
                    <Input
                      inputProps={{ accept: "image/*" }}
                      id="upload-photo"
                      type="file"
                      onChange={onFileChange}
                      sx={{ display: "none" }}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      color="secondary"
                      size="small"
                    >
                      <Typography variant="buttonLabel">
                        Choose Photo
                      </Typography>
                    </Button>
                  </label>
                  <Button
                    disabled={imageToCrop === ""}
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <Typography variant="buttonLabel">
                      {"Crop & Upload"}
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(CropImageDemo);
