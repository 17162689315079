import React from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  Grid,
  styled,
  CircularProgress,
  Alert,
} from "@mui/material";

import { postRegisterData } from "service/RegistrationService";

import {
  isEmptyString,
  isValidEmail,
  isValidPhoneNumber,
} from "components/common/FormValidator";
import { formatPhoneNumber } from "components/common/FormatPhoneNumber";
import { appendCountryCode } from "components/common/FormatPhoneNumber";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));

function Register(props) {
  const { setRegistrationId, setRegisterData, setIsRegister, verifyError } =
    props;
  const firstName = useStateUpdate("");
  const middleName = useStateUpdate("");
  const lastName = useStateUpdate("");
  const email = useStateUpdate("");
  const phoneNumber = useStateUpdate("");
  const isLoader = useStateUpdate(false);

  const validationError = useStateUpdate({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    };
    if (isEmptyString(firstName.state)) {
      newValidationError = {
        ...newValidationError,
        firstName: "First Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(lastName.state)) {
      newValidationError = {
        ...newValidationError,
        lastName: "Last Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is required",
      };
      isValidated = false;
    } else if (!isValidEmail(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is not valid.",
      };
      isValidated = false;
    }
    if (!isValidPhoneNumber(phoneNumber.state)) {
      newValidationError = {
        ...newValidationError,
        phoneNumber: "Phone Number is not valid",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  function getRegisterFormData() {
    var registerData = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      firstName: firstName.state.trim(),
      middleName: middleName.state.trim(),
      lastName: lastName.state.trim(),
      email: email.state.trim(),
      primaryNumber: appendCountryCode(phoneNumber.state.replace(/\D/g, "")),
    };
    return registerData;
  }

  async function callRegister(e) {
    e.preventDefault();
    isLoader.update(true);
    let registerData = getRegisterFormData();

    var response = await postRegisterData(registerData);
    if (response.status === 200) {
      if (response.data.code === 200) {
        setRegistrationId(response.data.data.registrationId);
        setRegisterData(registerData);
        setIsRegister(true);
      } else if (response.data.code === 422) {
        if (response.data.message === "Validation Error") {
          validationError.update({
            ...validationError.state,
            email: response.data.error.email[0],
          });
        } else {
        }
      }
    }
    isLoader.update(false);
  }

  return (
    <Container maxWidth="md">
      <Box
        mt={{ xs: 10, sm: 6, md: 5 }}
        p={4}
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} sm={12} md={12} mb={2}>
          <Item elevation={0}>
            <Typography variant="body2" color="#686868" sx={{ mb: 1, mt: 1 }}>
              Welcome to simplified online notary signing
            </Typography>
            <Typography variant="font20b" sx={{ mb: 5 }}>
              Create an account
            </Typography>
          </Item>
        </Grid>
        {verifyError.state !== "" && (
          <Grid container item xs={12}>
            <Alert
              severity="error"
              onClose={() => {
                verifyError.update("");
              }}
              style={{ width: "100%" }}
            >
              {verifyError.state}
            </Alert>
          </Grid>
        )}

        <Grid container item xs={12}>
          <Grid item xs={12} sm={10} md={6}>
            <Item elevation={0}>
              <TextField
                required
                error={validationError.state.firstName === "" ? false : true}
                helperText={validationError.state.firstName}
                inputProps={{ maxLength: 35 }}
                id="register-first-name"
                label="First Name"
                variant="standard"
                color="secondary"
                fullWidth
                value={firstName.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    firstName: "",
                  });
                  firstName.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      firstName: "First Name is required",
                    });
                  }
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Item elevation={0}>
              <TextField
                id="register-middle-name"
                label="Middle Name"
                variant="standard"
                color="secondary"
                fullWidth
                value={middleName.state}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    middleName: "",
                  });
                  middleName.update(e.target.value);
                }}
              />
            </Item>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={10} md={6}>
            <Item elevation={0}>
              <TextField
                required
                error={validationError.state.lastName === "" ? false : true}
                helperText={validationError.state.lastName}
                inputProps={{ maxLength: 35 }}
                id="register-last-name"
                label="Last Name"
                variant="standard"
                color="secondary"
                fullWidth
                value={lastName.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    lastName: "",
                  });
                  lastName.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      lastName: "Last Name is required",
                    });
                  }
                }}
              />
            </Item>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={10} md={6}>
            <Item elevation={0}>
              <TextField
                required
                error={validationError.state.email === "" ? false : true}
                inputProps={{ maxLength: 35 }}
                helperText={
                  validationError.state.email === ""
                    ? "Email id will be your user id for signing in. "
                    : validationError.state.email
                }
                type="email"
                id="register-email"
                label="Email"
                variant="standard"
                color="secondary"
                fullWidth
                value={email.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    email: "",
                  });
                  email.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      email: "Email is required",
                    });
                  } else if (!isValidEmail(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      email: "Email is not valid.",
                    });
                  }
                }}
              />
            </Item>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={10} md={6}>
            <Item elevation={0}>
              <TextField
                required
                error={validationError.state.phoneNumber === "" ? false : true}
                helperText={validationError.state.phoneNumber}
                id="register-phone-number"
                label="Phone number"
                variant="standard"
                color="secondary"
                fullWidth
                value={phoneNumber.state}
                inputProps={{ maxLength: 16 }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    phoneNumber: "",
                  });
                  phoneNumber.update(formatPhoneNumber(e.target.value));
                }}
                onBlur={(e) => {
                  !isValidPhoneNumber(e.target.value) &&
                    validationError.update({
                      ...validationError.state,
                      phoneNumber: "Phone Number is not valid",
                    });
                }}
              />
            </Item>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Stack direction="row" spacing={2}>
            <Item elevation={0}>
              <Button
                variant="contained"
                disabled={isLoader.state}
                onClick={(e) => {
                  checkValidatorOnSubmit() && callRegister(e);
                }}
              >
                <Typography variant="buttonLabel" mx={4}>
                  Register
                </Typography>
                {isLoader.state && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      marginLeft: "-10px",
                    }}
                  />
                )}
              </Button>
            </Item>
            <Item elevation={0}>
              <Typography variant="font12">
                {"Already registered? "}
                <b>
                  <Link href="/login" color="#000000" underline="none">
                    Log in here
                  </Link>
                </b>
              </Typography>
            </Item>
          </Stack>
        </Grid>
      </Box>
    </Container>
  );
}

export default Register;
