import React, { Fragment } from "react";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Paper,
  Button,
  Divider,
  TextField,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { isValidEmail } from "components/common/FormValidator";
import { addNotary } from "service/OrganizationService";
import Toast from "components/common/Toast";
import { approveInvitation } from "service/OrganizationService";
import { removeNotary } from "service/OrganizationService";
import { makeNotaryAdmin } from "service/OrganizationService";
import { EditOutlined } from "@material-ui/icons";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { iconButton } from "components/common/Styles";
import { updateOrganizationName } from "service/OrganizationService";
import { removeNotaryAdmin } from "service/OrganizationService";
import { leaveOrganization } from "service/OrganizationService";
import { useIsAdmin } from "components/common/GetUserDetail";
import { rejectInvitation } from "service/OrganizationService";
import ConfirmationModal from "components/common/ConfirmationModal";

function OwnedOrganization(props) {
  const {
    organization,
    profileId,
    fetchOrganizationMembersList,
    fetchOrganizationList,
  } = props;
  const isAdmin = useIsAdmin();
  const addNewNotary = useStateUpdate(false);
  const email = useStateUpdate("");
  const emailError = useStateUpdate("");
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const showInviteAlert = useStateUpdate(false);
  const token = useStateUpdate("");
  const editOrganizationName = useStateUpdate(false);
  const organizationName = useStateUpdate("");
  const sendRequestButtonLoader = useStateUpdate(false);
  const idForConfirmModal = useStateUpdate(null);

  const modalIcon = useStateUpdate(null);
  const modalHeading = useStateUpdate("");
  const modalMessage = useStateUpdate("");
  const modalApprove = useStateUpdate("Remove");
  const modalCancel = useStateUpdate("Cancel");
  const openModal = useStateUpdate(false);
  const modalType = useStateUpdate(null);

  function checkValidatorOnSubmit() {
    !isValidEmail(email.state) && emailError.update("Email is not valid.");
    return isValidEmail(email.state);
  }

  const callAddNotary = async () => {
    sendRequestButtonLoader.update(true);
    var response = await addNotary(email.state, organization.organizationId);
    if (response.status === 200) {
      if (response.data.code === 200) {
        token.update(response.data.data.token);
        showInviteAlert.update(true);
        addNewNotary.update(false);
        emailError.update("");
        calltoast("success", response.data.message);
      } else {
        calltoast("error", response.data.message);
      }
    } else {
      calltoast("error", response.data.message);
    }
    sendRequestButtonLoader.update(false);
  };

  const callApproveInvitation = async (profileIdIs) => {
    var response = await approveInvitation(
      organization.organizationId,
      profileIdIs
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
  };

  const callRejectInvitation = async () => {
    var response = await rejectInvitation(
      organization.organizationId,
      idForConfirmModal.state
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
    openModal.update(false);
  };

  const callRemoveNotary = async () => {
    var response = await removeNotary(
      organization.organizationId,
      idForConfirmModal.state
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
    openModal.update(false);
  };

  const callMakeNotaryAdmin = async () => {
    var response = await makeNotaryAdmin(
      organization.organizationId,
      idForConfirmModal.state
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
    openModal.update(false);
  };

  const callUpdateOrganizationName = async () => {
    var body = {
      name: organizationName.state,
    };
    var response = await updateOrganizationName(
      organization.organizationId,
      body
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    editOrganizationName.update(false);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
  };

  const callRemoveNotaryAdmin = async () => {
    var response = await removeNotaryAdmin(
      organization.organizationId,
      idForConfirmModal.state
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
    idForConfirmModal.update(null);
    openModal.update(false);
  };

  const callConfirm = async (type) => {
    switch (type) {
      case "RemoveAdmin":
        callRemoveNotaryAdmin();
        break;
      case "RejectRequest":
        callRejectInvitation();
        break;
      case "RemoveNotary":
        callRemoveNotary();
        break;
      case "MakeAdmin":
        callMakeNotaryAdmin();
        break;
      case "LeaveOrganization":
        callLeaveOrganization();
        break;
      default:
        break;
    }
  };

  const callLeaveOrganization = async () => {
    var response = await leaveOrganization(
      organization.organizationId,
      profileId
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
    fetchOrganizationList && fetchOrganizationList();
    openModal.update(false);
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Paper variant="outlined">
      <ConfirmationModal
        open={openModal}
        type={modalType.state}
        onConfirm={callConfirm}
        icon={modalIcon.state}
        heading={modalHeading.state}
        message={modalMessage.state}
        approveLabel={modalApprove.state}
        cancelLabel={modalCancel.state}
      />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            {editOrganizationName.state ? (
              <Stack
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <TextField
                  fullWidth
                  size="small"
                  color="secondary"
                  value={organizationName.state}
                  inputProps={{ maxLength: 35 }}
                  onChange={(e) => {
                    organizationName.update(e.target.value);
                  }}
                />
                <IconButton
                  disabled={organizationName.state === ""}
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    callUpdateOrganizationName();
                  }}
                  sx={iconButton.border}
                >
                  <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={(e) => {
                    editOrganizationName.update(false);
                  }}
                  sx={iconButton.border}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            ) : (
              <Typography variant="font20b">
                {organization.organization + " Organization"}
                <IconButton
                  size="small"
                  onClick={() => {
                    organizationName.update(organization.organization);
                    editOrganizationName.update(true);
                  }}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
              </Typography>
            )}
          </Grid>
          <Grid
            container
            item
            sm={8}
            justifyContent={{ xs: "center", sm: "flex-end" }}
          >
            <Stack spacing={2}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={{ xs: "center", sm: "flex-end" }}
              >
                {!isAdmin && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      idForConfirmModal.update();
                      openModal.update(true);
                      modalIcon.update("warning");
                      modalHeading.update("Leave Organization");
                      modalMessage.update(
                        "Are you sure to leave " +
                          organization.organization.toUpperCase() +
                          " Organization?"
                      );
                      modalApprove.update("Leave");
                      modalCancel.update("Cancel");
                      modalType.update("LeaveOrganization");
                    }}
                  >
                    <Typography variant="buttonLabel" color="#000" noWrap>
                      Leave Organization
                    </Typography>
                  </Button>
                )}
                {!addNewNotary.state && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(event) => {
                      email.update("");
                      showInviteAlert.update(false);
                      addNewNotary.update(true);
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <AddCircleOutlineOutlinedIcon />
                      <Typography variant="buttonLabel">
                        Add New Notary
                      </Typography>
                    </Stack>
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid
            container
            item
            sm={12}
            justifyContent={{ xs: "center", sm: "flex-end" }}
          >
            {showInviteAlert.state && (
              <Alert
                onClose={() => {
                  email.update("");
                  showInviteAlert.update(false);
                }}
              >
                {email.state + " invited. Token is: " + token.state}
              </Alert>
            )}
          </Grid>
          {addNewNotary.state && (
            <Fragment>
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent={{ xs: "center", sm: "flex-start" }}
              >
                <Typography variant="font15">
                  You can use a permanent token or a one-time token to invite.
                </Typography>
                <Typography variant="font14">
                  For a one-time token, enter the email id and invite.
                </Typography>
                <Typography variant="font14">
                  {organization.organization + " permanent token is "}
                  <b>{organization.invitationCode}</b>
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent={{ xs: "center", sm: "flex-end" }}
                alignItems="center"
              >
                <form>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      error={emailError.state === "" ? false : true}
                      helperText={
                        emailError.state === "" ? "" : emailError.state
                      }
                      type="email"
                      id="notary-email"
                      size="small"
                      label="Email"
                      color="secondary"
                      value={email.state}
                      inputProps={{ maxLength: 35 }}
                      onChange={(e) => {
                        emailError.update("");
                        email.update(e.target.value);
                      }}
                      onBlur={(e) => {
                        !isValidEmail(e.target.value) &&
                          emailError.update("Email is not valid.");
                      }}
                    />
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={1}
                      alignItems="center"
                    >
                      <Button
                        disabled={
                          sendRequestButtonLoader.state || email.state === ""
                        }
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={(e) => {
                          checkValidatorOnSubmit() && callAddNotary();
                        }}
                      >
                        <Typography variant="font12b" color="#000">
                          Invite
                        </Typography>
                        {sendRequestButtonLoader.state && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              marginLeft: "-10px",
                            }}
                          />
                        )}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          addNewNotary.update(false);
                          email.update("");
                          emailError.update("");
                        }}
                      >
                        <Typography variant="font12b" color="#000">
                          Cancel
                        </Typography>
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Box>
      <Divider />
      {organization.members.map(
        (member, index) =>
          (member.acceptedAt !== null || member.roleIds.indexOf(2) > -1) && (
            <Fragment key={index}>
              <Grid container spacing={2} px={2} py={1}>
                <Grid container item xs={12} sm={3} alignItems="center">
                  <Typography variant="font14">{member.notaryName}</Typography>
                </Grid>
                <Grid container item xs={12} sm={3} alignItems="center">
                  {member.approvedAt === null &&
                  member.roleIds.indexOf(2) === -1 ? (
                    <Typography variant="font14" color="#FF7A00">
                      Request to join
                    </Typography>
                  ) : (
                    <Typography variant="font14">{member.roles}</Typography>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  justifyContent={{ xs: "center", sm: "flex-end" }}
                >
                  <Stack direction="row" spacing={{ xs: 1, sm: 2 }}>
                    {member.approvedAt === null ? (
                      member.roleIds.indexOf(2) === -1 && (
                        <Fragment>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() =>
                              callApproveInvitation(member.profileId)
                            }
                          >
                            <Typography variant="font12b" color="#000" noWrap>
                              Approve request
                            </Typography>
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() => {
                              idForConfirmModal.update(member.profileId);
                              openModal.update(true);
                              modalIcon.update("warning");
                              modalHeading.update("Reject request");
                              modalMessage.update(
                                "Are you sure to Reject request from " +
                                  organization.organization.toUpperCase() +
                                  " Organization?"
                              );
                              modalApprove.update("Reject");
                              modalCancel.update("Cancel");
                              modalType.update("RejectRequest");
                            }}
                          >
                            <Typography variant="font12b" color="#000" noWrap>
                              Reject request
                            </Typography>
                          </Button>
                        </Fragment>
                      )
                    ) : (
                      <Fragment>
                        {member.roleIds.indexOf(2) === -1 ? (
                          <Fragment>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                idForConfirmModal.update(member.profileId);
                                openModal.update(true);
                                modalIcon.update("warning");
                                modalHeading.update("Remove Notary");
                                modalMessage.update(
                                  "Are you sure to remove the notary " +
                                    member.notaryName.toUpperCase() +
                                    " from " +
                                    organization.organization.toUpperCase() +
                                    " Organization?"
                                );
                                modalApprove.update("Remove");
                                modalCancel.update("Cancel");
                                modalType.update("RemoveNotary");
                              }}
                            >
                              <Typography variant="font12b" color="#000" noWrap>
                                Remove
                              </Typography>
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                idForConfirmModal.update(member.profileId);
                                openModal.update(true);
                                modalIcon.update("warning");
                                modalHeading.update("Make Notary Admin");
                                modalMessage.update(
                                  "Are you sure  to make  " +
                                    member.notaryName.toUpperCase() +
                                    " as admin of " +
                                    organization.organization.toUpperCase() +
                                    " Organization?"
                                );
                                modalApprove.update("Make Notary Admin");
                                modalCancel.update("Cancel");
                                modalType.update("MakeAdmin");
                              }}
                            >
                              <Typography variant="font12b" color="#000" noWrap>
                                Make admin
                              </Typography>
                            </Button>
                          </Fragment>
                        ) : (
                          member.profileId !== profileId && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                idForConfirmModal.update(member.profileId);
                                openModal.update(true);
                                modalIcon.update("warning");
                                modalHeading.update("Remove Notary Admin");
                                modalMessage.update(
                                  "Are you sure to remove the admin role of " +
                                    member.notaryName.toUpperCase() +
                                    " from " +
                                    organization.organization.toUpperCase() +
                                    " Organization?"
                                );
                                modalApprove.update("Remove");
                                modalCancel.update("Cancel");
                                modalType.update("RemoveAdmin");
                              }}
                            >
                              <Typography variant="font12b" color="#000" noWrap>
                                Remove Notary Admin
                              </Typography>
                            </Button>
                          )
                        )}
                      </Fragment>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
            </Fragment>
          )
      )}
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Paper>
  );
}

export default OwnedOrganization;
