import React, { useEffect, useContext, Fragment } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";

import {
  Button,
  Typography,
  Grid,
  AccordionDetails,
  TextField,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  FormHelperText,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CustomMultipleSelect from "../common_componets/CustomMultipleSelect";
import PolarQuestions from "../common_componets/PolarQuestion";
import Address from "./sections/Address";

import {
  NUMBER_OF_SIGNING_CLOSED,
  DEFAULT_COUNTRY,
} from "components/common/Constant";

import { updateProfile } from "service/ProfileService";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { getConfigurationList } from "service/ConfigurationService";
import { getTimeZones } from "components/common/GetTimeZones";
import { formatPhoneNumber } from "components/common/FormatPhoneNumber";
import { appendCountryCode } from "components/common/FormatPhoneNumber";
import { isValidPhoneNumber } from "components/common/FormValidator";

function PersonalDetails({ isUser, checkPercentage }) {
  const profileValue = useContext(ProfileContext);
  const { profileDatas, fetchProfileData } = profileValue;
  const signingsClosed = NUMBER_OF_SIGNING_CLOSED;

  const isLoader = useStateUpdate(false);
  const address = useStateUpdate({
    id: "",
    addressType: "Office",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    county: "",
    zipCode: "",
  });
  const primaryNumber = useStateUpdate("");
  const officeNumber = useStateUpdate("");
  const afterHoursNumber = useStateUpdate("");
  const alreadyASigningAgent = useStateUpdate(false);
  const numberOfSigningsClosed = useStateUpdate(signingsClosed.below200);
  const industryExperience = useStateUpdate("");
  const hasLaserPrinterWithDualTrays = useStateUpdate(false);
  const spokenLanguages = useStateUpdate([]);
  const nameOnCheque = useStateUpdate("");
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const languages = useStateUpdate([]);
  const timeZone = useStateUpdate("");
  const isButtonDisabled = useStateUpdate(true);

  const validationError = useStateUpdate({
    primaryNumber: "",
    officeNumber: "",
    afterHoursNumber: "",
  });

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    !alreadyASigningAgent.state &&
      numberOfSigningsClosed.update(signingsClosed.below200);
  }, [alreadyASigningAgent.state, numberOfSigningsClosed]);

  useEffect(() => {
    if (isNotEmpty(profileDatas)) {
      if (profileDatas.addresses.length > 0) {
        const addressIs = profileDatas.addresses[0];
        address.update({
          id: addressIs.id ?? "",
          addressType:
            addressIs.addressType === null || addressIs.addressType === ""
              ? "Office"
              : addressIs.addressType.charAt(0).toUpperCase() +
                addressIs.addressType.slice(1),
          streetAddress1: addressIs.streetAddress1 ?? "",
          streetAddress2: addressIs.streetAddress2 ?? "",
          city: addressIs.city ?? "",
          state: addressIs.state ?? "",
          county: addressIs.county ?? "",
          zipCode: addressIs.zip ?? "",
        });
      }
      primaryNumber.update(formatPhoneNumber(profileDatas.primaryNumber ?? ""));
      officeNumber.update(formatPhoneNumber(profileDatas.officeNumber ?? ""));

      afterHoursNumber.update(
        formatPhoneNumber(profileDatas.afterHoursNumber ?? "")
      );
      timeZone.update(profileDatas.timezone);
      alreadyASigningAgent.update(
        profileDatas.alreadyASigningAgent ? true : false
      );
      numberOfSigningsClosed.update(
        profileDatas.numberOfSigningsClosed ?? signingsClosed.below200
      );
      hasLaserPrinterWithDualTrays.update(
        profileDatas.hasLaserPrinterWithDualTrays ? true : false
      );
      industryExperience.update(profileDatas.industryExperience ?? "");
      nameOnCheque.update(profileDatas.nameOnCheque ?? "");
      spokenLanguages.update(profileDatas.spokenLanguages ?? "");
    }
  }, [profileDatas]);

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      primaryNumber: "",
      officeNumber: "",
      afterHoursNumber: "",
    };
    if (numberOfSigningsClosed.state === "" && alreadyASigningAgent.state) {
      newValidationError = {
        ...newValidationError,
        numberOfSigningsClosed: 'Choose "Less than 200" /  "200 or more"',
      };
      isValidated = false;
    }
    if (
      !isValidPhoneNumber(primaryNumber.state) &&
      primaryNumber.state !== ""
    ) {
      newValidationError = {
        ...newValidationError,
        primaryNumber: "Phone Number is not valid",
      };
      isValidated = false;
    }

    if (!isValidPhoneNumber(officeNumber.state) && officeNumber.state !== "") {
      newValidationError = {
        ...newValidationError,
        officeNumber: "Phone Number is not valid",
      };
      isValidated = false;
    }
    if (
      !isValidPhoneNumber(afterHoursNumber.state) &&
      afterHoursNumber.state !== ""
    ) {
      newValidationError = {
        ...newValidationError,
        afterHoursNumber: "Phone Number is not valid",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  function getPersonalDetalsData() {
    var personalDetails = {
      addresses: [
        {
          id: address.state.id,
          addressType: address.state.addressType,
          streetAddress1: address.state.streetAddress1.trim(),
          streetAddress2: address.state.streetAddress2.trim(),
          city: address.state.city.trim(),
          state: address.state.state.trim(),
          zip: address.state.zipCode.trim(),
          county: address.state.county.trim(),
          country: DEFAULT_COUNTRY,
          is_signing_location: false,
        },
      ],
      primaryNumber: appendCountryCode(primaryNumber.state),
      officeNumber: appendCountryCode(officeNumber.state),
      afterHoursNumber: appendCountryCode(afterHoursNumber.state),
      smsNumber: profileDatas.smsNumber,
      alreadyASigningAgent: alreadyASigningAgent.state,
      numberOfSigningsClosed: numberOfSigningsClosed.state,
      industryExperience: industryExperience.state,
      hasLaserPrinterWithDualTrays: hasLaserPrinterWithDualTrays.state,
      spokenLanguages: spokenLanguages.state,
      nameOnCheque: nameOnCheque.state,
      timezone: timeZone.state,
      profileCompletionPercentage: checkPercentage(),
    };
    return personalDetails;
  }

  async function updatePersonalDetails(e) {
    e.preventDefault();
    isLoader.update(true);
    let data = getPersonalDetalsData();
    var response = await updateProfile(profileDatas.profileId, data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        isLoader.update(false);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else {
        calltoast("error", response.data.message);
        isLoader.update(false);
      }
    } else {
      calltoast("error", response.data.message);
      isLoader.update(false);
    }
    isButtonDisabled.update(true);
  }

  const fetchLanguages = async () => {
    var result = await getConfigurationList();
    result.status === 200
      ? languages.update(result.data.data.languages)
      : languages.update([]);
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Fragment>
      <AccordionDetails>
        <Grid container spacing={3} px={2} mb={5}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="standard" color="secondary">
              <InputLabel>
                <Typography variant="font12">Time zone</Typography>
              </InputLabel>
              <Select
                labelId="time-zone-select-label"
                id="time-zone"
                label={<Typography variant="font12">Time zone</Typography>}
                value={timeZone.state}
                onChange={(e) => {
                  isButtonDisabled.update(false);
                  timeZone.update(e.target.value);
                }}
              >
                {getTimeZones().map((timeZone, index) => (
                  <MenuItem key={index} value={timeZone.value}>
                    {timeZone.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Address
            id="primary-address"
            heading="Primary address"
            addressIs={address}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6}>
              <TextField
                error={
                  validationError.state.primaryNumber === "" ? false : true
                }
                helperText={validationError.state.primaryNumber}
                id="primary-number"
                label={<Typography variant="font12">Primary Number</Typography>}
                inputProps={{ maxLength: 16 }}
                variant="standard"
                color="secondary"
                fullWidth
                value={primaryNumber.state}
                onChange={(e) => {
                  isButtonDisabled.update(false);
                  validationError.update({
                    ...validationError.state,
                    primaryNumber: "",
                  });
                  primaryNumber.update(formatPhoneNumber(e.target.value));
                }}
                onBlur={(e) => {
                  !isValidPhoneNumber(e.target.value) &&
                    e.target.value !== "" &&
                    validationError.update({
                      ...validationError.state,
                      primaryNumber: "Phone Number is not valid",
                    });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validationError.state.officeNumber === "" ? false : true}
                helperText={validationError.state.officeNumber}
                id="office-number"
                label={<Typography variant="font12">Office Number</Typography>}
                variant="standard"
                color="secondary"
                fullWidth
                value={officeNumber.state}
                inputProps={{ maxLength: 16 }}
                onChange={(e) => {
                  isButtonDisabled.update(false);
                  validationError.update({
                    ...validationError.state,
                    officeNumber: "",
                  });
                  officeNumber.update(formatPhoneNumber(e.target.value));
                }}
                onBlur={(e) => {
                  !isValidPhoneNumber(e.target.value) &&
                    e.target.value !== "" &&
                    validationError.update({
                      ...validationError.state,
                      officeNumber: "Phone Number is not valid",
                    });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="after-hours-number"
                error={
                  validationError.state.afterHoursNumber === "" ? false : true
                }
                helperText={validationError.state.afterHoursNumber}
                label={
                  <Typography variant="font12">After hours Number</Typography>
                }
                variant="standard"
                color="secondary"
                fullWidth
                value={afterHoursNumber.state}
                inputProps={{ maxLength: 16 }}
                onChange={(e) => {
                  isButtonDisabled.update(false);
                  validationError.update({
                    ...validationError.state,
                    afterHoursNumber: "",
                  });
                  afterHoursNumber.update(formatPhoneNumber(e.target.value));
                }}
                onBlur={(e) => {
                  !isValidPhoneNumber(e.target.value) &&
                    e.target.value !== "" &&
                    validationError.update({
                      ...validationError.state,
                      afterHoursNumber: "Phone Number is not valid",
                    });
                }}
              />
            </Grid>
          </Grid>
          {!isUser && (
            <Fragment>
              <PolarQuestions
                valueIs={alreadyASigningAgent.state}
                toggle={alreadyASigningAgent.update}
                isButtonDisabled={isButtonDisabled}
                question="Have you been a signing agent before?"
              />
              <Grid item xs={12} md={6}>
                <Typography
                  variant="font12"
                  color={
                    alreadyASigningAgent.state
                      ? validationError.state.numberOfSigningsClosed && "error"
                      : "#777"
                  }
                >
                  Approximately, how many signings have you closed?
                </Typography>
                <FormGroup>
                  <Stack direction="row" spacing={10}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={alreadyASigningAgent.state ? false : true}
                          color="secondary"
                          checked={
                            numberOfSigningsClosed.state ===
                            signingsClosed.below200
                          }
                          onChange={(e) => {
                            isButtonDisabled.update(false);
                            e.target.checked
                              ? numberOfSigningsClosed.update(
                                  signingsClosed.below200
                                )
                              : numberOfSigningsClosed.update(
                                  signingsClosed.below200
                                );
                            validationError.update({
                              ...validationError.state,
                              numberOfSigningsClosed: "",
                            });
                          }}
                        />
                      }
                      label={
                        <Typography variant="font12" color="#777">
                          Less than 200
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={alreadyASigningAgent.state ? false : true}
                          color="secondary"
                          checked={
                            numberOfSigningsClosed.state ===
                            signingsClosed.over200
                          }
                          onChange={(e) => {
                            isButtonDisabled.update(false);
                            e.target.checked
                              ? numberOfSigningsClosed.update(
                                  signingsClosed.over200
                                )
                              : numberOfSigningsClosed.update(
                                  signingsClosed.below200
                                );
                            validationError.update({
                              ...validationError.state,
                              numberOfSigningsClosed: "",
                            });
                          }}
                        />
                      }
                      label={
                        <Typography variant="font12" color="#777">
                          200 or more
                        </Typography>
                      }
                    />
                  </Stack>
                  <FormHelperText
                    error={
                      validationError.state.numberOfSigningsClosed === ""
                        ? false
                        : true
                    }
                  >
                    {validationError.state.numberOfSigningsClosed}
                  </FormHelperText>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="industry-experience"
                  label={
                    <Typography variant="font12">
                      Any Relevant Experience In This Industry?
                    </Typography>
                  }
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={industryExperience.state}
                  onChange={(e) => {
                    isButtonDisabled.update(false);
                    industryExperience.update(e.target.value);
                  }}
                />
              </Grid>
              <PolarQuestions
                valueIs={hasLaserPrinterWithDualTrays.state}
                toggle={hasLaserPrinterWithDualTrays.update}
                question="Do you have a laser printer with dual trays?"
                isButtonDisabled={isButtonDisabled}
              />
              <Grid item xs={12} md={6}>
                <CustomMultipleSelect
                  variant="standard"
                  size="normal"
                  label={
                    <Typography variant="font12">
                      What other languages do you speak besides English?
                    </Typography>
                  }
                  isButtonDisabled={isButtonDisabled}
                  options={languages.state}
                  selectedOptions={spokenLanguages.state}
                  setSelectedOptions={spokenLanguages.update}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="check-out-to"
                  label={
                    <Typography variant="font12">
                      Who should we make your check out to?
                    </Typography>
                  }
                  inputProps={{ maxLength: 35 }}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={nameOnCheque.state}
                  onChange={(e) => {
                    isButtonDisabled.update(false);
                    nameOnCheque.update(e.target.value);
                  }}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
        <Divider sx={{ borderBottomWidth: 3 }} />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          px={2}
          mt={2}
        >
          <Button
            variant="contained"
            disabled={isLoader.state || isButtonDisabled.state}
            onClick={(e) => {
              checkValidatorOnSubmit() && updatePersonalDetails(e);
            }}
          >
            <Typography variant="buttonLabel" mx={6}>
              Save
            </Typography>
            {isLoader.state && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  marginLeft: "-10px",
                }}
              />
            )}
          </Button>
        </Grid>
      </AccordionDetails>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default PersonalDetails;
