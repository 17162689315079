import React, { Fragment } from "react";

import {
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { STATES as states } from "components/common/Constant";

function Address(props) {
  const { heading, addressIs, isButtonDisabled } = props;

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="font16b" color="#777777">
          {heading}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font12">Address type</Typography>
          </InputLabel>
          <Select
            labelId="address-type-select-label"
            id="address-type"
            label={<Typography variant="font12">Address type</Typography>}
            value={addressIs.state.addressType}
            onChange={(e) => {
              isButtonDisabled.update(false);
              addressIs.update({
                ...addressIs.state,
                addressType: e.target.value,
              });
            }}
          >
            {["Signing location", "Office", "Home", "Primary", "Other"].map(
              (type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="Street-Address-1"
          label={<Typography variant="font12">Street Address 1</Typography>}
          inputProps={{ maxLength: 35 }}
          variant="standard"
          color="secondary"
          fullWidth
          value={addressIs.state.streetAddress1}
          onChange={(e) => {
            isButtonDisabled.update(false);
            addressIs.update({
              ...addressIs.state,
              streetAddress1: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="Street-Address-2"
          label={<Typography variant="font12">Street Address 2</Typography>}
          inputProps={{ maxLength: 35 }}
          variant="standard"
          color="secondary"
          fullWidth
          value={addressIs.state.streetAddress2}
          onChange={(e) => {
            isButtonDisabled.update(false);
            addressIs.update({
              ...addressIs.state,
              streetAddress2: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="city"
          label={<Typography variant="font12">City</Typography>}
          inputProps={{ maxLength: 35 }}
          variant="standard"
          color="secondary"
          fullWidth
          value={addressIs.state.city}
          onChange={(e) => {
            isButtonDisabled.update(false);
            addressIs.update({
              ...addressIs.state,
              city: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font12">State</Typography>
          </InputLabel>

          <Select
            labelId="state-select-label"
            id="state"
            label={<Typography variant="font12">State</Typography>}
            value={addressIs.state.state}
            onChange={(e) => {
              isButtonDisabled.update(false);
              addressIs.update({
                ...addressIs.state,
                state: e.target.value,
              });
            }}
          >
            {states.map((state) => (
              <MenuItem key={state.code} value={state.code}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="county"
          label={<Typography variant="font12">County</Typography>}
          inputProps={{ maxLength: 35 }}
          variant="standard"
          color="secondary"
          fullWidth
          value={addressIs.state.county}
          onChange={(e) => {
            isButtonDisabled.update(false);
            addressIs.update({
              ...addressIs.state,
              county: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="zip-code"
          label={<Typography variant="font12">Zip Code</Typography>}
          inputProps={{ maxLength: 15 }}
          variant="standard"
          color="secondary"
          fullWidth
          value={addressIs.state.zipCode}
          onChange={(e) => {
            isButtonDisabled.update(false);
            addressIs.update({
              ...addressIs.state,
              zipCode: e.target.value.replace(/\D/g, ""),
            });
          }}
        />
      </Grid>
    </Fragment>
  );
}

export default Address;
