import React from "react";
import { Box, Modal } from "@mui/material/";
import { smallModalStyle } from "components/common/Styles";
import AddNote from "./AddNote";
import AddAddress from "./AddAddress";
import AddDocuments from "./AddDocuments";
import ViewDocumentActivity from "./ViewDocumentActivity";

function OrderModal(props) {
  const { open, type, orderDetails, fetchOrderDetails } = props;

  return (
    <Modal open={open.state} onClose={() => open.update(false)}>
      <Box sx={smallModalStyle}>
        {type === "addNote" && (
          <AddNote orderDetails={orderDetails} open={open} />
        )}
        {type === "addAddress" && (
          <AddAddress
            open={open}
            orderDetails={orderDetails}
            fetchOrderDetails={fetchOrderDetails}
          />
        )}
        {type === "addDocuments" && <AddDocuments open={open} />}
        {type === "viewDocummentActivity" && (
          <ViewDocumentActivity open={open} />
        )}
      </Box>
    </Modal>
  );
}

export default OrderModal;
