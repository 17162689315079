import { authFetch } from "service/AuthProvider";
import { login } from "./AuthProvider";

const SERVICE_URL = "/admin-service/v1/";

export const getAllRoleList = async () => {
  let url = SERVICE_URL + "roles";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createUser = async (body) => {
  let url = SERVICE_URL + "user";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateUser = async (profileId, body) => {
  let url = SERVICE_URL + "users/" + profileId;
  return await authFetch("put", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const listAdminUser = async (formData) => {
  let url = SERVICE_URL + "admin-users";
  return await authFetch("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteUser = async (profileId) => {
  let url = SERVICE_URL + "users/" + profileId;
  return await authFetch("delete", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const forceResetOrTempPasswordGenerate = async (profileId, body) => {
  let url = SERVICE_URL + "users/set-password/" + profileId;
  return await authFetch("put", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateStatus = async (profileId, body) => {
  let url = SERVICE_URL + "notary-status/" + profileId;
  return await authFetch("put", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const listNotaryUsers = async (formData) => {
  let url = SERVICE_URL + "notary-users";
  return await authFetch("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAverageRating = async (profileId) => {
  let url = SERVICE_URL + "users/" + profileId + "/avg-ratings";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAgenciesRating = async (
  profileId,
  signingAgencyId,
  page,
  limit
) => {
  let url =
    SERVICE_URL +
    "users/" +
    profileId +
    "/signing-agencies/" +
    signingAgencyId +
    "/ratings?page=" +
    page +
    "&limit=" +
    limit;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getBlacklists = async (profileId, page, limit, isSoftBan) => {
  let url =
    SERVICE_URL +
    "users/" +
    profileId +
    "/blacklists?page=" +
    page +
    "&limit=" +
    limit +
    "&softBan=" +
    isSoftBan;
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const masqueradeUser = async (profileId) => {
  let url = SERVICE_URL + "masquerade-user/" + profileId;
  return await authFetch("get", url)
    .then(function (response) {
      let userData = response.data.data;
      localStorage.setItem("user", JSON.stringify(userData));
      let session = {
        //refreshToken: userData.refreshToken,
        accessToken: userData.access_token,
      };
      login(session);
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getNotarySummary = async () => {
  let url = SERVICE_URL + "notary-summary";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAdminSummary = async () => {
  let url = SERVICE_URL + "admin-summary";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const listNotarySummary = async (
  page,
  limit,
  search,
  orderBy,
  sort,
  type
) => {
  let url =
    SERVICE_URL +
    "notary-summary-list?page=" +
    page +
    "&limit=" +
    limit +
    "&search=" +
    search +
    "&orderBy=" +
    orderBy +
    "&sort=" +
    sort +
    "&type=" +
    type;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const listAdminUserSummary = async (
  page,
  limit,
  search,
  orderBy,
  sort,
  type
) => {
  let url =
    SERVICE_URL +
    "admin-summary-list?page=" +
    page +
    "&limit=" +
    limit +
    "&search=" +
    search +
    "&orderBy=" +
    orderBy +
    "&sort=" +
    sort +
    "&type=" +
    type;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const listOrganizations = async (page, limit, orderBy, sort, search) => {
  let url =
    SERVICE_URL +
    "organizations?page=" +
    page +
    "&limit=" +
    limit +
    "&orderBy=" +
    orderBy +
    "&sort=" +
    sort +
    "&search=" +
    search;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const listOrganizationMembers = async (organizationId) => {
  let url = SERVICE_URL + "organizations/" + organizationId + "/members-list";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const advanceNotarySearch = async (searchParams) => {
  let url = SERVICE_URL + searchParams;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const taggedSigningAgencyList = async (profileId) => {
  let url = SERVICE_URL + "tagged-signing-agency-list?profileId=" + profileId;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getNotaryTags = async (params) => {
  let url = SERVICE_URL + "tags-list?" + params;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteNotaryTags = async (profileId, tagId) => {
  let url = SERVICE_URL + "tag/" + profileId + "/delete/" + tagId;
  return await authFetch("delete", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createNotaryTags = async (profileId, body) => {
  let url = SERVICE_URL + "tag/" + profileId + "/create";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const quickOrderSearch = async (quickSearchParam, limit, page) => {
  let url =
    SERVICE_URL +
    "order-quick-search?search=" +
    quickSearchParam +
    "&page=" +
    page +
    "&limit=" +
    limit;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getOrderSummary = async () => {
  let url = SERVICE_URL + "order-summary";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getBarChartData = async () => {
  let url = SERVICE_URL + "order-status-summary-chart-data";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLineChartData = async () => {
  let url = SERVICE_URL + "new-orders-chart-data";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const quickNotarySearch = async (searchParams) => {
  let url = SERVICE_URL + searchParams;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
