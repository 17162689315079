import React from "react";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";

function NotarySearchData(props) {
  const { searchParams } = props;

  return (
    <Grid container rowSpacing={{ xs: 2, sm: 3, md: 4 }} columnSpacing={5}>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="standard" color="secondary">
          <InputLabel>
            <Typography variant="font14">Notary status:</Typography>
          </InputLabel>
          <Select
            labelId="notary-status-select-label"
            id="notary-status"
            label={<Typography variant="font14">Notary status:</Typography>}
            value={searchParams.state.notaryData.notaryStatus}
            onChange={(e) => {
              searchParams.update({
                ...searchParams.state,
                notaryData: {
                  notaryStatus: e.target.value,
                },
              });
            }}
          >
            {[
              "Any",
              "Accepted",
              "Completed",
              "Declined",
              "Pending",
              "Expired",
            ].map((status, index) => (
              <MenuItem key={index} value={status.toLowerCase()}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default NotarySearchData;
