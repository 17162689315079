import React, { useContext, useEffect } from "react";
import { ProfileContext } from "AppContext";

import { Typography, Link, Paper, Stack, Divider } from "@mui/material";
import { Box } from "@mui/system";
import NotaryRatingDetailModal from "./NotaryRatingDetailModal";
import NotaryBanAndBlockedModal from "./NotaryBanAndBlockedModal";
import { useStateUpdate } from "UseStateUpdate";
import NotaryOrganizationsModal from "./NotaryOrganizationsModal";

function UserRating() {
  const profileValue = useContext(ProfileContext);
  const { profileDatas } = profileValue;
  const openRatingModal = useStateUpdate(false);
  const openOrganizationModal = useStateUpdate(false);
  const openBanBlockedModal = useStateUpdate(false);
  const tabIs = useStateUpdate(-1);
  const softBanCount = useStateUpdate(0);
  const blockedCount = useStateUpdate(0);
  const notaryName = useStateUpdate("");

  useEffect(() => {
    notaryName.update(
      (profileDatas.firstName ?? "") +
        " " +
        (profileDatas.middleName ?? "") +
        " " +
        (profileDatas.lastName ?? "")
    );
  }, [profileDatas]);

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <NotaryRatingDetailModal
        open={openRatingModal}
        profileId={profileDatas.profileId}
        notaryName={notaryName.state}
        overallRating={profileDatas.avgRating}
      />
      <NotaryBanAndBlockedModal
        open={openBanBlockedModal}
        tabIs={tabIs}
        profileId={profileDatas.profileId}
        notaryName={notaryName.state}
        softBanCount={softBanCount}
        blockedCount={blockedCount}
      />
      <NotaryOrganizationsModal
        open={openOrganizationModal}
        profileId={profileDatas.profileId}
        notaryName={notaryName.state}
      />
      <Box p={3}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <Typography variant="font14">Overall Rating:</Typography>
            <Typography variant="font14b">
              {profileDatas.avgRating === ""
                ? "not yet rated"
                : profileDatas.avgRating}
            </Typography>
            {profileDatas.avgRating !== "" && (
              <Link
                href=""
                variant="font14"
                color="secondary.light"
                onClick={(e) => {
                  e.preventDefault();
                  openRatingModal.update(true);
                }}
              >
                View all
              </Link>
            )}
          </Stack>

          <Divider />

          <Stack direction="row" spacing={1}>
            <Typography variant="font14b">
              {"Soft bans (" + softBanCount.state + ")"}
            </Typography>
            {softBanCount.state > 0 && (
              <Link
                href=""
                variant="font14"
                color="secondary.light"
                onClick={(e) => {
                  e.preventDefault();
                  openBanBlockedModal.update(true);
                  tabIs.update(0);
                }}
              >
                View
              </Link>
            )}
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="font14b">
              {"Blocked (" + blockedCount.state + ")"}
            </Typography>
            {blockedCount.state > 0 && (
              <Link
                href=""
                variant="font14"
                color="secondary.light"
                onClick={(e) => {
                  e.preventDefault();
                  openBanBlockedModal.update(true);
                  tabIs.update(1);
                }}
              >
                View
              </Link>
            )}
          </Stack>

          <Divider />

          <Link
            href=""
            variant="font14b"
            color="secondary.light"
            onClick={(e) => {
              e.preventDefault();
              openOrganizationModal.update(true);
            }}
          >
            View Organizations
          </Link>
        </Stack>
      </Box>
    </Paper>
  );
}

export default UserRating;
