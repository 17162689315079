import React, { Fragment, useEffect } from "react";
//materialUi
import { Grid, Paper, Typography, Box, Stack, Skeleton } from "@mui/material";
//assets
import arrowUp from "assets/images/arrowUp.png";
import arrowDown from "assets/images/arrowDown.png";
import { getOrderSummary } from "service/ProfileService";
import { getProfileId } from "components/common/GetUserDetail";
import { useStateUpdate } from "UseStateUpdate";

function createFigures(title, value, isIncrease) {
  return { title, value, isIncrease };
}

const skeleton = () => {
  return (
    <Fragment>
      {[1, 2, 3, 4].map((index) => (
        <Grid key={index} item xs={6} md={2}>
          <Paper variant="outlined">
            <Box p={1} pb={{ xs: 1, md: 4 }}>
              <Stack spacing={2}>
                <Skeleton animation="wave" variant="rectangular" />
                <Grid container pt={{ xs: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12} md={4}>
        <Paper variant="outlined">
          <Box>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={100}
              width="100%"
            />
          </Box>
        </Paper>
      </Grid>
    </Fragment>
  );
};

function OrderSummary() {
  const figures = useStateUpdate([]);

  useEffect(() => {
    fetchOrderSummary();
  }, []);

  const fetchOrderSummary = async () => {
    var result = await getOrderSummary(getProfileId());
    if (result.status === 200) {
      const data = result.data.data;
      figures.update([
        createFigures(
          "Signings today",
          data.signingsToday,
          data.signingsToday > data.signingsYesterday
        ),
        createFigures(
          "Signings this month",
          data.signingsThisMonth,
          data.signingsThisMonth > data.signingsLastMonth
        ),
        createFigures(
          "Signings this year",
          data.signingsThisYear,
          data.signingsThisYear > data.signingsLastYear
        ),
        createFigures("Unscheduled signings", data.unscheduledSignings, null),
      ]);
    }
  };

  return (
    <Grid container spacing={2}>
      {figures.state.length > 0 ? (
        <Fragment>
          {figures.state.map((figure) => (
            <Grid key={figure.title} container item xs={6} md={3}>
              <Paper variant="outlined" sx={{ width: "100%" }}>
                <Box p={1} pb={{ xs: 1, md: 4 }}>
                  <Stack spacing={2}>
                    <Typography variant="font15b" color="#686868" noWrap>
                      {figure.title}
                    </Typography>
                    <Grid container pt={{ xs: 1, md: 2 }}>
                      <Grid item xs={6}>
                        <Typography variant="font32b">
                          {figure.value}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {figure.isIncrease ===
                        null ? null : figure.isIncrease ? (
                          <img src={arrowUp} alt="increased" />
                        ) : (
                          <img src={arrowDown} alt="decreased" />
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Fragment>
      ) : (
        skeleton()
      )}
    </Grid>
  );
}

export default OrderSummary;
