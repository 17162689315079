import React, { Fragment, useEffect } from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  Grid,
  styled,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { isValidPhoneNumber } from "components/common/FormValidator";
import { isValidEmail } from "components/common/FormValidator";
import { isEmptyString } from "components/common/FormValidator";
import { formatPhoneNumber } from "components/common/FormatPhoneNumber";
import { STATES as states } from "components/common/Constant";
import { fetchNNAProfile } from "service/RegistrationService";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { updateFromNnaProfile } from "service/RegistrationService";
import { appendCountryCode } from "components/common/FormatPhoneNumber";
import PolarQuestions from "components/profile/common_componets/PolarQuestion";
import Toast from "components/common/Toast";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));

function ImportNNAProfile(props) {
  const {
    setIsFetchProfile,
    setIsNnaProfileImported,
    personGuid,
    registrationId,
  } = props;
  const isFetchingData = useStateUpdate(true);
  const fetchedData = useStateUpdate({});
  const isLoader = useStateUpdate(false);
  const firstName = useStateUpdate("");
  const middleName = useStateUpdate("");
  const lastName = useStateUpdate("");
  const email = useStateUpdate("");
  const phoneNumber = useStateUpdate("");
  const hasLaserPrinterWithDualTrays = useStateUpdate(false);
  const address = useStateUpdate({
    id: "",
    addressType: "Office",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    county: "",
    zipCode: "",
  });

  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const validationError = useStateUpdate({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress1: "",
    city: "",
    state: "",
    county: "",
    zipCode: "",
  });

  useEffect(() => {
    callFetchNNAProfile();
  }, []);

  useEffect(() => {
    if (isNotEmpty(fetchedData.state)) {
      firstName.update(fetchedData.state.firstName ?? "");
      middleName.update(fetchedData.state.middleName ?? "");
      lastName.update(fetchedData.state.lastName ?? "");
      email.update(fetchedData.state.email ?? "");
      phoneNumber.update(getPrimaryNumber(fetchedData.state.phoneNumber));
      address.update({
        ...address.state,
        streetAddress1: fetchedData.state.address[0].addressLine1 ?? "",
        streetAddress2: fetchedData.state.address[0].addressLine2 ?? "",
        city: fetchedData.state.address[0].city ?? "",
        state: getStateCode(fetchedData.state.address[0].state),
        county: fetchedData.state.address[0].county ?? "",
        zipCode: fetchedData.state.address[0].zip ?? "",
      });
      hasLaserPrinterWithDualTrays.update(
        fetchedData.state.hasLaserPrinterWithDualTrays ?? false
      );
    }
  }, [fetchedData.state]);

  function getPrimaryNumber(phoneNumber) {
    var primaryNumber = "";

    phoneNumber.forEach((number) => {
      try {
        number.primaryNumber.length > 0 &&
          (primaryNumber = number.primaryNumber[0]);
      } catch {}
    });

    return primaryNumber;
  }

  function getStateCode(stateName) {
    var stateCode = "";
    states.forEach((stateIs) => {
      stateIs.name.toLowerCase() === stateName.toLowerCase() &&
        (stateCode = stateIs.code);
    });
    return stateCode;
  }

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      streetAddress1: "",
      city: "",
      state: "",
      county: "",
      zipCode: "",
    };
    if (isEmptyString(firstName.state)) {
      newValidationError = {
        ...newValidationError,
        firstName: "First Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(lastName.state)) {
      newValidationError = {
        ...newValidationError,
        lastName: "Last Name is required",
      };
      isValidated = false;
    }
    if (isEmptyString(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is required",
      };
      isValidated = false;
    } else if (!isValidEmail(email.state)) {
      newValidationError = {
        ...newValidationError,
        email: "Email is not valid.",
      };
      isValidated = false;
    }
    if (!isValidPhoneNumber(phoneNumber.state)) {
      newValidationError = {
        ...newValidationError,
        phoneNumber: "Phone Number is not valid",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.streetAddress1)) {
      newValidationError = {
        ...newValidationError,
        streetAddress1: "Street address 1 is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.city)) {
      newValidationError = {
        ...newValidationError,
        city: "City is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.state)) {
      newValidationError = {
        ...newValidationError,
        state: "State is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.county)) {
      newValidationError = {
        ...newValidationError,
        county: "County is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.zipCode)) {
      newValidationError = {
        ...newValidationError,
        zipCode: "Zip is required",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  const callFetchNNAProfile = async () => {
    isFetchingData.update(true);
    var response = await fetchNNAProfile(personGuid);
    if (response.status === 200) {
      fetchedData.update(response.data.data);
    }
    isFetchingData.update(false);
  };

  async function fetchProfile(importData) {
    if (importData) {
      isLoader.update(true);
      let fetchedData = getFetchedData();
      var response = await updateFromNnaProfile(registrationId, fetchedData);
      if (response.status === 200) {
        if (response.data.code === 200) {
          isLoader.update(false);
          setIsNnaProfileImported(true);
          setIsFetchProfile(false);
        } else {
          calltoast("error", response.data.message);
        }
      } else {
        calltoast("error", response.data.message);
      }
    } else {
      setIsFetchProfile(false);
    }
  }

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const getFetchedData = () => {
    return {
      firstName: firstName.state,
      middleName: middleName.state,
      lastName: lastName.state,
      email: email.state,
      phoneNumber: appendCountryCode(phoneNumber.state),
      photoURL: fetchedData.state.photoURL ?? "",
      industryExperience: "",
      hasLaserPrinterWithDualTrays: hasLaserPrinterWithDualTrays.state,
      languages: [],
      addresses: [
        {
          addressType: address.state.addressType,
          streetAddress1: address.state.streetAddress1,
          streetAddress2: address.state.streetAddress2,
          city: address.state.city,
          county: address.state.county,
          state: address.state.state,
          country: "USA",
          zip: address.state.zipCode,
          lat: "",
          long: "",
        },
      ],
    };
  };

  return (
    <Container maxWidth="md">
      <Box
        mt={{ xs: 10, sm: 6, md: 5 }}
        p={4}
        style={{ backgroundColor: "white" }}
      >
        <Fragment>
          <Grid item xs={12} sm={12} md={12} mb={2}>
            <Item elevation={0}>
              <Typography variant="font20b" sx={{ mb: 5 }}>
                NNA profile details
              </Typography>
            </Item>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={validationError.state.firstName === "" ? false : true}
                  helperText={validationError.state.firstName}
                  id="register-first-name"
                  label={<Typography variant="font12">First Name</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={firstName.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      firstName: "",
                    });
                    firstName.update(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        firstName: "First Name is required",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  id="register-middle-name"
                  label={<Typography variant="font12">Middle Name</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={middleName.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      middleName: "",
                    });
                    middleName.update(e.target.value);
                  }}
                />
              </Item>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={validationError.state.lastName === "" ? false : true}
                  helperText={validationError.state.lastName}
                  id="register-last-name"
                  label={<Typography variant="font12">Last Name</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={lastName.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      lastName: "",
                    });
                    lastName.update(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        lastName: "Last Name is required",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled
                  error={validationError.state.email === "" ? false : true}
                  helperText={validationError.state.email}
                  type="email"
                  id="register-email"
                  label={<Typography variant="font12">Email</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={email.state}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      email: "",
                    });
                    email.update(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        email: "Email is required",
                      });
                    } else if (!isValidEmail(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        email: "Email is not valid.",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={
                    validationError.state.phoneNumber === "" ? false : true
                  }
                  helperText={validationError.state.phoneNumber}
                  id="register-phone-number"
                  label={<Typography variant="font12">Phone number</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={phoneNumber.state}
                  inputProps={{ maxLength: 16 }}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      phoneNumber: "",
                    });
                    phoneNumber.update(formatPhoneNumber(e.target.value));
                  }}
                  onBlur={(e) => {
                    !isValidPhoneNumber(e.target.value) &&
                      validationError.update({
                        ...validationError.state,
                        phoneNumber: "Phone Number is not valid",
                      });
                  }}
                />
              </Item>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <FormControl
                  disabled={isFetchingData.state}
                  required
                  fullWidth
                  variant="standard"
                  color="secondary"
                >
                  <InputLabel>
                    <Typography variant="font12">Address type</Typography>
                  </InputLabel>
                  <Select
                    labelId="address-type-select-label"
                    id="address-type"
                    label={
                      <Typography variant="font12">Address type</Typography>
                    }
                    value={address.state.addressType}
                    onChange={(e) => {
                      address.update({
                        ...address.state,
                        addressType: e.target.value,
                      });
                    }}
                  >
                    {[
                      "Signing location",
                      "Office",
                      "Home",
                      "Primary",
                      "Other",
                    ].map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={
                    validationError.state.streetAddress1 === "" ? false : true
                  }
                  helperText={validationError.state.streetAddress1}
                  id="Street-Address-1"
                  label={
                    <Typography variant="font12">Street Address 1</Typography>
                  }
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={address.state.streetAddress1}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      streetAddress1: "",
                    });
                    address.update({
                      ...address.state,
                      streetAddress1: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        streetAddress1: "Street address 1 is required",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  id="Street-Address-2"
                  label={
                    <Typography variant="font12">Street Address 2</Typography>
                  }
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={address.state.streetAddress2}
                  onChange={(e) => {
                    address.update({
                      ...address.state,
                      streetAddress2: e.target.value,
                    });
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={validationError.state.city === "" ? false : true}
                  helperText={validationError.state.city}
                  id="city"
                  label={<Typography variant="font12">City</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={address.state.city}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      city: "",
                    });
                    address.update({
                      ...address.state,
                      city: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        city: "City is required",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <FormControl
                  disabled={isFetchingData.state}
                  required
                  fullWidth
                  variant="standard"
                  color="secondary"
                >
                  <InputLabel
                    error={validationError.state.state === "" ? false : true}
                  >
                    <Typography variant="font12">State</Typography>
                  </InputLabel>
                  <Select
                    error={validationError.state.state === "" ? false : true}
                    labelId="state-select-label"
                    id="state"
                    label={<Typography variant="font12">State</Typography>}
                    value={address.state.state}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        state: "",
                      });
                      address.update({
                        ...address.state,
                        state: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          state: "State is required",
                        });
                      }
                    }}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.code} value={state.code}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    error={validationError.state.state === "" ? false : true}
                  >
                    {validationError.state.state}
                  </FormHelperText>
                </FormControl>
              </Item>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={validationError.state.county === "" ? false : true}
                  helperText={validationError.state.county}
                  id="county"
                  label={<Typography variant="font12">County</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={address.state.county}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      county: "",
                    });
                    address.update({
                      ...address.state,
                      county: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        county: "County is required",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Item elevation={0}>
                <TextField
                  disabled={isFetchingData.state}
                  required
                  error={validationError.state.zipCode === "" ? false : true}
                  helperText={validationError.state.zipCode}
                  id="zip-code"
                  label={<Typography variant="font12">Zip Code</Typography>}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  value={address.state.zipCode}
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      zipCode: "",
                    });
                    address.update({
                      ...address.state,
                      zipCode: e.target.value.replace(/\D/g, ""),
                    });
                  }}
                  onBlur={(e) => {
                    if (isEmptyString(e.target.value)) {
                      validationError.update({
                        ...validationError.state,
                        zipCode: "Zip Code is required",
                      });
                    }
                  }}
                />
              </Item>
            </Grid>
            {fetchedData.state.photoURL === null ? null : (
              <Grid item xs={12}>
                <Item elevation={0}>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="font14b">Photo URL:</Typography>

                    <Link
                      href={fetchedData.state.photoURL}
                      variant="font14"
                      color="secondary.light"
                    >
                      {fetchedData.state.photoURL}
                    </Link>
                  </Stack>
                </Item>
              </Grid>
            )}
            <PolarQuestions
              valueIs={hasLaserPrinterWithDualTrays.state}
              toggle={hasLaserPrinterWithDualTrays.update}
              question="Do you have a laser printer with dual trays?"
            />
          </Grid>

          <Grid item xs={12} mt={5}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  fetchProfile(false);
                }}
              >
                <Typography variant="buttonLabel" mx={4}>
                  Do not import
                </Typography>
              </Button>
              <Button
                variant="contained"
                disabled={isLoader.state || isFetchingData.state}
                onClick={(e) => {
                  checkValidatorOnSubmit() && fetchProfile(true);
                }}
              >
                <Typography variant="buttonLabel" mx={4}>
                  Import
                </Typography>
                {isLoader.state && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      marginLeft: "-10px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Grid>
        </Fragment>
      </Box>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Container>
  );
}

export default ImportNNAProfile;
