import React, { Fragment, useEffect } from "react";

import {
  AccordionDetails,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  TableBody,
  Box,
  Paper,
  ListItem,
  List,
  Typography,
  Grid,
  Pagination,
  Stack,
  InputBase,
  IconButton,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import { visuallyHidden } from "@mui/utils";
import { useStateUpdate } from "UseStateUpdate";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ShowItemsPerPage from "components/common/ShowItemsPerPage";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { formattedDate } from "components/common/GetDateAndTime";

function createData(date, user, description, changes) {
  return { date, user, description, changes };
}

const headCells = [
  {
    id: "date",
    label: "Date",
  },
  {
    id: "user",
    label: "User",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "changes",
    label: "Changes",
  },
];

const ChangeComponent = ({ property, oldChange, newChange, document }) => {
  return (
    <Typography variant="font14" width={{ xs: 200, sm: 300, md: 400 }}>
      <FiberManualRecordIcon style={{ fontSize: "10px" }} />
      {" Changed "}
      <span style={{ color: "red" }}>{property}</span>
      {document && (
        <Fragment>
          {" of "}
          <span style={{ color: "red" }}>{document}</span>
        </Fragment>
      )}
      <Fragment>
        {" from "}
        <span style={{ color: "blue" }}>
          {oldChange === null || oldChange === "" ? "--" : oldChange}
        </span>
      </Fragment>
      <Fragment>
        {" to "}
        <span style={{ color: "green" }}>
          {newChange === null || newChange === "" ? "--" : newChange}
        </span>
      </Fragment>
    </Typography>
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ActivityLog(props) {
  const { activityLogs, filterValue, callSearchLogs, fetchLogs } = props;
  const order = useStateUpdate("asc");
  const orderBy = useStateUpdate("");
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const activityLogsData = useStateUpdate([]);
  const showAllButton = useStateUpdate(false);

  useEffect(() => {
    activityLogsData.update(getRowDatas(activityLogs));
  }, [activityLogs]);

  useEffect(() => {
    page.update(1);
    pageCount.update(
      Math.ceil(activityLogsData.state.length / rowsPerPage.state)
    );
  }, [activityLogsData.state, rowsPerPage.state]);

  useEffect(() => {
    if (filterValue.state === "" && showAllButton.state) {
      fetchLogs();
      showAllButton.update(false);
    }
  }, [filterValue.state]);

  const getRowDatas = (logs) => {
    let rows = [];
    logs &&
      logs.forEach((element) => {
        let changes = formatChanges(element.modified);
        changes !== null &&
          rows.push(
            createData(
              formattedDate(element.createdAt),
              element.username,
              element.description,
              changes
            )
          );
      });
    return rows;
  };

  const formatChanges = (modified) => {
    let changesAre = [];
    if (isNotEmpty(modified))
      for (const [property, changes] of Object.entries(modified)) {
        if (property === "user")
          for (const [userProperty, userChanges] of Object.entries(changes)) {
            changesAre.push(
              <ChangeComponent
                property={userProperty}
                oldChange={userChanges.old}
                newChange={userChanges.new}
                document={false}
              />
            );
          }
        else if (changes.new !== undefined)
          changesAre.push(
            property === "photo_url" ? (
              <Typography variant="font14">
                <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                {" Changed "}

                <span style={{ color: "red" }}>{property}</span>
              </Typography>
            ) : (
              <ChangeComponent
                property={property}
                oldChange={changes.old}
                newChange={changes.new}
                document={false}
              />
            )
          );
        else
          for (const [userProperty, userChanges] of Object.entries(changes)) {
            changesAre.push(
              <ChangeComponent
                property={userProperty}
                oldChange={
                  userProperty === "expires_at" && userChanges.old !== null
                    ? formattedDate(userChanges.old)
                    : userChanges.old
                }
                newChange={
                  userProperty === "expires_at" && userChanges.new !== null
                    ? formattedDate(userChanges.new)
                    : userChanges.new
                }
                document={property}
              />
            );
          }
      }
    else changesAre.push(<Typography variant="font14">N/A</Typography>);

    return changesAre.length === 0 ? null : changesAre;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.state === property && order.state === "asc";
    order.update(isAsc ? "desc" : "asc");
    orderBy.update(property);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="font14b">{headCell.label}</Typography>

                {orderBy === headCell.id ? (
                  <Box sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Fragment>
      <AccordionDetails>
        <Grid container justifyContent="flex-end" alignItems="center" pb={2}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 5 }}
          >
            <ShowItemsPerPage perPage={rowsPerPage} items={[10, 20, 50, 100]} />
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "center", width: 200 }}
            >
              <InputBase
                sx={{ ml: 1 }}
                placeholder="Search "
                value={filterValue.state}
                onChange={(e) => {
                  filterValue.update(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.code === "Enter" || event.code === "NumpadEnter") {
                    event.preventDefault();
                    if (event.target.value !== "") {
                      event.target.value !== "" && showAllButton.update(true);
                      event.target.value !== "" && callSearchLogs();
                    }
                  }
                }}
              />
              {filterValue.state !== "" && (
                <IconButton
                  onClick={(e) => {
                    filterValue.update("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton
                disabled={filterValue.state === ""}
                type="submit"
                sx={{ backgroundColor: "#2996AE", borderRadius: "0%" }}
                onClick={(e) => {
                  e.preventDefault();
                  showAllButton.update(true);
                  callSearchLogs();
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Stack>
        </Grid>
        {showAllButton.state && (
          <Grid container justifyContent="flex-end" alignItems="center" pb={2}>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                filterValue.update("");
              }}
            >
              <Typography variant="font12">Show all</Typography>
            </Button>
          </Grid>
        )}

        <Box sx={{ width: { xs: "82vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead
                order={order.state}
                orderBy={orderBy.state}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(
                  activityLogsData.state,
                  getComparator(order.state, orderBy.state)
                )
                  .slice(
                    (page.state - 1) * rowsPerPage.state,
                    (page.state - 1) * rowsPerPage.state + rowsPerPage.state
                  )
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="font14">{row.date}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font14">{row.user}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font14" color="#686868">
                            {row.description}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <List dense={true}>
                            {row.changes.map((data, index) => (
                              <ListItem key={index}>{data}</ListItem>
                            ))}
                          </List>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow>
                  <TableCell colSpan={4}>
                    {pageCount.state > 1 && (
                      <Grid
                        container
                        direction="row"
                        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        alignItems="center"
                      >
                        <Pagination
                          count={pageCount.state}
                          showFirstButton
                          showLastButton
                          size="small"
                          onChange={(e, value) => {
                            page.update(value);
                          }}
                        />
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AccordionDetails>
    </Fragment>
  );
}

export default ActivityLog;
