import React from "react";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { acceptInvitation } from "service/OrganizationService";
import Toast from "components/common/Toast";

function JoinOrganization({ profileId, fetchOrganizationMembersList }) {
  const tokenError = useStateUpdate("");
  const token = useStateUpdate("");
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const callAcceptInvitation = async () => {
    var response = await acceptInvitation(token.state, profileId);
    if (response.status === 200) {
      token.update("");
      calltoast("success", response.data.message);
      fetchOrganizationMembersList();
    } else {
      response.status === 400 && tokenError.update(response.data.message);
      calltoast("error", response.data.message);
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Paper variant="outlined">
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Typography variant="font20b" noWrap>
              Join an Organization
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={8}
            justifyContent={{ xs: "center", sm: "flex-end" }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2 }}
            >
              <TextField
                error={tokenError.state === "" ? false : true}
                helperText={tokenError.state === "" ? "" : tokenError.state}
                id="notary-token"
                size="small"
                placeholder="Enter short code"
                color="secondary"
                value={token.state}
                inputProps={{ maxLength: 20 }}
                onChange={(e) => {
                  tokenError.update("");
                  token.update(e.target.value);
                }}
              />
              <Button
                disabled={token.state === ""}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={(e) => {
                  callAcceptInvitation();
                }}
              >
                <Typography
                  variant="buttonLabel"
                  color={token.state === "" ? "#d3d3d3" : "#000"}
                >
                  Join Organization
                </Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Paper>
  );
}

export default JoinOrganization;
