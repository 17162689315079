import React, { Fragment, useContext } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
//materialUi
import {
  Typography,
  Grid,
  Divider,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  FormControl,
  Input,
  Tooltip,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
//icons
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@material-ui/icons";
//validation
import { isValidPassword } from "components/common/FormValidator";
//style
import { iconButton } from "components/common/Styles";
//service
import { updatePassword } from "service/ProfileService";
import { useIsAdmin } from "components/common/GetUserDetail";
import { forceResetOrTempPasswordGenerate } from "service/AdminService";
import { getProfileId } from "components/common/GetUserDetail";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

function Password() {
  const profileValue = useContext(ProfileContext);
  const isAdmin = useIsAdmin();
  const { profileDatas, fetchProfileData } = profileValue;
  const isChangePassword = useStateUpdate(false);
  const temporaryPassoword = useStateUpdate(null);
  const currentPassword = useStateUpdate("");
  const showCurrentPassword = useStateUpdate(false);
  const newPassword = useStateUpdate("");
  const showNewPassword = useStateUpdate(false);
  const confirmNewPassword = useStateUpdate("");
  const showConfirmNewPassword = useStateUpdate(false);
  const copiedPassword = useStateUpdate(false);
  const passwordHelperText = useStateUpdate(
    "Password must contain minimum 8 characters"
  );
  const validationError = useStateUpdate({
    currentPassword: "",
    newPassword: false,
    confirmNewPassword: "",
  });
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      currentPassword: "",
      newPassword: false,
      confirmNewPassword: "",
    };
    if (!isValidPassword(newPassword.state)) {
      newValidationError = {
        ...newValidationError,
        newPassword: true,
      };
      isValidated = false;
    } else if (newPassword.state !== confirmNewPassword.state) {
      newValidationError = {
        ...newValidationError,
        confirmNewPassword: "Password not match",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  function getPasswordData() {
    var data = {
      currentPassword: currentPassword.state,
      newPassword: newPassword.state,
      confirmNewPassword: confirmNewPassword.state,
    };
    return data;
  }

  const changePassword = async () => {
    let data = getPasswordData();
    var response = await updatePassword(profileDatas.profileId, data);

    if (response.status === 200) {
      if (response.data.code === 200) {
        isChangePassword.update(!isChangePassword.state);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else if (response.data.code === 422) {
        validationError.update({
          ...validationError.state,
          currentPassword: response.data.error.currentPassword[0],
        });
        calltoast("error", response.data.message);
      }
    } else {
      calltoast("error", response.data.message);
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const passwordChange = async (event) => {
    var body = {
      isTempPassword: event === "trigger" ? false : true,
    };

    var response = await forceResetOrTempPasswordGenerate(
      profileDatas.profileId,
      body
    );

    if (response.status === 200) {
      event === "temporary" &&
        temporaryPassoword.update(response.data.data.tempPassword);
      calltoast("success", response.data.message);
    } else {
      calltoast("error", response.data.message);
    }
  };

  return (
    <Grid container>
      {isChangePassword.state ? (
        <Grid item xs={12}>
          <Stack spacing={2}>
            <FormControl variant="standard" color="secondary">
              <InputLabel
                error={validationError.state.currentPassword ? true : false}
              >
                {<Typography variant="font12">Current Password*</Typography>}
              </InputLabel>
              <Input
                id="current-password"
                size="small"
                error={validationError.state.currentPassword ? true : false}
                type={showCurrentPassword.state ? "text" : "password"}
                value={currentPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    currentPassword: "",
                  });
                  currentPassword.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        showCurrentPassword.update(!showCurrentPassword.state)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showCurrentPassword.state ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  validationError.state.currentPassword === "" ? false : true
                }
              >
                {validationError.state.currentPassword}
              </FormHelperText>
            </FormControl>
            <FormControl variant="standard" color="secondary">
              <InputLabel
                error={validationError.state.newPassword ? true : false}
              >
                {<Typography variant="font12">New Password*</Typography>}
              </InputLabel>
              <Input
                id="new-password"
                size="small"
                error={validationError.state.newPassword ? true : false}
                type={showNewPassword.state ? "text" : "password"}
                value={newPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    newPassword: false,
                  });
                  newPassword.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        showNewPassword.update(!showNewPassword.state)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showNewPassword.state ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={validationError.state.newPassword ? true : false}
              >
                {
                  <Typography variant="font11">
                    {passwordHelperText.state}
                  </Typography>
                }
              </FormHelperText>
            </FormControl>
            <FormControl variant="standard" color="secondary">
              <InputLabel
                error={
                  validationError.state.confirmNewPassword === "" ? false : true
                }
              >
                {<Typography variant="font12">Confirm Password*</Typography>}
              </InputLabel>
              <Input
                id="confirm-new-password"
                size="small"
                error={
                  validationError.state.confirmNewPassword === "" ? false : true
                }
                type={showConfirmNewPassword.state ? "text" : "password"}
                value={confirmNewPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    confirmNewPassword: "",
                  });
                  confirmNewPassword.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        showConfirmNewPassword.update(
                          !showConfirmNewPassword.state
                        )
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmNewPassword.state ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  validationError.state.confirmNewPassword === "" ? false : true
                }
              >
                {validationError.state.confirmNewPassword}
              </FormHelperText>
            </FormControl>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                color="primary"
                size="small"
                onClick={(e) => {
                  checkValidatorOnSubmit() && changePassword();
                }}
                sx={iconButton.border}
              >
                <DoneIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => isChangePassword.update(!isChangePassword.state)}
                sx={iconButton.border}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      ) : (
        <Fragment>
          <Grid item xs={6}>
            <Typography variant="font14">*********</Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Stack>
              {isAdmin && profileDatas.profileId !== getProfileId() ? (
                <Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      passwordChange("trigger");
                    }}
                  >
                    <Typography variant="font11" noWrap>
                      Trigger password change
                    </Typography>
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      passwordChange("temporary");
                    }}
                  >
                    <Typography variant="font11" noWrap>
                      Generate temporary password
                    </Typography>
                  </Button>
                </Fragment>
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  onClick={(e) => {
                    isChangePassword.update(!isChangePassword.state);
                  }}
                >
                  <Typography variant="font11" noWrap>
                    {"Change Password"}
                  </Typography>
                </Button>
              )}
            </Stack>
          </Grid>
          {temporaryPassoword.state !== null && (
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="font12">Temporary Password:</Typography>

                <Typography variant="font14b">
                  {temporaryPassoword.state}
                </Typography>
                <Tooltip
                  title={
                    copiedPassword.state ? "Password copied" : "Copy password"
                  }
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      // navigator.clipboard.write(temporaryPassoword.state);
                      let textArea = document.createElement("textarea");
                      textArea.value = temporaryPassoword.state;
                      textArea.style.position = "fixed";
                      textArea.style.left = "-999999px";
                      textArea.style.top = "-999999px";
                      document.body.appendChild(textArea);
                      textArea.focus();
                      textArea.select();
                      return new Promise((res, rej) => {
                        document.execCommand("copy") ? res() : rej();
                        textArea.remove();
                        copiedPassword.update(true);
                      });
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          )}
        </Fragment>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Grid>
  );
}

export default Password;
