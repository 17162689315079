import React, { useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  Stack,
  IconButton,
  Button,
  Tab,
  Tabs,
  Divider,
  Pagination,
} from "@mui/material/";
import { largeModalStyle } from "components/common/Styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useStateUpdate } from "UseStateUpdate";
import { getBlacklists } from "service/AdminService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function NotaryBanAndBlockedModal(props) {
  const { open, tabIs, notaryName, softBanCount, blockedCount, profileId } =
    props;
  const tabValue = useStateUpdate(-1);
  const backlistData = useStateUpdate([]);
  const rowsPerPage = useStateUpdate(2);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(1);

  useEffect(() => {
    fetchBlacklist(true);
    fetchBlacklist(false);
  }, []);

  useEffect(() => {
    tabValue.state > -1 && fetchBlacklist(tabValue.state ? false : true);
  }, [tabValue.state, page.state]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  useEffect(() => {
    tabValue.update(tabIs.state);
  }, [tabIs.state]);

  const fetchBlacklist = async (isSoftBan) => {
    var result = await getBlacklists(
      profileId,
      page.state,
      rowsPerPage.state,
      isSoftBan
    );
    if (result.status === 200) {
      var data = result.data.data;
      backlistData.update(data.records);
      pageCount.update(data.pagination.total_pages);
      isSoftBan
        ? softBanCount.update(data.pagination.total)
        : blockedCount.update(data.pagination.total);
    }
  };

  const handleChange = (event, newValue) => {
    page.update(1);
    tabValue.update(newValue);
  };

  const handleClose = () => () => {
    open.update(false);
  };

  const ModalHeader = () => {
    return (
      <Fragment>
        <Grid container p={2} pl={4}>
          <Grid item xs={8}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 1, md: 2 }}
            >
              <Typography
                sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                noWrap
              >
                Bans and Blocked
              </Typography>
              <Stack direction="row" spacing={2}>
                <Typography
                  sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                  color="secondary.light"
                  noWrap
                >
                  {notaryName}
                </Typography>
                <Typography
                  sx={{ typography: { xs: "font15b", sm: "font20b" } }}
                  noWrap
                >
                  {softBanCount.state + blockedCount.state}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end">
            <IconButton onClick={handleClose()}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </Fragment>
    );
  };

  const ModalFooter = () => {
    return (
      <Stack p={2} direction="row" spacing={2} justifyContent="flex-end">
        <Pagination
          count={pageCount.state}
          showFirstButton
          showLastButton
          size="small"
          page={page.state}
          onChange={(e, value) => {
            page.update(value);
          }}
        />

        <Button
          variant="contained"
          size="small"
          onClick={handleClose()}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography variant="font14b">Close</Typography>
        </Button>
      </Stack>
    );
  };

  const ModalBody = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue.state}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
            onChange={handleChange}
          >
            {[
              "Softbans " + softBanCount.state,
              "Blocked " + blockedCount.state,
            ].map((label, index) => (
              <Tab
                key={index}
                label={
                  <Typography
                    sx={{ typography: { xs: "font13b", sm: "font17b" } }}
                  >
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </Box>
        {[0, 1].map((index) => (
          <AgencyDetails key={index} index={index} />
        ))}
      </Box>
    );
  };

  const AgencyDetails = ({ index }) => {
    return (
      <TabPanel
        value={tabValue.state}
        index={index}
        style={{ overflow: "auto", height: "50vh" }}
      >
        <Stack spacing={2}>
          {backlistData.state.map((blacklist, index) => (
            <Fragment key={index}>
              <Box p={2} pl={4}>
                <Stack spacing={2}>
                  <Typography variant="font15b">
                    {blacklist.signingAgencyName}
                  </Typography>
                  {blacklist.originatorName === null ? (
                    <Typography variant="font15">
                      {"Agency wide " + (index ? "block" : "ban")}
                    </Typography>
                  ) : (
                    <Typography variant="font15">
                      Originator: <b>{blacklist.originatorName}</b>
                    </Typography>
                  )}

                  <Typography
                    variant="font15"
                    width={{ xs: "100%", md: "90%" }}
                  >
                    Comments: {blacklist.comments ?? "--"}
                  </Typography>
                </Stack>
              </Box>
              <Divider />
            </Fragment>
          ))}
        </Stack>
      </TabPanel>
    );
  };

  return (
    <Modal open={open.state} onClose={handleClose()}>
      <Box sx={{ ...largeModalStyle, p: 0 }}>
        <ModalHeader />
        <ModalBody />
        {pageCount.state > 1 && <ModalFooter />}
      </Box>
    </Modal>
  );
}

export default NotaryBanAndBlockedModal;
