import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useIsAdmin } from "components/common/GetUserDetail";
import { useNavigate } from "react-router-dom";
import AdminDashboardSummary from "./sections/AdminDashboardSummary";
import AdminNotaryQuickSearch from "./sections/AdminNotaryQuickSearch";
import AdminOrderQuickSearch from "./sections/AdminOrderQuickSearch";

function AdminDashboard() {
  let navigate = useNavigate();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    !isAdmin && navigate("/dashboard");
  }, []);

  return isAdmin ? (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Admin Dashboard</Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <AdminNotaryQuickSearch />
          <AdminOrderQuickSearch />
          <AdminDashboardSummary />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <div />
  );
}

export default AdminDashboard;
