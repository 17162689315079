import React, { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Link,
  Stack,
  Alert,
  Button,
  Accordion,
  AccordionSummary,
  Skeleton,
} from "@mui/material";
import OrderInstructions from "./sections/OrderInstructions";
import OrderInformation from "./sections/OrderInformation";
import Notaries from "./sections/Notaries";
import Notes from "./sections/Notes";
import { getOrder } from "service/OrderService";
import { useStateUpdate } from "UseStateUpdate";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { isImage } from "components/common/FormValidator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActivityLog from "components/common/ActivityLog";
import { getOrderActivityLogs } from "service/OrderService";
import { searchOrderActivityLogs } from "service/OrderService";

function OrderDetails() {
  let navigate = useNavigate();
  const { orderId } = useParams();
  const orderDetails = useStateUpdate({});
  const isAuthorized = useStateUpdate(true);
  const isOrderFound = useStateUpdate(true);
  const activityLogs = useStateUpdate({});
  const filterValue = useStateUpdate("");

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    isNotEmpty(orderDetails.state) &&
      fetchActivityLogs(orderDetails.state.orderId);
  }, [orderDetails.state]);

  const fetchActivityLogs = async () => {
    var result = await getOrderActivityLogs(orderId);
    result.status === 200 && activityLogs.update(result.data.data);
  };

  const callSearchActivityLogs = async () => {
    var body = {
      search: filterValue.state,
      limit: 200,
    };
    var result = await searchOrderActivityLogs(orderId, body);
    result.status === 200 && activityLogs.update(result.data.data.records);
  };

  const fetchOrderDetails = async () => {
    var result = await getOrder(orderId);
    result.status === 200 && orderDetails.update(result.data.data);
    result.status === 401 && isAuthorized.update(false);
    result.status === 500 && isOrderFound.update(false);
  };

  return (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      {isAuthorized.state && isOrderFound.state ? (
        <Fragment>
          <Grid container mb={2}>
            <Grid item xs={6} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography variant="font20b">{"Order #: "}</Typography>
                {isNotEmpty(orderDetails.state) ? (
                  <Typography variant="font20b">
                    {orderDetails.state.orderNumber}
                  </Typography>
                ) : (
                  <Skeleton width={50} />
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              pb={1}
            >
              {isImage(orderDetails.state.signingAgencyLogoUrl) ? (
                isNotEmpty(orderDetails.state) ? (
                  <img
                    src={orderDetails.state.signingAgencyLogoUrl}
                    alt="agency-logo"
                    width={{ xs: 100, sm: 125, md: 150 }}
                  ></img>
                ) : (
                  <Skeleton width={50} />
                )
              ) : (
                <Stack direction="row" spacing={1}>
                  <Typography variant="font14b">Agency:</Typography>
                  {isNotEmpty(orderDetails.state) ? (
                    <Typography variant="font14">
                      {orderDetails.state.signingAgencyName}
                    </Typography>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </Stack>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              {isNotEmpty(orderDetails.state) && (
                <OrderInstructions orderDetails={orderDetails.state} />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <OrderInformation
                orderDetails={orderDetails.state}
                fetchOrderDetails={fetchOrderDetails}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                <Notaries orderDetails={orderDetails.state} />
                <Notes orderDetails={orderDetails.state} />
              </Stack>
            </Grid>
          </Grid>
          {isNotEmpty(activityLogs.state) && (
            <Grid container>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="font17b" sx={{ flexShrink: 0 }}>
                      Order activity log
                    </Typography>
                  </AccordionSummary>
                  <ActivityLog
                    activityLogs={activityLogs.state}
                    filterValue={filterValue}
                    callSearchLogs={callSearchActivityLogs}
                    fetchLogs={fetchActivityLogs}
                  />
                </Accordion>
              </Grid>
            </Grid>
          )}
        </Fragment>
      ) : (
        <Grid item xs={12} mt={5}>
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Alert severity={!isOrderFound.state ? "error" : "warning"}>
              {!isOrderFound.state && "Order not found!!!"}
              {!isAuthorized.state && "You are not allowed to view this order."}
            </Alert>
            <Button
              variant="text"
              color="secondary"
              onClick={() => navigate("/dashboard")}
            >
              DASHBOARD
            </Button>
          </Stack>
        </Grid>
      )}
    </Box>
  );
}

export default OrderDetails;
