import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Box,
  Typography,
  Stack,
  Divider,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
} from "@mui/material";
import { WARNING_YELLOW, WARNING_GREEN } from "components/common/Constant";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getProfileId } from "components/common/GetUserDetail";
import { useIsAdmin } from "components/common/GetUserDetail";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { useStateUpdate } from "UseStateUpdate";

function Notaries({ orderDetails }) {
  const isAdmin = useIsAdmin();
  const reasonCantComplete = useStateUpdate("");
  let navigate = useNavigate();

  useEffect(() => {
    isNotEmpty(orderDetails) &&
      reasonCantComplete.update(orderDetails.reasonCantComplete ?? false);
  }, [orderDetails]);

  const loadTable = () => {
    return (
      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {["Notary Name", "Rate", "Action", "Status"].map((heading) => (
                <TableCell key={heading}>
                  <Typography variant="font15b" color="#777" noWrap>
                    {heading}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isNotEmpty(orderDetails) &&
              orderDetails.notaries.map((notary, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {isAdmin ? (
                      <Link
                        href="#"
                        variant="font12"
                        color="secondary.light"
                        underline="none"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/profile/" + notary.profileId);
                        }}
                        noWrap
                      >
                        {notary.name}
                      </Link>
                    ) : (
                      <Typography variant="font12" noWrap>
                        {notary.name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="font12b">
                      {getProfileId() === notary.profileId || isAdmin
                        ? "$" + parseFloat(notary.fee).toFixed(2)
                        : "*****"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {getProfileId() === notary.profileId ? (
                        <Button
                          disabled={
                            !orderDetails.canCompleteOrder ||
                            orderDetails.status.toLowerCase() === "closed"
                          }
                          variant="contained"
                          size="small"
                          onClick={() => {
                            navigate(
                              "/orders/" +
                                orderDetails.orderId +
                                "/completion?profileId=" +
                                notary.profileId
                            );
                          }}
                        >
                          <Typography variant="font12b" noWrap>
                            Complete order
                          </Typography>
                        </Button>
                      ) : (
                        <Typography
                          variant="font12b"
                          color={
                            notary.completionStatus.toLowerCase() === "pending"
                              ? WARNING_YELLOW
                              : WARNING_GREEN
                          }
                        >
                          {notary.completionStatus.toLowerCase() === "pending"
                            ? "Pending"
                            : notary.completionStatus.toLowerCase() ===
                              "completed"
                            ? "Completed"
                            : "--"}
                        </Typography>
                      )}
                      {reasonCantComplete.state && (
                        <Tooltip
                          title={
                            <Stack>
                              <Typography variant="font12b">
                                {orderDetails.reasonCantComplete}
                              </Typography>
                            </Stack>
                          }
                          style={{
                            display:
                              getProfileId() === notary.profileId
                                ? orderDetails.canCompleteOrder
                                  ? "none"
                                  : "block"
                                : notary.completionStatus.toLowerCase() ===
                                  "pending"
                                ? "block"
                                : "none",
                          }}
                        >
                          <InfoOutlinedIcon fontSize="small" color="error" />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Box sx={{ width: { xs: "90vw", sm: "100%" } }} p={2}>
        <Stack spacing={2}>
          <Typography variant="font15b" color="#686868">
            Notaries
          </Typography>
          <Divider />
          {loadTable()}
        </Stack>
      </Box>
    </Paper>
  );
}

export default Notaries;
