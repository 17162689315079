import React, { Fragment, useEffect } from "react";

import {
  Grid,
  Paper,
  Typography,
  Box,
  Stack,
  Skeleton,
  Link,
  Tooltip,
} from "@mui/material";

import { useStateUpdate } from "UseStateUpdate";
import { getNotarySummary } from "service/AdminService";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function createFigures(title, value, type) {
  return { title, value, type };
}

const skeleton = () => {
  return (
    <Fragment>
      {[1, 2, 3, 4].map((index) => (
        <Grid key={index} item xs={6} sm={3}>
          <Paper variant="outlined">
            <Box p={1} pb={{ xs: 1, md: 4 }}>
              <Stack spacing={2}>
                <Skeleton animation="wave" variant="rectangular" />
                <Grid container pt={{ xs: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Fragment>
  );
};

function AdminNotariesSummary({ showNotaries, changeHeading }) {
  const figures = useStateUpdate([]);
  const expireCount = useStateUpdate(0);

  useEffect(() => {
    fetchNotarySummary();
  }, []);

  const fetchNotarySummary = async () => {
    var result = await getNotarySummary();
    if (result.status === 200) {
      figures.update([
        createFigures(
          "Active Notaries",
          result.data.data.activeCount,
          "active"
        ),
        createFigures(
          "Inactive Notaries",
          result.data.data.inActiveCount,
          "inactive"
        ),
      ]);
      expireCount.update(result.data.data.expireCount);
    }
  };

  return (
    <Grid container spacing={2}>
      {figures.state.length > 0 ? (
        <Fragment>
          {figures.state.map((figure) => (
            <Grid key={figure.title} container item xs={6} sm={3}>
              <Paper variant="outlined" sx={{ width: "100%" }}>
                <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
                  <Stack spacing={2}>
                    <Typography variant="font15b" color="#686868" noWrap>
                      {figure.title}
                    </Typography>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      pt={{ xs: 1, md: 2 }}
                    >
                      <Link
                        href=""
                        variant="font32b"
                        color="secondary.light"
                        underline="none"
                        onClick={(e) => {
                          e.preventDefault();
                          showNotaries.update(figure.type);
                          changeHeading(figure.title);
                        }}
                      >
                        {figure.value}
                      </Link>
                    </Grid>
                  </Stack>
                </Box>
              </Paper>
            </Grid>
          ))}
          <Grid container item xs={6} sm={3}>
            <Paper variant="outlined" sx={{ width: "100%" }}>
              <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="font15b" color="#686868">
                      Expired/Expiring soon
                    </Typography>
                    <Tooltip
                      title={
                        <Typography variant="font14">
                          Notaries with expired or expiring background checks in
                          the next 30 days
                        </Typography>
                      }
                    >
                      <InfoOutlinedIcon fontSize="small" color="secondary" />
                    </Tooltip>
                  </Stack>

                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    pt={{ xs: 1, md: 2 }}
                  >
                    <Link
                      href=""
                      variant="font32b"
                      color="secondary.light"
                      underline="none"
                      onClick={(e) => {
                        e.preventDefault();
                        showNotaries.update("bgexpired");
                        changeHeading("Expired/Expiring soon");
                      }}
                    >
                      {expireCount.state}
                    </Link>
                  </Grid>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid container item xs={6} sm={3}>
            <Paper variant="outlined" sx={{ width: "100%" }}>
              <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}></Box>
            </Paper>
          </Grid>
        </Fragment>
      ) : (
        skeleton()
      )}
    </Grid>
  );
}

export default AdminNotariesSummary;
