import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

import Register from "./sections/Register";
import VerifySecretCode from "./sections/VerifySecretCode";
import SetPassword from "./sections/SetPassword";
import AccountCreated from "./sections/AccountCreated";

import { LinearProgress } from "@mui/material";

import { postSecretCode } from "service/RegistrationService";
import Toast from "components/common/Toast";
import NNAProfileMatch from "./sections/NNAProfileMatch";
import ImportNNAProfile from "./sections/ImportNNAProfile";
import { checkNNAProfile } from "service/RegistrationService";

function Registration() {
  const search = useLocation().search;
  const regId = new URLSearchParams(search).get("regId");
  const code = new URLSearchParams(search).get("code");

  const registrationId = useStateUpdate("");
  const registerData = useStateUpdate(null);
  const isRegister = useStateUpdate(false);
  const isVerify = useStateUpdate(false);
  const isProfileMatched = useStateUpdate(false);
  const isFetchProfile = useStateUpdate(false);
  const isSetPassword = useStateUpdate(false);
  const isNnaProfileImported = useStateUpdate(false);
  const personGuid = useStateUpdate("");
  const isLoading = useStateUpdate(true);

  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const verifyError = useStateUpdate("");

  useEffect(() => {
    if (regId !== null && code !== null) {
      callSecretCode();
    } else isLoading.update(false);
  }, []);

  async function callSecretCode() {
    let secretCodeData = getSecretCodeData();
    var response = await postSecretCode(secretCodeData);

    if (response.status === 200) {
      if (response.data.code === 200) {
        callCheckNNAProfile(response.data.data.email);
        registrationId.update(secretCodeData.registrationId);
      } else {
        isLoading.update(false);
      }
    } else if (response.status === 400) {
      verifyError.update(response.data.message);
      callToast("error", response.data.message);
      isLoading.update(false);
    } else {
      verifyError.update(response.data.message);
      callToast("error", response.data.message);
      isLoading.update(false);
    }
  }

  const callCheckNNAProfile = async (registerEmail) => {
    var response = await checkNNAProfile(registerEmail);
    if (response.status === 200) {
      personGuid.update(response.data.data.personGuid);
      isProfileMatched.update(true);
    }
    isLoading.update(false);
    isVerify.update(true);
    isRegister.update(true);
  };

  const callToast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  function getSecretCodeData() {
    var secretCodeData = {
      registrationId: regId,
      secretCode: code,
    };
    return secretCodeData;
  }

  return (
    <React.StrictMode>
      {isLoading.state ? (
        <LinearProgress color="secondary" />
      ) : !isRegister.state ? (
        <Register
          setRegistrationId={registrationId.update}
          setRegisterData={registerData.update}
          setIsRegister={isRegister.update}
          verifyError={verifyError}
        />
      ) : !isVerify.state ? (
        <VerifySecretCode
          registrationId={registrationId.state}
          registerName={
            registerData.state.firstName +
            " " +
            registerData.state.middleName +
            " " +
            registerData.state.lastName
          }
          registerEmail={registerData.state.email}
          setIsProfileMatched={isProfileMatched.update}
          setIsVerify={isVerify.update}
          setPersonGuid={personGuid.update}
        />
      ) : isProfileMatched.state ? (
        <NNAProfileMatch
          setIsProfileMatched={isProfileMatched.update}
          setIsFetchProfile={isFetchProfile.update}
        />
      ) : isFetchProfile.state ? (
        <ImportNNAProfile
          setIsNnaProfileImported={isNnaProfileImported.update}
          setIsFetchProfile={isFetchProfile.update}
          personGuid={personGuid.state}
          registrationId={registrationId.state}
        />
      ) : !isSetPassword.state ? (
        <SetPassword
          registrationId={registrationId.state}
          setIsSetPassword={isSetPassword.update}
          isNnaProfileImported={isNnaProfileImported.state}
        />
      ) : (
        <AccountCreated />
      )}
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </React.StrictMode>
  );
}

export default Registration;
