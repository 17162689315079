export const formatPhoneNumber = (phoneNum) => {
  var input = phoneNum ? phoneNum : "";
  if (input[0] === "+") {
    input = input.substring(input.length - 10);
  }
  input = input.replace(/\D/g, "").substring(0, 10);
  if (input === "") return input;
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    return `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    return `(${zip}`;
  }
};

export const appendCountryCode = (phoneNum) => {
  if (phoneNum === "") return phoneNum;
  const input = "+1" + phoneNum.replace(/\D/g, "").substring(0, 10);
  return input;
};
