import React, { useState } from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";

import { postSecretCode } from "service/RegistrationService";
import { resendOtp } from "service/AuthService";
import Toast from "components/common/Toast";
import { checkNNAProfile } from "service/RegistrationService";

function VerifySecretCode(props) {
  const {
    setIsVerify,
    setIsProfileMatched,
    registrationId,
    registerName,
    registerEmail,
    setPersonGuid,
  } = props;
  const isLoader = useStateUpdate(false);
  const errorMsg = useStateUpdate("");

  const [verificationCode, setVerificationCode] = useState({
    codeIndex1: "",
    codeIndex2: "",
    codeIndex3: "",
    codeIndex4: "",
  });
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  async function callSecretCode(e) {
    e.preventDefault();
    isLoader.update(true);
    let secretCodeData = getSecretCodeData();
    var response = await postSecretCode(secretCodeData);
    response.status === 200
      ? response.data.code === 200
        ? callCheckNNAProfile()
        : response.data.code === 422
        ? errorMsg.update(response.data.error.secretCode[0])
        : errorMsg.update("some error")
      : errorMsg.update("some error");
    isLoader.update(false);
  }

  const callCheckNNAProfile = async () => {
    var response = await checkNNAProfile(registerEmail);
    if (response.status === 200) {
      setPersonGuid(response.data.data.personGuid);
      setIsProfileMatched(true);
    }
    isLoader.update(true);
    setIsVerify(true);
  };

  async function callResendOtp(e) {
    e.preventDefault();
    var response = await resendOtp({ email: registerEmail });
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
  }

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const formatEmail = (email) => {
    return (
      email.substring(0, 3) +
      "**" +
      email.substring(email.indexOf("@"), email.indexOf("@") + 3) +
      "**"
    );
  };

  function getSecretCodeData() {
    var secretCodeData = {
      registrationId: registrationId,
      secretCode:
        verificationCode.codeIndex1 +
        verificationCode.codeIndex2 +
        verificationCode.codeIndex3 +
        verificationCode.codeIndex4,
    };
    return secretCodeData;
  }

  function codeTextFeild(index) {
    return (
      <TextField
        autoFocus={index === 1}
        key={index}
        name={"codeIndex-" + index}
        variant="outlined"
        color="secondary"
        value={
          index === 1
            ? verificationCode.codeIndex1
            : index === 2
            ? verificationCode.codeIndex2
            : index === 3
            ? verificationCode.codeIndex3
            : verificationCode.codeIndex4
        }
        onChange={handleChange}
        style={{ width: "40px" }}
        inputProps={{ maxLength: 1 }}
      />
    );
  }

  function handleChange(e) {
    const { value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    if (value !== " ") {
      let siblingIncrement = 1;
      value === "" ? (siblingIncrement = -1) : (siblingIncrement = 1);
      const nextSibling = document.querySelector(
        `input[name=codeIndex-${parseInt(fieldIndex, 10) + siblingIncrement}]`
      );
      nextSibling !== null && nextSibling.focus();
      setVerificationCode({
        ...verificationCode,
        [`codeIndex${fieldIndex}`]: value,
      });
    }
  }

  return (
    <Container maxWidth="md">
      <Box
        mt={{ xs: 10, sm: 6, md: 5 }}
        p={5}
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={11} mt={4}>
          <Typography variant="font20b">Welcome {registerName}</Typography>
          <Typography variant="body2" color="#686868">
            <br></br>
            Thanks for registering with us. Before proceeding we need to verify
            your email id.<br></br>An unique code has been sent to your email
            {" " + formatEmail(registerEmail)}.<br></br>
            <br></br>Please enter the unique code here and press Verify and
            Continue
          </Typography>
        </Grid>
        <Grid item xs={10} md={6} mt={6}>
          <Stack spacing={2}>
            <Typography variant="body2" color="#686868">
              Unique verification code*
            </Typography>
            <Stack direction="row" spacing={2}>
              {[1, 2, 3, 4].map((index) => codeTextFeild(index))}
            </Stack>
            <Typography variant="body2" color="red">
              {errorMsg.state}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={8} my={4}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Button
              variant="contained"
              disabled={isLoader.state}
              onClick={(e) => {
                callSecretCode(e);
              }}
            >
              <Typography variant="buttonLabel">Verify and Continue</Typography>
              {isLoader.state && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    marginLeft: "-10px",
                  }}
                />
              )}
            </Button>
            <Typography variant="font12">
              {"Didn't receive the code "}
              <b>
                <Link
                  href="#"
                  color="#000000"
                  underline="none"
                  onClick={callResendOtp}
                >
                  Click here to resend
                </Link>
              </b>
            </Typography>
          </Stack>
        </Grid>
      </Box>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Container>
  );
}

export default VerifySecretCode;
