import { authFetch } from "service/AuthProvider";

const SERVICE_URL = "/template-service/v1/templates/";

export const getTemplates = async () => {
  let url = SERVICE_URL + "list";
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getNoteTemplates = async (templateId) => {
  let url = SERVICE_URL + templateId + "/note-templates";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createNoteTemplates = async (templateId, body) => {
  let url = SERVICE_URL + templateId + "/note-template";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateNoteTemplates = async (templateId, noteTemplateId, body) => {
  let url = SERVICE_URL + templateId + "/note-templates/" + noteTemplateId;
  return await authFetch("put", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteNoteTemplates = async (templateId, noteTemplateId) => {
  let url = SERVICE_URL + templateId + "/note-templates/" + noteTemplateId;
  return await authFetch("delete", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
