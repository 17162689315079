import React from "react";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { leaveOrganization } from "service/OrganizationService";
import Toast from "components/common/Toast";
import { useStateUpdate } from "UseStateUpdate";
import ConfirmationModal from "components/common/ConfirmationModal";

function OtherOrganization({
  organization,
  profileId,
  fetchOrganizationMembersList,
  isAdminView,
}) {
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const modalIcon = useStateUpdate(null);
  const modalHeading = useStateUpdate("");
  const modalMessage = useStateUpdate("");
  const modalApprove = useStateUpdate("Remove");
  const modalCancel = useStateUpdate("Cancel");
  const openModal = useStateUpdate(false);
  const modalType = useStateUpdate(null);

  const callLeaveOrganization = async () => {
    var response = await leaveOrganization(
      organization.organizationId,
      profileId
    );
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);
    fetchOrganizationMembersList();
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Paper variant="outlined">
      <ConfirmationModal
        open={openModal}
        type={modalType.state}
        onConfirm={callLeaveOrganization}
        icon={modalIcon.state}
        heading={modalHeading.state}
        message={modalMessage.state}
        approveLabel={modalApprove.state}
        cancelLabel={modalCancel.state}
      />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="font20b" noWrap>
              {organization.organization + "'s Organization"}
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={6}
            justifyContent={{ xs: "center", sm: "flex-end" }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                openModal.update(true);
                modalIcon.update("warning");
                modalHeading.update(
                  isAdminView
                    ? "Remove from Organization"
                    : "Leave Organization"
                );
                modalMessage.update(
                  (isAdminView
                    ? "Are you sure to remove from "
                    : "Are you sure to leave ") +
                    organization.organization.toUpperCase() +
                    " Organization?"
                );
                modalApprove.update("Leave");
                modalCancel.update("Cancel");
              }}
            >
              <Typography variant="buttonLabel" color="#000" noWrap>
                {isAdminView
                  ? "Remove from Organization"
                  : "Leave Organization"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>{" "}
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Paper>
  );
}

export default OtherOrganization;
