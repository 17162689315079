import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import AddNoteToTemplate from "./sections/AddNoteToTemplate";
import NoteTemplatesList from "./sections/NoteTemplatesList";
import { getTemplates } from "service/TemplatesService";
import { useStateUpdate } from "UseStateUpdate";
import { getNoteTemplates } from "service/TemplatesService";

function NoteTemplates() {
  const templates = useStateUpdate([]);
  const expanded = useStateUpdate(null);
  const noteTemplateForEdit = useStateUpdate(null);
  const noteTemplates = useStateUpdate([]);
  const currentTemplateId = useStateUpdate(null);
  const editTemplate = useStateUpdate(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    var result = await getTemplates();
    result.status === 200 && templates.update(result.data.data.records);
  };

  const fetchNoteTemplates = async (templateId) => {
    var result = await getNoteTemplates(
      templateId ? templateId : currentTemplateId.state
    );
    result.status === 200 && noteTemplates.update(result.data.data.records);
  };

  return (
    <Box mx={{ xs: 2, sm: 3 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Note Templates</Typography>
      </Grid>

      <Grid container spacing={2} pr={{ md: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          {editTemplate.state ? (
            <AddNoteToTemplate
              templates={templates.state}
              expanded={expanded}
              editTemplate={editTemplate}
              noteTemplate={noteTemplateForEdit.state}
              fetchNoteTemplates={fetchNoteTemplates}
            />
          ) : (
            <AddNoteToTemplate
              templates={templates.state}
              expanded={expanded}
              editTemplate={editTemplate}
              fetchNoteTemplates={fetchNoteTemplates}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <NoteTemplatesList
            templates={templates.state}
            expanded={expanded}
            noteTemplates={noteTemplates}
            setEditNoteTemplate={noteTemplateForEdit.update}
            currentTemplateId={currentTemplateId}
            editTemplate={editTemplate}
            fetchNoteTemplates={fetchNoteTemplates}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default NoteTemplates;
