import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//materialUi
import {
  Paper,
  Typography,
  Box,
  Stack,
  Tabs,
  Tab,
  Divider,
  Grid,
  Button,
  Skeleton,
} from "@mui/material";
import PropTypes from "prop-types";
import { StyledBadge } from "components/common/Styles";
import OrderTable from "./OrderTable";
import { useStateUpdate } from "UseStateUpdate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: { xs: "90vw", md: "100%" } }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function OrdersSections(props) {
  let navigate = useNavigate();
  const { section } = props;
  const value = useStateUpdate(0);
  const handleChange = (event, newValue) => {
    value.update(newValue);
  };

  useEffect(() => {
    section.forEach((data) => {
      data.label === "Pending signings" &&
        data.datas.length === 0 &&
        value.update(1);
    });
  }, [section]);

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        {section.length > 0 ? (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value.state}
                textColor="secondary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
              >
                {section.map((tabIs, index) => (
                  <Tab
                    key={index}
                    label={
                      <Fragment>
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant={
                              value.state === index ? "font17b" : "font17"
                            }
                          >
                            {tabIs.label}
                          </Typography>
                          <StyledBadge
                            badgeContent={tabIs.badge}
                            color="error"
                          ></StyledBadge>
                        </Stack>
                      </Fragment>
                    }
                    id={`simple-tab-${index}`}
                  />
                ))}
              </Tabs>
            </Box>
            {section.map((dataIs, index) => (
              <TabPanel key={index} value={value.state} index={index}>
                <OrderTable
                  tableRows={dataIs.datas}
                  buttonColumn={dataIs.haveButton}
                  label={dataIs.label}
                  pageCount={dataIs.pageCount}
                  page={dataIs.page}
                  {...props}
                />
              </TabPanel>
            ))}
            <Divider />
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              item
              px={4}
            >
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  navigate("/orders");
                }}
              >
                <Typography variant="font12">View all</Typography>
              </Button>
            </Grid>
          </>
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        )}
      </Box>
    </Paper>
  );
}

export default OrdersSections;
