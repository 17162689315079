import React, { useEffect, useMemo, Fragment } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Link,
  Stack,
  Typography,
  Box,
  Grid,
  IconButton,
  AccordionSummary,
  Accordion,
  Paper,
  Button,
  FormControl,
  MenuItem,
  Select,
  Alert,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import BasicDetails from "./basic_details/BasicDetails";
import PieChart from "./pie_chart/PieChart";
import PersonalDetails from "./personal_details/PersonalDetails";
import Credentials from "./credentials/Credentials";
import ActivityLog from "../common/ActivityLog";

import { getProfileDatas } from "service/ProfileService";
import { iconButton } from "components/common/Styles";
import { calculatePercentage } from "./calculatePercentage";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { getProfileId } from "components/common/GetUserDetail";
import { getActivityLogs } from "service/ProfileService";
import { useIsAdmin } from "components/common/GetUserDetail";
import UserRatingAndBans from "./user_rating_bans/UserRatingAndBans";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { deleteUser } from "service/AdminService";
import Toast from "components/common/Toast";
import ConfirmationModal from "components/common/ConfirmationModal";
import { updateStatus } from "service/AdminService";
import { masqueradeUser } from "service/AdminService";
import { searchActivityLogs } from "service/ProfileService";
import NotaryTags from "./notary_tags/NotaryTags";
import ProfileOrdersView from "./order_view/ProfileOrdersView";

export const createPanels = (label, expand, component, percentageCompleted) => {
  return { label, expand, component, percentageCompleted };
};

function Profile() {
  let navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const { profileId } = useParams();
  const profileIdIs = useStateUpdate(profileId ? profileId : getProfileId());
  const isAdminUserProfile = useStateUpdate(false);
  const profileDatas = useStateUpdate({});
  const notaryName = useStateUpdate("");
  const status = useStateUpdate("active");
  const expanded = useStateUpdate("panelPersonalDetails");
  const percentageCompleted = useStateUpdate({
    basicProfile: {
      percentage: 0,
      toComplete: [],
    },
    personalDetails: {
      percentage: 0,
      toComplete: [],
    },
    credentials: {
      percentage: 0,
      toComplete: [],
    },
  });
  const activityLogs = useStateUpdate([]);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const isUserFound = useStateUpdate(true);
  const openModal = useStateUpdate(false);
  const modalType = useStateUpdate(null);
  const modalIcon = useStateUpdate(null);
  const modalHeading = useStateUpdate(null);
  const modalMessage = useStateUpdate(null);
  const modalApprove = useStateUpdate(null);
  const modalCancel = useStateUpdate(null);
  const filterValue = useStateUpdate("");

  const handleChange = (panel) => (event, newExpanded) => {
    expanded.update(newExpanded ? panel : false);
  };

  useEffect(() => {
    profileIdIs.update(profileId ? profileId : getProfileId());
  }, [profileId]);

  useEffect(() => {
    fetchProfileData();
  }, [profileIdIs.state]);

  const checkPercentage = (data) => {
    const profiledata = data ? data : profileDatas.state;
    if (isNotEmpty(profiledata) && !isAdminUserProfile.state) {
      var percentageCompletedIs = calculatePercentage(profiledata);
      percentageCompleted.update(percentageCompletedIs);
      var percentage =
        percentageCompletedIs.basicProfile.percentage * 0.2 +
        percentageCompletedIs.personalDetails.percentage * 0.4 +
        percentageCompletedIs.credentials.percentage * 0.4;
      return percentage;
    }
  };

  const fetchProfileData = async () => {
    var result = await getProfileDatas(profileIdIs.state);
    if (result.status === 200) {
      var data = result.data.data;
      data = {
        ...data,
        profileId: profileIdIs.state,
      };
      notaryName.update(
        (data.firstName ?? "") +
          " " +
          (data.middleName ?? "") +
          " " +
          (data.lastName ?? "")
      );
      profileDatas.update(data);
      isAdminUserProfile.update(data.isAdmin);
      status.update(data.status);
      checkPercentage(data);
      fetchActivityLogs();
    } else {
      isUserFound.update(false);
    }
  };

  const fetchActivityLogs = async () => {
    var result = await getActivityLogs(profileIdIs.state);
    result.status === 200 && activityLogs.update(result.data.data);
  };

  const callSearchActivityLogs = async () => {
    var body = {
      search: filterValue.state,
      limit: 200,
    };
    var result = await searchActivityLogs(profileIdIs.state, body);
    result.status === 200 && activityLogs.update(result.data.data.records);
  };

  const callDeleteUser = async () => {
    var response = await deleteUser(profileIdIs.state);
    if (response.status === 200) {
      calltoast("success", response.data.message);
      navigate("/dashboard/manage-notaries");
    } else {
      calltoast("error", response.data.message);
    }
  };

  const callUpdateStatus = async () => {
    var body = {
      status: status.state,
    };
    var response = await updateStatus(profileIdIs.state, body);
    response.status === 200
      ? calltoast("success", response.data.message)
      : calltoast("error", response.data.message);

    openModal.update(false);
  };

  const callConfirm = (type) => {
    type === "deleteUser" && callDeleteUser();
    type === "changeStatus" && callUpdateStatus();
    type === "masqueradeUser" && masqueradeToNotary();
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const handleChangeStatus = (e) => {
    status.update(e.target.value);
    openModal.update(true);
    modalType.update("changeStatus");
    modalIcon.update("warning");
    modalHeading.update("Are you sure?");
    modalMessage.update(
      "Do you really want to change the status of this user?"
    );
    modalApprove.update("Change");
    modalCancel.update("Cancel");
  };

  const handleChangeDelete = () => {
    openModal.update(true);
    modalType.update("deleteUser");
    modalIcon.update("error");
    modalHeading.update("Are you sure?");
    modalMessage.update("Do you really want to delete this user?");
    modalApprove.update("Delete");
    modalCancel.update("Cancel");
  };

  const handleMasqueradeClick = async () => {
    openModal.update(true);
    modalType.update("masqueradeUser");
    modalIcon.update("warning");
    modalHeading.update("Are you sure to Masquerade?");
    modalMessage.update(
      "Do you really want to switch to " +
        notaryName.state.toUpperCase() +
        "'s profile?"
    );
    modalApprove.update("Masquerade");
    modalCancel.update("Cancel");
  };

  const masqueradeToNotary = async () => {
    openModal.update(false);
    const adminId = getProfileId();
    var result = await masqueradeUser(profileIdIs.state);
    if (result.status === 200) {
      localStorage.setItem("adminId", adminId);
      navigate("/profile");
    }
  };

  const profileValue = useMemo(
    () => ({
      profileDatas: profileDatas.state,
      percentageCompleted,
      fetchProfileData,
      activityLogs: activityLogs.state,
    }),
    [profileDatas.state, percentageCompleted]
  );

  const getPanels = () => {
    let panels = [];
    panels.push(
      createPanels(
        "Personal details",
        "panelPersonalDetails",
        <PersonalDetails
          isUser={isAdminUserProfile.state}
          checkPercentage={checkPercentage}
        />,
        percentageCompleted.state.personalDetails.percentage
      )
    );
    !isAdminUserProfile.state &&
      panels.push(
        createPanels(
          "Credentials",
          "panelCredentials",
          <Credentials checkPercentage={checkPercentage} />,
          percentageCompleted.state.credentials.percentage
        )
      );
    !isAdminUserProfile.state &&
      isAdmin &&
      panels.push(createPanels("Orders", "panelOrders", <ProfileOrdersView />));
    isAdmin &&
      panels.push(
        createPanels(
          "Tags",
          "notaryTags",
          <NotaryTags
            profileId={profileDatas.state.profileId}
            fetchActivityLogs={fetchActivityLogs}
          />
        )
      );
    panels.push(
      createPanels(
        "User activity log",
        "panelUserActivityLog",
        <ActivityLog
          activityLogs={activityLogs.state}
          filterValue={filterValue}
          callSearchLogs={callSearchActivityLogs}
          fetchLogs={fetchActivityLogs}
        />
      )
    );

    return panels;
  };

  const UserNotFound = () => {
    return (
      <Grid item xs={12} mt={5}>
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <Alert severity="error">User not found!!!</Alert>
          <Button
            variant="text"
            color="secondary"
            onClick={() => navigate("/dashboard/manage-notaries")}
          >
            DASHBOARD
          </Button>
        </Stack>
      </Grid>
    );
  };

  const AdminTopSection = () => {
    return (
      <Paper variant="outlined">
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} container justifyContent="flex-start">
              <Stack direction="row" spacing={1}></Stack>
            </Grid>
            <Grid item xs={6} sm={4} container justifyContent="center">
              <FormControl color="secondary" variant="standard" size="small">
                <Select value={status.state} onChange={handleChangeStatus}>
                  <MenuItem value="active">
                    <Stack direction="row" spacing={1}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ color: "#84D434" }}
                      />
                      <Typography variant="font13">Active</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value="inactive">
                    <Stack direction="row" spacing={1}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />
                      <Typography variant="font13">Inactive</Typography>
                    </Stack>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              container
              justifyContent={{ xs: "center", sm: "flex-end" }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleMasqueradeClick}
                >
                  <Typography variant="font14" color="#000" noWrap>
                    Masquerade
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleChangeDelete}
                >
                  <Typography variant="font14" color="#000" noWrap>
                    Delete user
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  };

  const NotaryTopSection = () => {
    return (
      <Grid>
        <Grid item xs>
          <Typography variant="font20b">Profile</Typography>
          <Typography variant="font20" color="#686868">
            {"Welcome " + notaryName.state}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <ProfileContext.Provider value={profileValue}>
      <ConfirmationModal
        open={openModal}
        type={modalType.state}
        onConfirm={callConfirm}
        icon={modalIcon.state}
        heading={modalHeading.state}
        message={modalMessage.state}
        approveLabel={modalApprove.state}
        cancelLabel={modalCancel.state}
      />
      <Container maxWidth="lg">
        <Box mt={5} pb={5}>
          {isUserFound.state ? (
            <Stack spacing={3}>
              {isNotEmpty(profileDatas.state) && (
                <Fragment>
                  {isAdmin ? (
                    isAdminUserProfile.state ? (
                      profileDatas.state.profileId === getProfileId() && (
                        <NotaryTopSection />
                      )
                    ) : (
                      <AdminTopSection />
                    )
                  ) : (
                    <NotaryTopSection />
                  )}
                  <Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={isAdminUserProfile.state ? 12 : 8}>
                        <BasicDetails checkPercentage={checkPercentage} />
                      </Grid>
                      {!isAdminUserProfile.state && (
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justifyContent="center"
                          alignItems="stretch"
                        >
                          {isAdmin ? (
                            <UserRatingAndBans />
                          ) : (
                            <PieChart percentage={percentageCompleted.state} />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {getPanels().map((panel, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded.state === panel.expand}
                      onChange={handleChange(panel.expand)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded.state === panel.expand ? (
                            <IconButton sx={iconButton.border}>
                              <RemoveIcon />
                            </IconButton>
                          ) : (
                            <IconButton sx={iconButton.border}>
                              <AddIcon />
                            </IconButton>
                          )
                        }
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          px={2}
                        >
                          <Typography variant="font17b" sx={{ flexShrink: 0 }}>
                            {panel.label}
                          </Typography>
                          {panel.percentageCompleted ? (
                            <Typography variant="font15" color="primary">
                              {panel.percentageCompleted + "% Complete"}
                            </Typography>
                          ) : null}
                        </Grid>
                      </AccordionSummary>
                      {panel.component}
                    </Accordion>
                  ))}
                </Fragment>
              )}
            </Stack>
          ) : (
            <UserNotFound />
          )}
        </Box>
      </Container>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </ProfileContext.Provider>
  );
}

export default Profile;
