import React, { useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks(props) {
  const {
    options,
    selectedOptions,
    setSelectedOptions,
    isDisabled,
    isButtonDisabled,
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [optionsAre, setOptionsAre] = useState([]);

  useEffect(() => {
    var tempOptions = [];
    options.forEach((option) => {
      tempOptions.push(option.name);
    });
    setOptionsAre(tempOptions);
  }, [options]);

  useEffect(() => {
    var tempOptions = [];
    selectedOptions.forEach((option) => {
      tempOptions.push(option.name);
    });
    setSelectedItems(tempOptions);
  }, [selectedOptions]);

  const handleChange = (event) => {
    isButtonDisabled && isButtonDisabled.update(false);
    const {
      target: { value },
    } = event;

    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedItems(selected);
    var tempOptions = [];
    selected.forEach((optionSelected) => {
      options.forEach((option) => {
        if (optionSelected === option.name) {
          tempOptions.push(option);
        }
      });
    });
    setSelectedOptions(tempOptions);
  };

  // const handleBlur = (event) => {
  //   var tempOptions = [];
  //   selectedItems.forEach((optionSelected) => {
  //     options.forEach((option) => {
  //       if (optionSelected === option.name) {
  //         tempOptions.push(option);
  //       }
  //     });
  //   });
  //   setSelectedOptions(tempOptions);
  // };

  return (
    <div>
      <FormControl
        variant={props.variant}
        size={props.size}
        color="secondary"
        fullWidth
        disabled={isDisabled}
      >
        <InputLabel error={props.error}>{props.label}</InputLabel>
        <Select
          multiple
          error={props.error}
          value={selectedItems}
          onChange={handleChange}
          label={props.label}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {optionsAre.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedItems.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
