import React from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { postPasswords } from "service/RegistrationService";

import { isValidPassword } from "components/common/FormValidator";

function SetPassword({
  setIsSetPassword,
  registrationId,
  isNnaProfileImported,
}) {
  const isLoader = useStateUpdate(false);
  const password = useStateUpdate("");
  const showPassword = useStateUpdate(false);
  const reEnterPassword = useStateUpdate("");
  const showReEnterPassword = useStateUpdate(false);
  const passwordHelperText = useStateUpdate(
    "Password must contain minimum 8 characters"
  );
  const validationError = useStateUpdate({
    password: false,
    reEnterPassword: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      password: false,
      reEnterPassword: "",
    };
    if (!isValidPassword(reEnterPassword.state)) {
      newValidationError = {
        ...newValidationError,
        reEnterPassword: "Password must contain minimum 8 characters",
      };
      isValidated = false;
    }
    if (!isValidPassword(password.state)) {
      newValidationError = {
        ...newValidationError,
        password: true,
      };
      isValidated = false;
    } else if (password.state !== reEnterPassword.state) {
      newValidationError = {
        ...newValidationError,
        reEnterPassword: "Password not match",
      };
      isValidated = false;
    }

    validationError.update(newValidationError);
    return isValidated;
  }

  async function callSetNewPassword(e) {
    isLoader.update(true);
    e.preventDefault();
    var passwordData = getPasswordData();
    var response = await postPasswords(passwordData);
    response.status === 200 &&
      response.data.code === 200 &&
      setIsSetPassword(true);

    isLoader.update(false);
  }

  function getPasswordData() {
    var passwordData = {
      registrationId: registrationId,
      password: password.state,
      confirmPassword: reEnterPassword.state,
    };
    return passwordData;
  }

  return (
    <Container maxWidth="md">
      <Box
        mt={{ xs: 10, sm: 6, md: 5 }}
        p={5}
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={11} mt={4}>
          <Stack spacing={2}>
            <Typography variant="font20b">Congratulations</Typography>
            <Typography variant="font14" color="#686868">
              {isNnaProfileImported
                ? "Your email has been successfully verified and NNA profile details successfully imported."
                : "Your email has been successfully verified."}
            </Typography>
            <Typography variant="font14" color="#686868">
              <b>Please secure your account with a sign in password.</b>
              <br></br>
              Please note, your email id will be your user id.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={10} md={6} mt={6}>
          <Stack spacing={2}>
            <FormControl variant="standard" color="secondary">
              <InputLabel error={validationError.state.password ? true : false}>
                Password*
              </InputLabel>
              <Input
                id="password"
                error={validationError.state.password ? true : false}
                type={showPassword.state ? "text" : "password"}
                value={password.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    password: false,
                  });
                  password.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => showPassword.update(!showPassword.state)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword.state ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={validationError.state.password ? true : false}
                id="standard-weight-helper-text"
              >
                {passwordHelperText.state}
              </FormHelperText>
            </FormControl>
            <FormControl variant="standard" color="secondary">
              <InputLabel
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
              >
                Re enter Password*
              </InputLabel>
              <Input
                id="re-enter-password"
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
                type={showReEnterPassword.state ? "text" : "password"}
                value={reEnterPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    reEnterPassword: "",
                  });
                  reEnterPassword.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        showReEnterPassword.update(!showReEnterPassword.state)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showReEnterPassword.state ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
                id="standard-weight-helper-text"
              >
                {validationError.state.reEnterPassword}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={8} my={4}>
          <Button
            variant="contained"
            disabled={isLoader.state}
            onClick={(e) => {
              checkValidatorOnSubmit() && callSetNewPassword(e);
            }}
          >
            <Typography variant="buttonLabel" mx={5}>
              Save
            </Typography>
            {isLoader.state && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  marginLeft: "-10px",
                }}
              />
            )}
          </Button>
        </Grid>
      </Box>
    </Container>
  );
}

export default SetPassword;
