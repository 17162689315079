import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import { formattedDate } from "components/common/GetDateAndTime";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { useStateUpdate } from "UseStateUpdate";
import { getNotifications } from "service/ProfileService";
import { getProfileId } from "components/common/GetUserDetail";
import { isNotEmpty } from "components/common/IsEmptyObject";
import ShowItemsPerPage from "components/common/ShowItemsPerPage";
import { useNavigate } from "react-router-dom";
import { useIsNotary } from "components/common/GetUserDetail";

function Notifications() {
  let navigate = useNavigate();
  const isNotary = useIsNotary();
  const readStatus = useStateUpdate("unread");
  const perPage = useStateUpdate(10);
  const page = useStateUpdate("1");
  const pageCount = useStateUpdate(1);
  const notifications = useStateUpdate([]);
  const pagination = useStateUpdate({});
  const currentRead = useStateUpdate(null);

  useEffect(() => {
    !isNotary && navigate("/dashboard");
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [readStatus.state, perPage.state, page.state]);

  useEffect(() => {
    if (isNotEmpty(pagination.state)) {
      pageCount.update(pagination.state.total_pages);
      pagination.state.count === 0 && page.update(pagination.state.total_pages);
    }
  }, [pagination.state]);

  const fetchNotifications = async () => {
    var result = await getNotifications(
      getProfileId(),
      readStatus.state,
      perPage.state,
      page.state
    );
    if (result.status === 200) {
      notifications.update(result.data.data.records);
      pagination.update(result.data.data.pagination);
    }
  };

  const readStatusDropDown = () => {
    return (
      <Stack direction="row" spacing={1}>
        <Typography variant="font15b">Filter:</Typography>
        <FormControl color="secondary" variant="standard" size="small">
          <Select
            value={readStatus.state}
            onChange={(e) => readStatus.update(e.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"read"}>Read</MenuItem>
            <MenuItem value={"unread"}>Unread</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    );
  };

  const tableHeading = () => {
    return (
      <Hidden smDown>
        <TableHead>
          <TableRow>
            <TableCell size="medium" width={"30%"}>
              <Typography variant="font15b">Title</Typography>
            </TableCell>
            <TableCell width={"50%"}>
              <Typography variant="font15b">Message</Typography>
            </TableCell>
            <TableCell width={"20%"}>
              <Typography variant="font15b">Date</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Hidden>
    );
  };

  const tableBody = () => {
    return (
      <TableBody>
        {notifications.state.map((notification, index) => (
          <TableRow
            key={index}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => {
              currentRead.update(
                currentRead.state === notification.id ? null : notification.id
              );
            }}
          >
            {mobileView(notification)}
            {nonMobileView(notification)}
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={3} size="medium">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Pagination
                count={pageCount.state}
                showFirstButton
                showLastButton
                size="small"
                onChange={(e, value) => {
                  page.update(value);
                }}
              />
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const noNotifications = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={3} size="medium">
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="font20">
                {"No " +
                  (readStatus.state === "all" ? "" : readStatus.state) +
                  " notifications"}
              </Typography>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const mobileView = (notification) => {
    return (
      <Hidden smUp>
        <TableCell>
          <Stack spacing={1}>
            {title(notification)}
            <Grid container justifyContent="flex-end" alignItems="center">
              {date(notification)}
            </Grid>
            {message(notification)}
          </Stack>
        </TableCell>
      </Hidden>
    );
  };

  const nonMobileView = (notification) => {
    return (
      <Hidden smDown>
        <TableCell>{title(notification)}</TableCell>
        <TableCell>{message(notification)}</TableCell>
        <TableCell>{date(notification)}</TableCell>
      </Hidden>
    );
  };

  const title = (notification) => {
    const title = notification.data.title ?? "";
    return (
      <Stack spacing={1} direction="row">
        {notification.hasRead ? (
          <DraftsOutlinedIcon />
        ) : (
          <MarkunreadOutlinedIcon />
        )}
        <Typography variant={notification.hasRead ? "font15" : "font15b"}>
          {currentRead.state === notification.id
            ? title
            : title.substring(0, 25) + (title.length > 25 ? "..." : "")}
        </Typography>
      </Stack>
    );
  };

  const message = (notification) => {
    const message = notification.data.message ?? "";
    return (
      <Typography variant="font15">
        {currentRead.state === notification.id
          ? message
          : message.substring(0, 100) + (message.length > 100 ? "..." : "")}
      </Typography>
    );
  };

  const date = (notification) => {
    return (
      <Typography variant="font15">
        {formattedDate(notification.date)}
      </Typography>
    );
  };

  return (
    <Box mx={{ xs: 2, sm: 3 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Notifications</Typography>
      </Grid>

      <Grid container>
        <Grid item xs={12} pr={{ md: 5 }}>
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 7 }}
            justifyContent="flex-end"
            pb={1}
          >
            <ShowItemsPerPage perPage={perPage} items={[10, 20, 50, 100]} />
            {readStatusDropDown()}
          </Stack>

          <TableContainer component={Paper}>
            <Table size="small">
              {tableHeading()}
              {notifications.state.length > 0 ? tableBody() : noNotifications()}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Notifications;
