import { authFetch } from "service/AuthProvider";

const SERVICE_URL = "/order-service/v1/orders/";

export const getOrder = async (orderId) => {
  let url = SERVICE_URL + "get-order/" + orderId;
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateOrder = async (orderId, data) => {
  let url = SERVICE_URL + orderId;
  return await authFetch("patch", url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const acceptOrder = async (orderId, body) => {
  let url = SERVICE_URL + orderId + "/accept";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const declineOrder = async (orderId, body) => {
  let url = SERVICE_URL + orderId + "/decline";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const searchOrder = async (params, perPage, page) => {
  let url =
    SERVICE_URL +
    "search?perPage=" +
    perPage +
    "&page=" +
    page +
    "&scheduledStatus=" +
    params.scheduledStatus +
    "&assignedStatus=" +
    params.assignedStatus +
    "&signerName=" +
    params.signerName +
    "&signerEmail=" +
    params.signerEmail +
    "&signingAgencyName=" +
    params.signingAgencyName +
    "&orderNumber=" +
    params.orderNumber +
    "&fileNumber=" +
    params.fileNumber +
    "&receivedStartDate=" +
    params.receivedStartDate +
    "&receivedEndDate=" +
    params.receivedEndDate +
    "&trackingNumber=" +
    params.trackingNumber +
    "&scheduler=" +
    params.scheduler +
    "&signingStartDate=" +
    params.signingStartDate +
    "&signingEndDate=" +
    params.signingEndDate +
    "&streetAddress=" +
    params.streetAddress +
    "&city=" +
    params.city +
    "&state=" +
    params.state +
    "&county=" +
    params.county +
    "&zipCode=" +
    params.zipCode +
    "&notaryOrderstatus=" +
    params.notaryOrderstatus +
    "&orderStatus=" +
    params.orderStatus +
    "&signerPhoneNumber=" +
    params.signerPhoneNumber +
    "&view-all-orders=" +
    params.viewAllOrders;
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getOrderActivityLogs = async (orderId) => {
  let url = SERVICE_URL + "get-activity/" + orderId + "/activities";
  return await authFetch("get", url, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const completeOrder = async (orderId, body) => {
  let url = SERVICE_URL + orderId + "/complete";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const addOrderAddress = async (orderId, body) => {
  let url = SERVICE_URL + orderId + "/address";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const searchOrderActivityLogs = async (orderId, body) => {
  let url = SERVICE_URL + orderId + "/activities-search";
  return await authFetch("post", url, body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPendingOrderDetails = async (orderId, asNotary) => {
  let url =
    SERVICE_URL +
    orderId +
    "/order-details-with-pending-notary-invitations?view-as-notary-admin=" +
    asNotary;
  return await authFetch("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
