import React, { Fragment, useEffect } from "react";

import {
  Grid,
  Paper,
  Typography,
  Box,
  Stack,
  Skeleton,
  Button,
  Link,
} from "@mui/material";

import { useStateUpdate } from "UseStateUpdate";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { getAdminSummary } from "service/AdminService";

function createFigures(title, value, type, isIncrease) {
  return { title, value, type, isIncrease };
}

const skeleton = () => {
  return (
    <Fragment>
      {[1, 2].map((index) => (
        <Grid key={index} item xs={6} sm={3}>
          <Paper variant="outlined">
            <Box p={1} pb={{ xs: 1, md: 4 }}>
              <Stack spacing={2}>
                <Skeleton animation="wave" variant="rectangular" />
                <Grid container pt={{ xs: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Paper variant="outlined">
          <Box>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={100}
              width="100%"
            />
          </Box>
        </Paper>
      </Grid>
    </Fragment>
  );
};

function AdminUsersSummary({ showAdminUsers, changeHeading }) {
  let navigate = useNavigate();
  const figures = useStateUpdate([]);

  useEffect(() => {
    fetchAdminSummary();
  }, []);

  const fetchAdminSummary = async () => {
    var result = await getAdminSummary();
    if (result.status === 200) {
      figures.update([
        createFigures("New Users", result.data.data.newUsersCount, "new", true),
        createFigures(
          "Inactive Users",
          result.data.data.inActiveUsersCount,
          "inactive",
          false
        ),
      ]);
    }
  };

  return (
    <Grid container spacing={2}>
      {figures.state.length > 0 ? (
        <Fragment>
          {figures.state.map((figure) => (
            <Grid key={figure.title} container item xs={6} sm={3}>
              <Paper variant="outlined" sx={{ width: "100%" }}>
                <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
                  <Stack spacing={2}>
                    <Typography variant="font15b" color="#686868" noWrap>
                      {figure.title}
                    </Typography>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      pt={{ xs: 1, md: 2 }}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {figure.value === 0 ? (
                          <Typography variant="font32b">
                            {figure.value}
                          </Typography>
                        ) : (
                          <Link
                            href=""
                            variant="font32b"
                            color="secondary.light"
                            underline="none"
                            onClick={(e) => {
                              e.preventDefault();
                              showAdminUsers.update(figure.type);
                              changeHeading(figure.title);
                            }}
                          >
                            {figure.value}
                          </Link>
                        )}
                      </Stack>
                    </Grid>
                  </Stack>
                </Box>
              </Paper>
            </Grid>
          ))}
          <Grid container item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ width: "100%" }}>
              <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
                <Stack spacing={2}>
                  <Typography variant="font15b" color="#686868" noWrap>
                    User management
                  </Typography>
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        navigate("/dashboard/manage-users/add-new-user");
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <AddCircleOutlineOutlinedIcon />
                        <Typography variant="font17b">Add New User</Typography>
                      </Stack>
                    </Button>
                  </Grid>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Fragment>
      ) : (
        skeleton()
      )}
    </Grid>
  );
}

export default AdminUsersSummary;
