import React from "react";
//materialUi
import { Snackbar, Alert } from "@mui/material";

function Toast(props) {
  const { toastOpen, severity, toastMessage } = props;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    toastOpen.update(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={toastOpen.state}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert severity={severity}>{toastMessage}</Alert>
    </Snackbar>
  );
}

export default Toast;
