import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Rating,
  Stack,
  IconButton,
} from "@mui/material";
import { STATES as states } from "components/common/Constant";
import CloseIcon from "@mui/icons-material/Close";
import CustomMultipleSelect from "components/profile/common_componets/CustomMultipleSelect";
import { useStateUpdate } from "UseStateUpdate";
import { getConfigurationList } from "service/ConfigurationService";

function NotaryAdvancedSearch(props) {
  const {
    showNotaries,
    firstName,
    lastName,
    city,
    notaryLanguage,
    state,
    zipCode,
    minRating,
    radius,
    callSearch,
    changeHeading,
  } = props;
  const languages = useStateUpdate([]);

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    var result = await getConfigurationList();
    result.status === 200
      ? languages.update(result.data.data.languages)
      : languages.update([]);
  };

  const clearAllField = () => {
    firstName.update("");
    lastName.update("");
    city.update("");
    notaryLanguage.update([]);
    state.update("");
    zipCode.update("");
    minRating.update(1);
    radius.update("1");
  };

  return (
    <form>
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        p={2}
        justifyContent="flex-end"
      >
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            placeholder="First name"
            fullWidth
            label={<Typography variant="font12">First name</Typography>}
            color="secondary"
            size="small"
            value={firstName.state}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              firstName.update(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            placeholder="Last name"
            fullWidth
            label={<Typography variant="font12">Last name</Typography>}
            color="secondary"
            size="small"
            value={lastName.state}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              lastName.update(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            placeholder="City"
            fullWidth
            label={<Typography variant="font12">City</Typography>}
            color="secondary"
            size="small"
            value={city.state}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              city.update(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth size="small" color="secondary">
            <InputLabel>
              <Typography variant="font12">State</Typography>
            </InputLabel>
            <Select
              labelId="state-select-label"
              id="state"
              value={state.state}
              label={<Typography variant="font12">State</Typography>}
              endAdornment={
                <IconButton
                  sx={{
                    display: state.state !== "" ? "" : "none",
                    marginRight: "10px",
                  }}
                  onClick={() => state.update("")}
                >
                  <CloseIcon />
                </IconButton>
              }
              onChange={(e) => {
                state.update(e.target.value);
              }}
            >
              {states.map((state) => (
                <MenuItem key={state.code} value={state.code}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            placeholder="Zip"
            fullWidth
            label={<Typography variant="font12">Zip</Typography>}
            color="secondary"
            size="small"
            value={zipCode.state}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => {
              zipCode.update(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth size="small" color="secondary">
            <InputLabel>
              <Typography variant="font12">Min rating</Typography>
            </InputLabel>
            <Select
              labelId="min-rating-select-label"
              id="min-rating"
              value={minRating.state}
              label={<Typography variant="font12">Min rating</Typography>}
              onChange={(e) => {
                minRating.update(e.target.value);
              }}
            >
              <MenuItem value={0}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Rating name="read-only" value={0} precision={0.5} readOnly />
                  <Typography variant="font12">Not Yet Rated</Typography>
                </Stack>
              </MenuItem>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                <MenuItem key={rating} value={rating}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Rating
                      name="read-only"
                      value={rating / 2}
                      precision={0.5}
                      readOnly
                    />
                    <Typography variant="font12">{rating + "/10"}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            placeholder="Radius"
            fullWidth
            label={<Typography variant="font12">Radius</Typography>}
            color="secondary"
            size="small"
            value={radius.state}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => {
              radius.update(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <CustomMultipleSelect
            variant="outlined"
            size="small"
            label={<Typography variant="font12">Languages</Typography>}
            options={languages.state}
            selectedOptions={notaryLanguage.state}
            setSelectedOptions={notaryLanguage.update}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} container justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                callSearch();
                showNotaries.update("advanceSearch");
                changeHeading("Notaries");
              }}
            >
              <Typography variant="font12b" color="#000" noWrap>
                Search
              </Typography>
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                clearAllField();
              }}
            >
              <Typography variant="font12b" color="#000" noWrap>
                Clear
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export default NotaryAdvancedSearch;
