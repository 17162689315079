import React, { useEffect } from "react";

import {
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Grid,
  Stack,
  Paper,
  Divider,
  TableHead,
  TableSortLabel,
  Box,
  Pagination,
  IconButton,
  InputBase,
} from "@mui/material";

import { visuallyHidden } from "@mui/utils";
import { useStateUpdate } from "UseStateUpdate";
import ShowItemsPerPage from "components/common/ShowItemsPerPage";
import { rowHoverClickStyle } from "components/common/Styles";
import AdminOrganizationModal from "./AdminOrganizationModal";
import { listOrganizations } from "service/AdminService";
import { listOrganizationMembers } from "service/AdminService";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

function AdminOrganizations() {
  const classes = rowHoverClickStyle();
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("desc");
  const orderBy = useStateUpdate("id");
  const organizationToModal = useStateUpdate({});
  const organizationsList = useStateUpdate([]);
  const organizationId = useStateUpdate("");
  const openModal = useStateUpdate(false);
  const filterValue = useStateUpdate("");

  useEffect(() => {
    fetchOrganizationList();
  }, [page.state, rowsPerPage.state, orderBy.state, order.state]);

  useEffect(() => {
    organizationId.state !== "" && fetchOrganizationMembers();
  }, [organizationId.state]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.state === property && order.state === "asc";
    order.update(isAsc ? "desc" : "asc");
    orderBy.update(property);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell sortDirection={orderBy === "name" ? order : false}>
            <TableSortLabel
              active={orderBy === "name"}
              direction={orderBy === "name" ? order : "asc"}
              onClick={createSortHandler("name")}
            >
              <Typography variant="font12b" noWrap>
                Name
              </Typography>

              {orderBy === "name" ? (
                <Box sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <Typography variant="font12b">Admins</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="font12b">Invitation Code</Typography>
          </TableCell>
          <TableCell
            sortDirection={
              orderBy === "notary_organization_count" ? order : false
            }
          >
            <TableSortLabel
              active={orderBy === "notary_organization_count"}
              direction={
                orderBy === "notary_organization_count" ? order : "asc"
              }
              onClick={createSortHandler("notary_organization_count")}
            >
              <Typography variant="font12b" noWrap>
                Total members
              </Typography>

              {orderBy === "notary_organization_count" ? (
                <Box sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    filterValue.state === "" && pageCount.state > 0 && fetchOrganizationList();
  }, [filterValue.state]);

  const fetchOrganizationList = async () => {
    var result = await listOrganizations(
      page.state,
      rowsPerPage.state,
      orderBy.state,
      order.state.toUpperCase(),
      filterValue.state
    );
    if (result.status === 200) {
      organizationsList.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
    }
  };

  const fetchOrganizationMembers = async () => {
    var result = await listOrganizationMembers(organizationId.state);
    if (result.status === 200) {
      organizationToModal.update(result.data.data);
      openModal.update(true);
    }
  };

  const getAdmins = (adminsArray) => {
    const admins = adminsArray.join(", ");
    return admins;
  };

  return (
    <Grid container item xs={12}>
      <AdminOrganizationModal
        open={openModal}
        organizationId={organizationId}
        organization={organizationToModal.state}
        fetchOrganizationList={fetchOrganizationList}
        fetchOrganizationMembersList={fetchOrganizationMembers}
      />
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Grid container p={2}>
          <Grid item xs={6}>
            <Typography variant="font17b" color="secondary.light">
              Organizations
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 4 }}
              alignItems="center"
            >
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: 250, sm: 350 },
                }}
              >
                <InputBase
                  sx={{ ml: 1, width: { xs: 200, sm: 300 } }}
                  placeholder="Search by organization name"
                  value={filterValue.state}
                  onChange={(e) => {
                    filterValue.update(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (
                      event.code === "Enter" ||
                      event.code === "NumpadEnter"
                    ) {
                      event.preventDefault();
                      event.target.value !== "" && fetchOrganizationList();
                    }
                  }}
                />
                {filterValue.state !== "" && (
                  <IconButton
                    onClick={(e) => {
                      filterValue.update("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton
                  sx={{
                    backgroundColor: "secondary.light",
                    borderRadius: "0%",
                  }}
                  onClick={(e) => {
                    filterValue.state !== "" && fetchOrganizationList();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Stack>
          </Grid>
        </Grid>

        <Divider />
        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead
                order={order.state}
                orderBy={orderBy.state}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {organizationsList.state.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="font15">NO DATA FOUND</Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : (
                  organizationsList.state.map((organization, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        classes={{ hover: classes.hover }}
                        onClick={() => {
                          organizationId.update(organization.organizationId);
                        }}
                      >
                        {[
                          organization.organization,
                          getAdmins(organization.admins),
                          organization.invitationCode,
                          organization.numberOfMembers,
                        ].map((organizationDetail, index) => {
                          return (
                            <TableCell key={index}>
                              <Typography variant="font12">
                                {organizationDetail}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
                {organizationsList.state.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Grid
                        container
                        direction="row"
                        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        alignItems="center"
                      >
                        <Stack direction="row" spacing={2}>
                          <ShowItemsPerPage
                            perPage={rowsPerPage}
                            items={[10, 20, 50, 100]}
                          />
                          <Pagination
                            count={pageCount.state}
                            showFirstButton
                            showLastButton
                            size="small"
                            onChange={(e, value) => {
                              page.update(value);
                            }}
                          />
                        </Stack>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Divider />
      </Paper>
    </Grid>
  );
}

export default AdminOrganizations;
