import React from "react";
import { Link } from "@mui/material/";
import { MAPBASEURL } from "./Constant";

function OpenMap({ lat, long }) {
  return (
    <Link
      href={MAPBASEURL + lat + "," + long}
      target="_blank"
      variant="font12"
      color="secondary.light"
      underline="none"
    >
      Open With Google Maps
    </Link>
  );
}

export default OpenMap;
