import { useState } from "react";

export const useStateUpdate = (initialState) => {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value);
  }
  return {
    state,
    update,
  };
};
