import {
  setToken,
  getToken,
  isLoggedIn,
  subscribe,
  unsubscribe,
} from "./TokenProvider";
import axios from "components/common/Axios";
import { useState, useEffect } from "react";

export const login = (newTokens) => {
  setToken(newTokens);
};

export const logout = () => {
  setToken(null);
};

export const authFetch = async (methodName, apiUrl, body) => {
  const token = await getToken();
  const adminId = localStorage.getItem("adminId");
  const header = adminId
    ? {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-Admin": adminId,
      }
    : {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
  var config = {
    method: methodName,
    url: apiUrl,
    headers: header,
    data: body,
  };
  return await axios(config);
};

export const useAuth = () => {
  const [isLogged, setIsLogged] = useState(isLoggedIn());

  useEffect(() => {
    const listener = (newIsLogged) => {
      setIsLogged(newIsLogged);
    };

    subscribe(listener);
    return () => {
      unsubscribe(listener);
    };
  }, []);

  return isLogged;
};
