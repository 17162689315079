import React, { Fragment, useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import JoinOrganization from "./sections/JoinOrganization";
import OtherOrganization from "./sections/OtherOrganization";
import OwnedOrganization from "./sections/OwnedOrganization";
import { getProfileId } from "components/common/GetUserDetail";
import { getOrganizationMembersList } from "service/OrganizationService";
import { useStateUpdate } from "UseStateUpdate";
import { useIsAdmin } from "components/common/GetUserDetail";
import AdminOrganizations from "./sections/AdminOrganizations";
import { useIsNotary } from "components/common/GetUserDetail";

function Organization({ profileIdIs, isAdminView }) {
  const isAdmin = useIsAdmin();
  const isNotary = useIsNotary();
  const profileId = profileIdIs ? profileIdIs : getProfileId();
  const organizationsData = useStateUpdate([]);
  const ownedOrganizationData = useStateUpdate([]);
  const otherOrganizationsData = useStateUpdate([]);

  useEffect(() => {
    (isNotary || isAdminView) && fetchOrganizationMembersList();
  }, []);

  useEffect(() => {
    var ownedOrganizations = [];
    var otherOrganizations = [];
    organizationsData.state.length > 0 &&
      organizationsData.state.forEach((organization) => {
        organization.members.length > 0
          ? ownedOrganizations.push(organization)
          : otherOrganizations.push(organization);
      });

    ownedOrganizationData.update(ownedOrganizations);
    otherOrganizationsData.update(otherOrganizations);
  }, [organizationsData.state]);

  const fetchOrganizationMembersList = async () => {
    var result = await getOrganizationMembersList(profileId);
    result.status === 200 && organizationsData.update(result.data.data);
  };

  return (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      {isAdmin && !isAdminView ? (
        <Fragment>
          <Grid mb={2}>
            <Typography variant="font20b">Organization Management</Typography>
          </Grid>
          <AdminOrganizations />
        </Fragment>
      ) : (
        <Fragment>
          {!isAdminView && (
            <Grid mb={2}>
              <Typography variant="font20b">Organizations</Typography>
            </Grid>
          )}
          <Stack spacing={2} my={2}>
            {ownedOrganizationData.state.map((organization, index) => (
              <OwnedOrganization
                key={index}
                profileId={profileId}
                organization={organization}
                fetchOrganizationMembersList={fetchOrganizationMembersList}
              />
            ))}
            {otherOrganizationsData.state.map((organization, index) => (
              <OtherOrganization
                key={index}
                profileId={profileId}
                isAdminView={isAdminView}
                organization={organization}
                fetchOrganizationMembersList={fetchOrganizationMembersList}
              />
            ))}
            {isNotary && (
              <JoinOrganization
                profileId={profileId}
                fetchOrganizationMembersList={fetchOrganizationMembersList}
              />
            )}
          </Stack>
        </Fragment>
      )}
    </Box>
  );
}

export default Organization;
