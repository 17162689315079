import React, { Fragment, useEffect, useContext } from "react";
import { ProfileContext } from "AppContext";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";

import {
  Button,
  Divider,
  Typography,
  Grid,
  AccordionDetails,
  TextField,
  Tooltip,
  CircularProgress,
  Hidden,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import UploadFile from "./sections/UploadFile";
import PolarQuestions from "../common_componets/PolarQuestion";
import CustomMultipleSelect from "../common_componets/CustomMultipleSelect";

import { updateProfile } from "service/ProfileService";

import { DOCUMENT_TYPE_CODES } from "components/common/Constant";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { formattedDate } from "components/common/GetDateAndTime";
import { CERTIFICATION_CODES } from "components/common/Constant";
import { getConfigurationList } from "service/ConfigurationService";

const certificationsCodes = CERTIFICATION_CODES;

const createFile = (documentTypeCode, file) => {
  return {
    documentTypeCode: documentTypeCode,
    file: file ? file.fileName ?? "" : "",
    fileName: file ? file.fileName ?? "" : "",
    documentId: file ? file.documentId ?? "" : "",
    expiresAt: file ? formattedDate(file.expiresAt, "input") ?? "" : "",
  };
};

function HelpToolTip({ title }) {
  return (
    <Hidden mdDown>
      <Tooltip title={title}>
        <HelpIcon />
      </Tooltip>
    </Hidden>
  );
}

function Credentials({ checkPercentage }) {
  const profileValue = useContext(ProfileContext);
  const { profileDatas, fetchProfileData } = profileValue;
  const isLoader = useStateUpdate(false);
  const documentTypeCodes = DOCUMENT_TYPE_CODES;
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const isButtonDisabled = useStateUpdate(true);
  //files
  const fileNPCExpiration = useStateUpdate(
    createFile(documentTypeCodes.npcExpiration)
  );
  const fileElectronicNotaryExpiration = useStateUpdate(
    createFile(documentTypeCodes.electronicNotaryExpiration)
  );
  const fileBackgroundDocuments = useStateUpdate(
    createFile(documentTypeCodes.backgroundDocuments)
  );
  const fileW9 = useStateUpdate(createFile(documentTypeCodes.w9));
  const fileFNTGDocuments = useStateUpdate(
    createFile(documentTypeCodes.fntgDocuments)
  );
  const fileIdentification = useStateUpdate(
    createFile(documentTypeCodes.identification)
  );
  const fileEandOInsurance = useStateUpdate(
    createFile(documentTypeCodes.eandOInsurance)
  );
  const fileSigningAgentCertifications = useStateUpdate(
    createFile(documentTypeCodes.signingAgentCertifications)
  );
  const fileAttorneyBarLicense = useStateUpdate(
    createFile(documentTypeCodes.attorneyBarLicense)
  );
  const fileTitleInsuranceProducersLicense = useStateUpdate(
    createFile(documentTypeCodes.titleInsuranceProducersLicense)
  );
  const fileLicensedClosingagent = useStateUpdate(
    createFile(documentTypeCodes.licensedClosingagent)
  );

  const backgroundPassDate = useStateUpdate("");
  const isUtilizedAnyRONS = useStateUpdate(false);
  const ronPlatformsUsed = useStateUpdate([]);
  const ronPlatforms = useStateUpdate([]);

  const pavasoCertification = useStateUpdate(false);

  const handleChange = (e, file) => {
    isButtonDisabled.update(false);
    file.update({
      ...file.state,
      expiresAt: e.target.value,
    });
  };

  useEffect(() => {
    fetchRonPlatforms();
  }, []);

  useEffect(() => {
    !isUtilizedAnyRONS.state && ronPlatformsUsed.update([]);
  }, [isUtilizedAnyRONS.state]);

  useEffect(() => {
    backgroundPassDate.state !== "" &&
      fileBackgroundDocuments.update({
        ...fileBackgroundDocuments.state,
        expiresAt: getbackgroundDate(backgroundPassDate.state, "exp"),
      });
  }, [backgroundPassDate.state]);

  useEffect(() => {
    if (isNotEmpty(profileDatas)) {
      pavasoCertification.update(
        isCertified(profileDatas.certifications, certificationsCodes.pavaso)
      );
      isUtilizedAnyRONS.update(profileDatas.ronPlatformsUsed.length > 0);
      ronPlatformsUsed.update(profileDatas.ronPlatformsUsed);
      profileDatas.documents.forEach((file) => {
        switch (file.documentTypeCode) {
          case documentTypeCodes.npcExpiration:
            fileNPCExpiration.update(createFile(file.documentTypeCode, file));
            break;
          case documentTypeCodes.electronicNotaryExpiration:
            fileElectronicNotaryExpiration.update(
              createFile(file.documentTypeCode, file)
            );
            break;
          case documentTypeCodes.signingAgentCertifications:
            fileSigningAgentCertifications.update(
              createFile(file.documentTypeCode, file)
            );
            break;
          case documentTypeCodes.attorneyBarLicense:
            fileAttorneyBarLicense.update(
              createFile(file.documentTypeCode, file)
            );
            break;
          case documentTypeCodes.titleInsuranceProducersLicense:
            fileTitleInsuranceProducersLicense.update(
              createFile(file.documentTypeCode, file)
            );
            break;
          case documentTypeCodes.licensedClosingagent:
            fileLicensedClosingagent.update(
              createFile(file.documentTypeCode, file)
            );
            break;
          case documentTypeCodes.eandOInsurance:
            fileEandOInsurance.update(createFile(file.documentTypeCode, file));
            break;
          case documentTypeCodes.fntgDocuments:
            fileFNTGDocuments.update(createFile(file.documentTypeCode, file));
            break;
          case documentTypeCodes.backgroundDocuments:
            fileBackgroundDocuments.update(
              createFile(file.documentTypeCode, file)
            );
            backgroundPassDate.update(
              file.expiresAt === null
                ? ""
                : getbackgroundDate(file.expiresAt, "pass")
            );
            break;
          case documentTypeCodes.w9:
            fileW9.update(createFile(file.documentTypeCode, file));
            break;
          case documentTypeCodes.identification:
            fileIdentification.update(createFile(file.documentTypeCode, file));
            break;
          default:
            break;
        }
      });
    }
  }, [profileDatas]);

  const fetchRonPlatforms = async () => {
    var result = await getConfigurationList();
    result.status === 200
      ? ronPlatforms.update(result.data.data.ronPlatforms)
      : ronPlatforms.update([]);
  };

  const getCertifications = () => {
    var certifications = [];
    pavasoCertification.state &&
      certifications.push(getCertification(certificationsCodes.pavaso));
    return certifications;
  };

  const getCertification = (code) => {
    var certificationIs = null;

    for (const [certificationName, certificationCode] of Object.entries(
      certificationsCodes
    )) {
      certificationCode === code &&
        (certificationIs = {
          code: certificationCode,
          name: certificationName,
        });
    }

    return certificationIs;
  };

  const isCertified = (certifications, code) => {
    var certified = false;
    certifications.forEach((certification) => {
      certification.code === code && (certified = true);
    });
    return certified;
  };

  const getDocumentArray = () => {
    var documentArray = [];

    (fileNPCExpiration.state.fileName !== "" ||
      fileNPCExpiration.state.documentId !== "" ||
      fileNPCExpiration.state.expiresAt !== "") &&
      documentArray.push(fileNPCExpiration.state);

    (fileElectronicNotaryExpiration.state.fileName !== "" ||
      fileElectronicNotaryExpiration.state.documentId !== "" ||
      fileElectronicNotaryExpiration.state.expiresAt !== "") &&
      documentArray.push(fileElectronicNotaryExpiration.state);

    (fileBackgroundDocuments.state.fileName !== "" ||
      fileBackgroundDocuments.state.documentId !== "" ||
      fileBackgroundDocuments.state.expiresAt !== "") &&
      documentArray.push(fileBackgroundDocuments.state);

    (fileW9.state.fileName !== "" ||
      fileW9.state.documentId !== "" ||
      fileW9.state.expiresAt !== "") &&
      documentArray.push(fileW9.state);

    (fileFNTGDocuments.state.fileName !== "" ||
      fileFNTGDocuments.state.documentId !== "" ||
      fileFNTGDocuments.state.expiresAt !== "") &&
      documentArray.push(fileFNTGDocuments.state);

    (fileIdentification.state.fileName !== "" ||
      fileIdentification.state.documentId !== "" ||
      fileIdentification.state.expiresAt !== "") &&
      documentArray.push(fileIdentification.state);

    (fileEandOInsurance.state.fileName !== "" ||
      fileEandOInsurance.state.documentId !== "" ||
      fileEandOInsurance.state.expiresAt !== "") &&
      documentArray.push(fileEandOInsurance.state);

    (fileSigningAgentCertifications.state.fileName !== "" ||
      fileSigningAgentCertifications.state.documentId !== "" ||
      fileSigningAgentCertifications.state.expiresAt !== "") &&
      documentArray.push(fileSigningAgentCertifications.state);

    (fileAttorneyBarLicense.state.fileName !== "" ||
      fileAttorneyBarLicense.state.documentId !== "" ||
      fileAttorneyBarLicense.state.expiresAt !== "") &&
      documentArray.push(fileAttorneyBarLicense.state);

    (fileTitleInsuranceProducersLicense.state.fileName !== "" ||
      fileTitleInsuranceProducersLicense.state.documentId !== "" ||
      fileTitleInsuranceProducersLicense.state.expiresAt !== "") &&
      documentArray.push(fileTitleInsuranceProducersLicense.state);

    (fileLicensedClosingagent.state.fileName !== "" ||
      fileLicensedClosingagent.state.documentId !== "" ||
      fileLicensedClosingagent.state.expiresAt !== "") &&
      documentArray.push(fileLicensedClosingagent.state);

    return documentArray;
  };

  function getCredentialsData() {
    var data = {
      ronPlatformsUsed: ronPlatformsUsed.state,
      documents: getDocumentArray(),
      certifications: getCertifications(),
      profileCompletionPercentage: checkPercentage(),
    };
    return data;
  }

  function getbackgroundDate(date, dateType) {
    var dateIs = new Date(date.replace(/-/g, "/"));
    dateIs =
      dateType === "pass"
        ? new Date(
            dateIs.setDate(new Date(date.replace(/-/g, "/")).getDate() - 364)
          )
        : new Date(
            dateIs.setDate(new Date(date.replace(/-/g, "/")).getDate() + 364)
          );
    var dd = String(dateIs.getDate()).padStart(2, "0");
    var mm = String(dateIs.getMonth() + 1).padStart(2, "0");
    var yyyy = dateIs.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  async function updateCredentials(e) {
    e.preventDefault();
    isLoader.update(true);
    let data = getCredentialsData();
    var response = await updateProfile(profileDatas.profileId, data);
    if (response.status === 200) {
      if (response.data.code === 200) {
        isLoader.update(false);
        fetchProfileData();
        calltoast("success", response.data.message);
      } else {
        calltoast("error", response.data.message);
        isLoader.update(false);
      }
    } else {
      calltoast("error", response.data.message);
      isLoader.update(false);
    }
    isButtonDisabled.update(true);
  }

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Fragment>
      <AccordionDetails>
        <Grid container spacing={5} px={2} mb={5}>
          <Grid container spacing={5} item xs={12}>
            <PolarQuestions
              valueIs={pavasoCertification.state}
              toggle={pavasoCertification.update}
              question="Are you Pavaso Certified?"
              isButtonDisabled={isButtonDisabled}
            />
          </Grid>

          <PolarQuestions
            valueIs={isUtilizedAnyRONS.state}
            toggle={isUtilizedAnyRONS.update}
            question="Have You Utilized Any Electronic or Remote Notarization Software? "
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <CustomMultipleSelect
              variant="standard"
              size="normal"
              isDisabled={!isUtilizedAnyRONS.state}
              label={
                <Typography variant="font12">if so, Please specify.</Typography>
              }
              isButtonDisabled={isButtonDisabled}
              options={ronPlatforms.state}
              selectedOptions={ronPlatformsUsed.state}
              setSelectedOptions={ronPlatformsUsed.update}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  Notary Public Commision Expiration Date
                  <HelpToolTip title="Notary Public Commision Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileNPCExpiration.state.expiresAt}
              onChange={(e) => handleChange(e, fileNPCExpiration)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="npc-Expiration"
            label="NPC Expiration"
            fileIs={fileNPCExpiration}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  Electronic Notary Expiration Date
                  <HelpToolTip title="Electronic Notary Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileElectronicNotaryExpiration.state.expiresAt}
              onChange={(e) => handleChange(e, fileElectronicNotaryExpiration)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="electronic-notary-expiration"
            label="Electronic Notary Expiration"
            fileIs={fileElectronicNotaryExpiration}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              helperText={
                backgroundPassDate.state !== "" && (
                  <Fragment>
                    <Typography variant="font14" color="#B21C1C">
                      {"Expiration Date: "}
                    </Typography>
                    <Typography variant="font14b" color="#B21C1C">
                      {formattedDate(fileBackgroundDocuments.state.expiresAt)}
                    </Typography>
                  </Fragment>
                )
              }
              label={
                <Fragment>
                  Background screening pass date
                  <HelpToolTip title="Background screening pass date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={backgroundPassDate.state}
              onChange={(e) => {
                backgroundPassDate.update(e.target.value);
              }}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="background-screening-documents"
            label="Background screening documents"
            fileIs={fileBackgroundDocuments}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  W9 Expiration Date
                  <HelpToolTip title="W9 Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileW9.state.expiresAt}
              onChange={(e) => handleChange(e, fileW9)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="w9"
            label="W9"
            fileIs={fileW9}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  FNTG Expiration Date
                  <HelpToolTip title="FNTG Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileFNTGDocuments.state.expiresAt}
              onChange={(e) => handleChange(e, fileFNTGDocuments)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="fntg-documents"
            label="FNTG Documents"
            fileIs={fileFNTGDocuments}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  Identification Expiration Date
                  <HelpToolTip title="Identification Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileIdentification.state.expiresAt}
              onChange={(e) => handleChange(e, fileIdentification)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="identification"
            label="Identification"
            fileIs={fileIdentification}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  E&O Insurance Expiration Date
                  <HelpToolTip title="E&O Insurance Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileEandOInsurance.state.expiresAt}
              onChange={(e) => handleChange(e, fileEandOInsurance)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="e-and-o-insurance"
            label="E&O Insurance"
            fileIs={fileEandOInsurance}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  Attorney Bar License Expiration Date
                  <HelpToolTip title="Attorney Bar License Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileAttorneyBarLicense.state.expiresAt}
              onChange={(e) => handleChange(e, fileAttorneyBarLicense)}
            />
          </Grid>

          <UploadFile
            checkPercentage={checkPercentage}
            id="attorney-bar-license"
            label="Attorney Bar License"
            fileIs={fileAttorneyBarLicense}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  Title Insurance Expiration Date
                  <HelpToolTip title="Title Insurance Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileTitleInsuranceProducersLicense.state.expiresAt}
              onChange={(e) =>
                handleChange(e, fileTitleInsuranceProducersLicense)
              }
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="title-insurance-producers-license"
            label="Title Insurance Producers License"
            fileIs={fileTitleInsuranceProducersLicense}
            isButtonDisabled={isButtonDisabled}
          />
          <Grid item xs={12} md={6}>
            <TextField
              label={
                <Fragment>
                  Licensed Closing Expiration Date
                  <HelpToolTip title="Licensed Closing Expiration Date" />
                </Fragment>
              }
              type="date"
              inputProps={{ maxLength: 15 }}
              fullWidth
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              value={fileLicensedClosingagent.state.expiresAt}
              onChange={(e) => handleChange(e, fileLicensedClosingagent)}
            />
          </Grid>
          <UploadFile
            checkPercentage={checkPercentage}
            id="licensed-closing-agent"
            label="Licensed Closing agent"
            fileIs={fileLicensedClosingagent}
            isButtonDisabled={isButtonDisabled}
          />
          <UploadFile
            checkPercentage={checkPercentage}
            id="signing-agent-certifications"
            label="Signing Agent Certifications"
            fileIs={fileSigningAgentCertifications}
            isButtonDisabled={isButtonDisabled}
          />
        </Grid>
        <Divider sx={{ borderBottomWidth: 3 }} />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          px={2}
          mt={2}
        >
          <Button
            variant="contained"
            disabled={isLoader.state || isButtonDisabled.state}
            onClick={(e) => {
              updateCredentials(e);
            }}
          >
            <Typography variant="buttonLabel" mx={6}>
              Save
            </Typography>
            {isLoader.state && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  marginLeft: "-10px",
                }}
              />
            )}
          </Button>
        </Grid>
      </AccordionDetails>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default Credentials;
