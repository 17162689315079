import React, { Fragment } from "react";
import { Typography, Stack, Divider, Link, IconButton } from "@mui/material";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as AcknowledgedNoteNoteIcon } from "assets/images/acknowledgedNote.svg";
import { ReactComponent as NewNoteIcon } from "assets/images/newNote.svg";
import { formattedDate, formattedTime } from "components/common/GetDateAndTime";
import { useStateUpdate } from "UseStateUpdate";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function ListOrderNotes({ notes, canDelete }) {
  const currentRead = useStateUpdate(null);
  const ListNotes = ({ note }) => {
    return (
      <Fragment>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <SvgIcon>
              {note.hasViewed ? <AcknowledgedNoteNoteIcon /> : <NewNoteIcon />}
            </SvgIcon>
            <Stack>
              <Link
                color="secondary.light"
                href="#"
                variant="font12"
                underline="none"
                onClick={(e) => {
                  e.preventDefault();
                  currentRead.update(
                    currentRead.state === note.noteId ? null : note.noteId
                  );
                }}
              >
                {currentRead.state === note.noteId
                  ? note.message ?? ""
                  : String(note.message ?? "").substring(0, 25) + "..."}
              </Link>
              <Typography variant="font12" color="#A1A1A1">
                {formattedDate(note.date) +
                  " " +
                  formattedTime(note.date) +
                  " | " +
                  note.author}
              </Typography>
            </Stack>
          </Stack>
          <IconButton
            size="small"
            sx={{ display: canDelete ? "block" : "none" }}
          >
            <DeleteOutlineOutlinedIcon style={{ color: "#686868" }} />
          </IconButton>
        </Stack>
        <Divider />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {notes.map((note, index) => (
        <ListNotes note={note} key={index} />
      ))}
    </Fragment>
  );
}

export default ListOrderNotes;
