import React from "react";
import { useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";

import { isEmptyString } from "components/common/FormValidator";
import { isValidEmail } from "components/common/FormValidator";
import { forgotPasswordSendMail } from "service/AuthService";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const email = useStateUpdate("");
  const isMailSend = useStateUpdate(false);
  const isLoader = useStateUpdate(false);

  const validationError = useStateUpdate({
    email: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      email: "",
    };
    if (isEmptyString(email.state)) {
      newValidationError = {
        email: "Email is required",
      };
      isValidated = false;
    } else if (!isValidEmail(email.state)) {
      newValidationError = {
        email: "Email is invalid.",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  async function sendMail() {
    isLoader.update(true);
    var data = {
      email: email.state,
    };
    var response = await forgotPasswordSendMail(data);
    response.status === 200 &&
      (response.data.code === 200
        ? isMailSend.update(true)
        : validationError.update({
            email: response.data.error.email[0],
          }));
    isLoader.update(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper>
        <Box mt={10} py={5} px={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={1}
          >
            <Typography variant="font20b">Forgot Password?</Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={3}
          >
            <Typography
              variant="font14"
              color={isMailSend.state ? "error" : "#000"}
            >
              {isMailSend.state
                ? "Please check your email (" +
                  email.state +
                  ") to reset password"
                : "Please enter your email address to send password reset link."}
            </Typography>
          </Grid>

          {!isMailSend.state && (
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                label="Email"
                variant="standard"
                color="secondary"
                value={email.state}
                inputProps={{ maxLength: 35 }}
                helperText={
                  <Typography variant="font14" color="error">
                    {validationError.state.email}
                  </Typography>
                }
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    email: "",
                  });
                  email.update(e.target.value);
                }}
              />
            </Grid>
          )}

          {isMailSend.state ? (
            <Grid container justifyContent="center">
              <Button
                variant="text"
                color="secondary"
                onClick={() => navigate("/", { replace: true })}
              >
                <Typography variant="buttonLabel">Home</Typography>
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" alignItems="center">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/login", { replace: true })}
                >
                  <Typography variant="buttonLabel">Cancel</Typography>
                </Button>
                <Button
                  disabled={isLoader.state}
                  variant="contained"
                  onClick={(e) => {
                    checkValidatorOnSubmit() && sendMail();
                  }}
                >
                  <Typography variant="buttonLabel">Send</Typography>
                  {isLoader.state && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Grid>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgotPassword;
