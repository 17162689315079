export const createOrdersTabs = (
  label,
  badge,
  datas,
  pageCount,
  page,
  haveButton
) => {
  return { label, badge, datas, pageCount, page, haveButton };
};

export const createOrdersData = (
  isAccepted,
  orderId,
  orderNumber,
  orderStatus,
  hasNotes,
  hasDocuments,
  signingType,
  signingDate,
  signingTime,
  signer,
  signerPhone,
  originator,
  originatorEmail,
  originating,
  originatingPhone,
  fileNumber,
  notaries,
  notariesPhone,
  scheduler,
  signingAgency,
  address,
  lat,
  long
) => {
  return {
    isAccepted,
    orderId,
    orderNumber,
    orderStatus,
    hasNotes,
    hasDocuments,
    signingType,
    signingDate,
    signingTime,
    signer,
    signerPhone,
    originator,
    originatorEmail,
    originating,
    originatingPhone,
    fileNumber,
    notaries,
    notariesPhone,
    scheduler,
    signingAgency,
    address,
    lat,
    long,
  };
};
