import React, { Fragment, useContext } from "react";
import { Button, Stack, Typography, Avatar } from "@mui/material";
import broken_avatar from "assets/images/broken-avatar.png";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
import { ProfileContext } from "AppContext";
import UploadImageModal from "components/CropImage/UploadImageModal";

function ProfilePicture({ checkPercentage }) {
  const file = useStateUpdate("");
  const profileValue = useContext(ProfileContext);
  const { profileDatas } = profileValue;
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const openModal = useStateUpdate(false);

  const getProfilePicture = () => {
    return profileDatas.photoUrl === null || profileDatas.photoUrl === ""
      ? broken_avatar
      : profileDatas.photoUrl;
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Fragment>
      <UploadImageModal
        open={openModal}
        calltoast={calltoast}
        checkPercentage={checkPercentage}
      />
      <Stack spacing={3} alignItems="center">
        <Avatar src={getProfilePicture()} sx={{ width: 130, height: 130 }} />

        <Button
          variant="outlined"
          component="span"
          color="secondary"
          onClick={() => openModal.update(true)}
        >
          <Typography variant="buttonLabel">Upload Photo</Typography>
        </Button>

        <Toast
          severity={severity.state}
          toastMessage={toastMessage.state}
          toastOpen={toastOpen}
        />
      </Stack>
    </Fragment>
  );
}

export default ProfilePicture;
