import React, { Fragment } from "react";
import {
  Divider,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";
import { formattedDate, formattedTime } from "components/common/GetDateAndTime";

function createData(date, activity) {
  return { date, activity };
}

const rows = [
  createData(
    formattedDate("2016-08-29T12:24:45Z") +
      " " +
      formattedTime("2016-08-29T12:24:45Z"),
    "Mohammed Shalu Uploaded Document."
  ),
];

function ViewDocumentActivity({ open }) {
  return (
    <Fragment>
      <Grid pb={1}>
        <Typography variant="font15b">Document Activity</Typography>
      </Grid>
      <Divider />

      <TableContainer component={Box}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.activity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />
      <Grid container py={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => open.update(false)}
          >
            <Typography variant="font14b" color="#000" mx={{ xs: 0, sm: 3 }}>
              Close
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ViewDocumentActivity;
