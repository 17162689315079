import React, { Fragment, useContext, useEffect } from "react";
import { ProfileContext } from "AppContext";
import { Box, Grid, AccordionDetails } from "@mui/material";
import { getProfileOrders } from "service/ProfileService";
import OrderTable from "components/dashboard/sections/OrderTable";
import { useStateUpdate } from "UseStateUpdate";
import { formattedDate, formattedTime } from "components/common/GetDateAndTime";
import { createOrdersData } from "components/common/CreateObjects";

function ProfileOrdersView() {
  const profileValue = useContext(ProfileContext);
  const { profileDatas } = profileValue;
  const fetchedData = useStateUpdate([]);
  const pageCount = useStateUpdate(0);
  const dataForTable = useStateUpdate([]);
  const currentPage = useStateUpdate(1);
  const perPage = useStateUpdate(10);

  useEffect(() => {
    fetchProfileOrders();
  }, [profileDatas, currentPage.state]);

  const fetchProfileOrders = async () => {
    var result = await getProfileOrders(
      profileDatas.profileId,
      perPage.state,
      currentPage.state
    );
    if (result.status === 200) {
      fetchedData.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
    }
  };

  useEffect(() => {
    createDataForTable();
  }, [fetchedData.state]);

  const createDataForTable = () => {
    var rows = [];
    fetchedData.state.forEach((rowData) => {
      rows.push(
        createOrdersData(
          "open",
          rowData.orderId,
          rowData.orderNumber,
          rowData.status,
          rowData.hasNotes,
          rowData.hasDocuments,
          rowData.signingType,
          formattedDate(rowData.signingAt),
          formattedTime(rowData.signingAt),
          rowData.signerName,
          rowData.signerPhoneNumber,
          rowData.originatorName,
          rowData.originatorEmail,
          rowData.originatingCompany,
          rowData.originatingCompanyPhoneNumber,
          rowData.fileNumber,
          rowData.notaries === undefined || rowData.notaries.length === 0
            ? ""
            : rowData.notaries[0].name,
          rowData.notaries === undefined || rowData.notaries.length === 0
            ? ""
            : rowData.notaries[0].phoneNumber,
          rowData.scheduler,
          rowData.signingAgencyName
        )
      );
    });
    dataForTable.update(rows);
  };

  return (
    <Fragment>
      <AccordionDetails>
        <Box sx={{ p: 3, width: { xs: "84vw", md: "100%" } }}>
          <OrderTable
            tableRows={dataForTable.state}
            buttonColumn={false}
            label={"Search Results"}
            pageCount={pageCount.state}
            page={currentPage}
            isSearchResult={true}
            viewAsNotaryAdmin={false}
          />
        </Box>
      </AccordionDetails>
    </Fragment>
  );
}

export default ProfileOrdersView;
