import React, { useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import OrderSummary from "./sections/OrderSummary";
import OrdersSections from "./sections/OrdersSections";
import { useStateUpdate } from "UseStateUpdate";
import { getProfileId } from "components/common/GetUserDetail";
import { getOrders } from "service/ProfileService";
import { isNotEmpty } from "components/common/IsEmptyObject";
import { createOrdersTabs } from "components/common/CreateObjects";
import { createOrdersData } from "components/common/CreateObjects";
import { formattedDate, formattedTime } from "components/common/GetDateAndTime";
import Toast from "components/common/Toast";
import { useNavigate } from "react-router-dom";
import { useIsNotary } from "components/common/GetUserDetail";

const emptyOrder = {
  records: [],
  pagination: {
    total_pages: 0,
  },
};

function NotaryDashboard() {
  let navigate = useNavigate();
  const isNotary = useIsNotary();
  const orderDatas = useStateUpdate({});
  const sections = useStateUpdate([]);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const perPage = useStateUpdate(10);
  const pendingCurrentPage = useStateUpdate(1);
  const unscheduledCurrentPage = useStateUpdate(1);
  const completedCurrentPage = useStateUpdate(1);
  const scheduledTodayCurrentPage = useStateUpdate(1);
  const scheduledTomorrowCurrentPage = useStateUpdate(1);

  useEffect(() => {
    isNotary ? fetchOrders() : navigate("/admin-dashboard");
  }, [
    pendingCurrentPage.state,
    unscheduledCurrentPage.state,
    completedCurrentPage.state,
    scheduledTodayCurrentPage.state,
    scheduledTomorrowCurrentPage.state,
  ]);

  const fetchOrders = async () => {
    let fetchDatas = {
      pending: {},
      unscheduled: {},
      completed: {},
      scheduledToday: {},
      scheduledTomorrow: {},
    };
    let pendingData = await fetchOrder(
      "pending_signing",
      pendingCurrentPage.state
    );
    let unscheduledData = await fetchOrder(
      "unscheduled_signing",
      unscheduledCurrentPage.state
    );
    let completedData = await fetchOrder(
      "completed",
      completedCurrentPage.state
    );
    let scheduledTodayData = await fetchOrder(
      "scheduled_today",
      scheduledTodayCurrentPage.state
    );
    let scheduledTomorrowData = await fetchOrder(
      "scheduled_tomorrow",
      scheduledTomorrowCurrentPage.state
    );
    fetchDatas = {
      pending: pendingData,
      unscheduled: unscheduledData,
      completed: completedData,
      scheduledToday: scheduledTodayData,
      scheduledTomorrow: scheduledTomorrowData,
    };
    orderDatas.update(fetchDatas);
  };

  const fetchOrder = async (searchType, currentPage) => {
    var result = await getOrders(
      getProfileId(),
      perPage.state,
      searchType,
      currentPage
    );
    return result.status === 200 ? result.data.data : emptyOrder;
  };

  const sectionParams = { severity, toastMessage, toastOpen, fetchOrders };

  const createRow = (rowData) => {
    let addressIs =
      rowData.signingAddress.length > 0 ? rowData.signingAddress[0] : false;
    return createOrdersData(
      "open",
      rowData.orderId,
      rowData.orderNumber,
      rowData.status,
      rowData.hasNotes,
      rowData.hasDocuments,
      rowData.signingType,
      formattedDate(rowData.signingAt),
      formattedTime(rowData.signingAt),
      rowData.signerName,
      rowData.signerPhoneNumber,
      rowData.originatorName,
      rowData.originatorEmail,
      rowData.originatingCompany,
      rowData.originatingCompanyPhoneNumber,
      rowData.fileNumber,
      rowData.notaries === undefined ? "" : rowData.notaries[0].name,
      rowData.notaries === undefined ? "" : rowData.notaries[0].phoneNumber,
      rowData.scheduler,
      rowData.signingAgencyName,
      addressIs &&
        "[" +
          addressIs.addressType +
          "] " +
          addressIs.streetAddress1 +
          ", " +
          // addressIs.streetAddress2 +
          // ", " +
          addressIs.city +
          ", " +
          addressIs.state +
          " " +
          addressIs.zip +
          ", " +
          addressIs.county,
      addressIs && addressIs.lat,
      addressIs && addressIs.long
    );
  };

  useEffect(() => {
    if (isNotEmpty(orderDatas.state)) {
      var orders = orderDatas.state;
      var pendingRowDatas = [];
      var unscheduledRowDatas = [];
      var todayRowDatas = [];
      var tomorrowRowDatas = [];
      var completedRowDatas = [];

      orders.pending.records.map((rowData) => {
        pendingRowDatas.push(createRow(rowData));
        return null;
      });
      orders.unscheduled.records.map((rowData) => {
        unscheduledRowDatas.push(createRow(rowData));
        return null;
      });
      orders.completed.records.map((rowData) => {
        completedRowDatas.push(createRow(rowData));
        return null;
      });
      orders.scheduledToday.records.map((rowData) => {
        todayRowDatas.push(createRow(rowData));
        return null;
      });
      orders.scheduledTomorrow.records.map((rowData) => {
        tomorrowRowDatas.push(createRow(rowData));
        return null;
      });
      var pendingUnscheduledSections = [
        createOrdersTabs(
          "Pending signings",
          orders.pending.pagination.total,
          pendingRowDatas,
          orders.pending.pagination.total_pages,
          pendingCurrentPage,
          true
        ),
        createOrdersTabs(
          "Unscheduled signings",
          orders.unscheduled.pagination.total,
          unscheduledRowDatas,
          orders.unscheduled.pagination.total_pages,
          unscheduledCurrentPage
        ),
      ];
      var scheduledForTodayTomorrowSections = [
        createOrdersTabs(
          "Scheduled for today",
          orders.scheduledToday.pagination.total,
          todayRowDatas,
          orders.scheduledToday.pagination.total_pages,
          scheduledTodayCurrentPage
        ),
        createOrdersTabs(
          "Scheduled for tomorrow",
          orders.scheduledTomorrow.pagination.total,
          tomorrowRowDatas,
          orders.scheduledTomorrow.pagination.total_pages,
          scheduledTomorrowCurrentPage
        ),
      ];
      var completedSections = [
        createOrdersTabs(
          "Completed",
          orders.completed.pagination.total,
          completedRowDatas,
          orders.completed.pagination.total_pages,
          completedCurrentPage
        ),
      ];

      sections.update([
        pendingUnscheduledSections,
        scheduledForTodayTomorrowSections,
        completedSections,
      ]);
    }
  }, [orderDatas.state]);

  return isNotary ? (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b">Notary Dashboard</Typography>
      </Grid>
      <Stack spacing={2}>
        <OrderSummary />
        {sections.state.length > 0
          ? sections.state.map((section, index) => (
              <OrdersSections
                key={index}
                section={section}
                viewAsNotaryAdmin={false}
                {...sectionParams}
              />
            ))
          : [1, 2, 3].map((index) => (
              <OrdersSections key={index} section={sections.state} />
            ))}
      </Stack>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  ) : null;
}

export default NotaryDashboard;
