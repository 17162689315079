import React, { Fragment, useEffect } from "react";
import {
  Paper,
  Box,
  Grid,
  Typography,
  Stack,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { deleteNoteTemplates } from "service/TemplatesService";
import { useStateUpdate } from "UseStateUpdate";
import ConfirmationModal from "components/common/ConfirmationModal";

function NoteTemplatesList(props) {
  const {
    templates,
    expanded,
    setEditNoteTemplate,
    fetchNoteTemplates,
    currentTemplateId,
    editTemplate,
    noteTemplates,
  } = props;

  const modalIcon = useStateUpdate("warning");
  const modalHeading = useStateUpdate("Delete");
  const modalMessage = useStateUpdate("Are you sure to delete this note?");
  const modalApprove = useStateUpdate("Delete");
  const modalCancel = useStateUpdate("Cancel");
  const openModal = useStateUpdate(false);
  const modalType = useStateUpdate(null);

  const templateToDelete = useStateUpdate(null);

  useEffect(() => {
    currentTemplateId.state && noteTemplates.update([]);
    currentTemplateId.state && fetchNoteTemplates(currentTemplateId.state);
  }, [currentTemplateId.state]);

  const callDeleteNoteTemplates = async (templateId, noteTemplateId) => {
    var result = await deleteNoteTemplates(templateId, noteTemplateId);
    result.status === 200 && fetchNoteTemplates(templateId);
  };

  const handleChange = (templateId) => (event, newExpanded) => {
    newExpanded && currentTemplateId.update(templateId);
    expanded.update(newExpanded ? templateId : false);
  };

  const handleEdit = (templateIdIs, noteTemplate) => {
    setEditNoteTemplate({ templateId: templateIdIs, ...noteTemplate });
    editTemplate.update(true);
  };

  const handleDelete = (templateId, noteTemplateId) => {
    templateToDelete.update({
      templateId: templateId,
      noteTemplateId: noteTemplateId,
    });
    openModal.update(true);
  };

  const callConfirm = (type) => {
    callDeleteNoteTemplates(
      templateToDelete.state.templateId,
      templateToDelete.state.noteTemplateId
    );
    openModal.update(false);
  };

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <ConfirmationModal
        open={openModal}
        type={modalType.state}
        onConfirm={callConfirm}
        icon={modalIcon.state}
        heading={modalHeading.state}
        message={modalMessage.state}
        approveLabel={modalApprove.state}
        cancelLabel={modalCancel.state}
      />
      <Box sx={{ width: "100%" }} p={2}>
        <Stack spacing={3}>
          <Typography variant="font15b" color="#686868">
            Note Templates
          </Typography>
          {templates.map((template) => (
            <Accordion
              key={template.templateId}
              expanded={expanded.state === template.templateId}
              onChange={handleChange(template.templateId)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="font12b">
                  {template.templateName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  {noteTemplates.state.map((noteTemplate, index) => (
                    <Fragment key={index}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Stack>
                            <Typography variant="font12b">
                              {noteTemplate.title}
                            </Typography>
                            <Typography variant="font12">
                              {noteTemplate.body}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Fragment>
                            <IconButton
                              fontSize="small"
                              color="secondary"
                              onClick={() =>
                                handleEdit(template.templateId, noteTemplate)
                              }
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              fontSize="small"
                              color="error"
                              onClick={() =>
                                handleDelete(
                                  template.templateId,
                                  noteTemplate.noteTemplateId
                                )
                              }
                            >
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                          </Fragment>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Fragment>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Box>
    </Paper>
  );
}

export default NoteTemplatesList;
