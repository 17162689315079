export const formattedDate = (dateIs, type) => {
  if (dateIs === "" || dateIs === null) return "";
  var dateIs = dateIs.replace(/-/g, "/");
  dateIs = dateIs.replace(/T/g, " ");
  dateIs = dateIs.replace(/Z/g, " ");
  let date = new Date(dateIs.replace(/-/g, "/"));
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = date.getFullYear();
  return type === "input"
    ? yyyy + "-" + mm + "-" + dd
    : mm + "-" + dd + "-" + yyyy;
};

export const formattedTime = (dateIs, type) => {
  if (dateIs === "" || dateIs === null) return "";
  var dateIs = dateIs.replace(/-/g, "/");
  dateIs = dateIs.replace(/T/g, " ");
  dateIs = dateIs.replace(/Z/g, " ");
  let date = new Date(dateIs.replace(/-/g, "/"));
  let hours = date.getHours();
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let timeIs = "";
  if (type !== "input") {
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    timeIs = hours + ":" + minutes + " " + ampm;
  } else {
    hours = hours < 10 ? "0" + hours : hours;
    timeIs = hours + ":" + minutes;
  }

  return timeIs;
};

export const getLastActiveAt = (lastActiveAt) => {
  var lastActiveIs = "";
  var date1 = new Date(lastActiveAt);
  var date2 = new Date();
  const diffTime = Math.abs(date2 - date1);

  const diffSeconds = Math.floor((diffTime / 1000) % 60);
  const diffMinutes = Math.floor((diffTime / 1000 / 60) << 0);
  const diffHours = Math.floor(diffMinutes / 60);

  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 31);
  const diffYear = Math.floor(diffMonths / 12);

  lastActiveIs =
    diffYear >= 1
      ? diffYear + "year ago"
      : diffMonths >= 1
      ? diffMonths + " month ago"
      : diffWeeks >= 1
      ? diffWeeks + " weeks ago"
      : diffDays >= 1
      ? diffDays + " days ago"
      : diffHours >= 1
      ? diffHours + " hours ago"
      : diffMinutes >= 1
      ? diffMinutes + " minutes ago"
      : isNaN(diffSeconds)
      ? lastActiveAt
      : diffSeconds + " seconds ago";

  return lastActiveIs;
};
