import { DOCUMENT_TYPE_CODES } from "components/common/Constant";

export const calculatePercentage = (profileDatas) => {
  return {
    basicProfile: basicProfilePercentage(profileDatas),
    personalDetails: personalDetailsPercentage(profileDatas),
    credentials: credentialsPercentage(profileDatas),
  };
};

function basicProfilePercentage(profileDatas) {
  let basicProfilePercentage = 0;
  let toComplete = [];
  profileDatas.firstName
    ? basicProfilePercentage++
    : toComplete.push("Add First name");
  profileDatas.lastName
    ? basicProfilePercentage++
    : toComplete.push("Add Last name");
  profileDatas.photoUrl
    ? basicProfilePercentage++
    : toComplete.push("Add Profile picture");
  profileDatas.eMail
    ? basicProfilePercentage++
    : toComplete.push("Add your Email");
  profileDatas.emailVerifiedAt
    ? basicProfilePercentage++
    : toComplete.push("Email is not verified");
  profileDatas.smsNumber
    ? basicProfilePercentage++
    : toComplete.push("Add SMS number");
  profileDatas.phoneVerifiedAt
    ? basicProfilePercentage++
    : toComplete.push("SMS number is not verified");
  basicProfilePercentage *= 100 / 7;
  var basicProfilePercentageDetail = {
    percentage: Math.trunc(basicProfilePercentage),
    toComplete: toComplete,
  };
  return basicProfilePercentageDetail;
}

function personalDetailsPercentage(profileDatas) {
  let personalDetailsPercentage = 0;
  let toComplete = [];

  if (profileDatas.addresses.length > 0) {
    profileDatas.addresses[0].streetAddress1
      ? personalDetailsPercentage++
      : toComplete.push("Add Street Address 1");
    profileDatas.addresses[0].city
      ? personalDetailsPercentage++
      : toComplete.push("Add City");
    profileDatas.addresses[0].state
      ? personalDetailsPercentage++
      : toComplete.push("Add State");
    profileDatas.addresses[0].county
      ? personalDetailsPercentage++
      : toComplete.push("Add County");
    profileDatas.addresses[0].zip
      ? personalDetailsPercentage++
      : toComplete.push("Add Zip code");
  } else {
    toComplete.push("Add Street Address 1");
    toComplete.push("Add City");
    toComplete.push("Add State");
    toComplete.push("Add County");
    toComplete.push("Add Zip code");
  }

  profileDatas.nameOnCheque
    ? personalDetailsPercentage++
    : toComplete.push("Who should we make your check out to?");

  profileDatas.primaryNumber
    ? personalDetailsPercentage++
    : toComplete.push("Add Primary number");

  personalDetailsPercentage *= 100 / 7;

  var personalProfilePercentageDetail = {
    percentage: Math.trunc(personalDetailsPercentage),
    toComplete: toComplete,
  };
  return personalProfilePercentageDetail;
}

function credentialsPercentage(profileDatas) {
  let credentialsPercentage = 0;
  let toComplete = [
    "Add NPC Expiration document",
    "Add NPC Expiration document date",
    "Add E and O Insurance document",
    "Add E and O Insurance document date",
    "Add FNTG document",
    "Add FNTG document date",
    "Add Background screening document",
    "Add Background screening document date",
    "Add W9 document",
    "Add W9 document date",
    "Add Identification document",
    "Add Identification document date",
  ];

  const documentTypeCodes = DOCUMENT_TYPE_CODES;
  profileDatas.documents.forEach((file) => {
    switch (file.documentTypeCode) {
      case documentTypeCodes.npcExpiration:
        credentialsPercentage++;
        toComplete = toComplete.filter(
          (item) => item !== "Add NPC Expiration document"
        );
        if (file.expiresAt) {
          credentialsPercentage++;
          toComplete = toComplete.filter(
            (item) => item !== "Add NPC Expiration document date"
          );
        }
        break;
      case documentTypeCodes.eandOInsurance:
        credentialsPercentage++;
        toComplete = toComplete.filter(
          (item) => item !== "Add E and O Insurance document"
        );
        if (file.expiresAt) {
          credentialsPercentage++;
          toComplete = toComplete.filter(
            (item) => item !== "Add E and O Insurance document date"
          );
        }
        break;
      case documentTypeCodes.fntgDocuments:
        credentialsPercentage++;
        toComplete = toComplete.filter((item) => item !== "Add FNTG document");
        if (file.expiresAt) {
          credentialsPercentage++;
          toComplete = toComplete.filter(
            (item) => item !== "Add FNTG document date"
          );
        }
        break;
      case documentTypeCodes.backgroundDocuments:
        credentialsPercentage++;
        toComplete = toComplete.filter(
          (item) => item !== "Add Background screening document"
        );
        if (file.expiresAt) {
          credentialsPercentage++;
          toComplete = toComplete.filter(
            (item) => item !== "Add Background screening document date"
          );
        }
        break;
      case documentTypeCodes.w9:
        credentialsPercentage++;
        toComplete = toComplete.filter((item) => item !== "Add W9 document");
        if (file.expiresAt) {
          credentialsPercentage++;
          toComplete = toComplete.filter(
            (item) => item !== "Add W9 document date"
          );
        }
        break;
      case documentTypeCodes.identification:
        credentialsPercentage++;
        toComplete = toComplete.filter(
          (item) => item !== "Add Identification document"
        );
        if (file.expiresAt) {
          credentialsPercentage++;
          toComplete = toComplete.filter(
            (item) => item !== "Add Identification document date"
          );
        }
        break;
      default:
        break;
    }
  });

  credentialsPercentage *= 100 / 12;
  var credentialsPercentageDetail = {
    percentage: Math.trunc(credentialsPercentage),
    toComplete: toComplete,
  };
  return credentialsPercentageDetail;
}
