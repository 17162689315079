import React, { Fragment } from "react";
import {
  Divider,
  Typography,
  Grid,
  Stack,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import { STATES } from "components/common/Constant";
import { isEmptyString } from "components/common/FormValidator";
import { useStateUpdate } from "UseStateUpdate";
import { addOrderAddress } from "service/OrderService";
import Toast from "components/common/Toast";

function AddAddress({ open, fetchOrderDetails, orderDetails }) {
  const states = STATES;
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const isSigningAddress = useStateUpdate(false);
  const address = useStateUpdate({
    addressType: "Office",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    county: "",
    zipCode: "",
  });

  const validationError = useStateUpdate({
    streetAddress1: "",
    city: "",
    state: "",
    county: "",
    zipCode: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      streetAddress1: "",
      city: "",
      state: "",
      county: "",
      zipCode: "",
    };
    if (isEmptyString(address.state.streetAddress1)) {
      newValidationError = {
        ...newValidationError,
        streetAddress1: "Street Address 1 is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.city)) {
      newValidationError = {
        ...newValidationError,
        city: "City is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.state)) {
      newValidationError = {
        ...newValidationError,
        state: "State is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.county)) {
      newValidationError = {
        ...newValidationError,
        county: "County is required",
      };
      isValidated = false;
    }
    if (isEmptyString(address.state.zipCode)) {
      newValidationError = {
        ...newValidationError,
        zipCode: "Zip Code is required",
      };
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  const addNewAddress = async () => {
    var requestBody = {
      addresses: [
        { ...address.state, is_signing_location: isSigningAddress.state },
      ],
    };
    var response = await addOrderAddress(orderDetails.orderId, requestBody);
    if (response.status === 200) {
      fetchOrderDetails();
      calltoast("success", response.data.message);
      open.update(false);
    } else {
      calltoast("error", response.data.message);
    }
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Fragment>
      <Grid pb={1}>
        <Typography variant="font15b">Add address</Typography>
      </Grid>
      <Divider />
      <Grid container py={3} spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl required fullWidth variant="standard" color="secondary">
            <InputLabel>
              <Typography variant="font12">Address type</Typography>
            </InputLabel>
            <Select
              labelId="address-type-select-label"
              id="address-type"
              label={<Typography variant="font12">Address type</Typography>}
              value={address.state.addressType}
              onChange={(e) => {
                address.update({
                  ...address.state,
                  addressType: e.target.value,
                });
              }}
            >
              {["Signing location", "Office", "Home", "Primary", "Other"].map(
                (type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={isSigningAddress.state}
                onChange={(e) => {
                  isSigningAddress.update(e.target.checked);
                }}
              />
            }
            label={
              <Stack>
                <Typography variant="font12">Is a signing location</Typography>
              </Stack>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={validationError.state.streetAddress1 === "" ? false : true}
            helperText={validationError.state.streetAddress1}
            id="Street-Address-1"
            label={<Typography variant="font12">Street Address 1</Typography>}
            variant="standard"
            color="secondary"
            fullWidth
            value={address.state.streetAddress1}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              validationError.update({
                ...validationError.state,
                streetAddress1: "",
              });
              address.update({
                ...address.state,
                streetAddress1: e.target.value,
              });
            }}
            onBlur={(e) => {
              if (isEmptyString(e.target.value)) {
                validationError.update({
                  ...validationError.state,
                  streetAddress1: "Street address 1 is required",
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="Street-Address-2"
            label={<Typography variant="font12">Street Address 2</Typography>}
            variant="standard"
            color="secondary"
            fullWidth
            value={address.state.streetAddress2}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              address.update({
                ...address.state,
                streetAddress2: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            error={validationError.state.city === "" ? false : true}
            helperText={validationError.state.city}
            id="city"
            label={<Typography variant="font12">City</Typography>}
            variant="standard"
            color="secondary"
            fullWidth
            value={address.state.city}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              validationError.update({
                ...validationError.state,
                city: "",
              });
              address.update({
                ...address.state,
                city: e.target.value,
              });
            }}
            onBlur={(e) => {
              if (isEmptyString(e.target.value)) {
                validationError.update({
                  ...validationError.state,
                  city: "City is required",
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl required fullWidth variant="standard" color="secondary">
            <InputLabel
              error={validationError.state.state === "" ? false : true}
            >
              <Typography variant="font12">State</Typography>
            </InputLabel>
            <Select
              error={validationError.state.state === "" ? false : true}
              labelId="state-select-label"
              id="state"
              label={<Typography variant="font12">State</Typography>}
              value={address.state.state}
              onChange={(e) => {
                validationError.update({
                  ...validationError.state,
                  state: "",
                });
                address.update({
                  ...address.state,
                  state: e.target.value,
                });
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  validationError.update({
                    ...validationError.state,
                    state: "State is required",
                  });
                }
              }}
            >
              {states.map((state) => (
                <MenuItem key={state.code} value={state.code}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error={validationError.state.state === "" ? false : true}
            >
              {validationError.state.state}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            error={validationError.state.county === "" ? false : true}
            helperText={validationError.state.county}
            id="county"
            label={<Typography variant="font12">County</Typography>}
            variant="standard"
            color="secondary"
            fullWidth
            value={address.state.county}
            inputProps={{ maxLength: 35 }}
            onChange={(e) => {
              validationError.update({
                ...validationError.state,
                county: "",
              });
              address.update({
                ...address.state,
                county: e.target.value,
              });
            }}
            onBlur={(e) => {
              if (isEmptyString(e.target.value)) {
                validationError.update({
                  ...validationError.state,
                  county: "County is required",
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            error={validationError.state.zipCode === "" ? false : true}
            helperText={validationError.state.zipCode}
            id="zip-code"
            label={<Typography variant="font12">Zip Code</Typography>}
            variant="standard"
            color="secondary"
            fullWidth
            value={address.state.zipCode}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => {
              validationError.update({
                ...validationError.state,
                zipCode: "",
              });
              address.update({
                ...address.state,
                zipCode: e.target.value.replace(/\D/g, ""),
              });
            }}
            onBlur={(e) => {
              if (isEmptyString(e.target.value)) {
                validationError.update({
                  ...validationError.state,
                  zipCode: "Zip Code is required",
                });
              }
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container py={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems="center"
        >
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => open.update(false)}
            >
              <Typography variant="font14b" color="#000" mx={{ xs: 0, sm: 3 }}>
                Cancel
              </Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={(event) => {
                checkValidatorOnSubmit() && addNewAddress();
              }}
            >
              <Typography variant="font14b" mx={{ xs: 0, sm: 3 }}>
                Add address
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default AddAddress;
