import React, { Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { logout, useAuth } from "service/AuthProvider";
//materialUi
import {
  Button,
  Toolbar,
  IconButton,
  ThemeProvider,
  Container,
  Grid,
  Stack,
  MenuItem,
  ListItemIcon,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FlagOutlined,
  NotificationsNone,
  PermIdentityOutlined,
} from "@material-ui/icons";

import LogoutIcon from "@mui/icons-material/Logout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import Settings from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DashboardIcon from "@mui/icons-material/Dashboard";

import logo from "logo.svg";

import { toolbarTheme } from "components/common/Theme";

import { AppBar, menuProps } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import { NORMAL_PAGES } from "components/common/Constant";
import { useIsNotary } from "components/common/GetUserDetail";
import ConfirmationModal from "components/common/ConfirmationModal";
import { masqueradeUser } from "service/AdminService";
import { getProfileId } from "components/common/GetUserDetail";
const pages = NORMAL_PAGES;

function NssToolbar(props) {
  const navigate = useNavigate();
  const isNotary = useIsNotary();
  const { pathname } = useLocation();
  const { open } = props;
  const isMenuIcon = useStateUpdate(false);
  const notificationMenu = useStateUpdate(null);
  const flagMenu = useStateUpdate(null);
  const accountMenu = useStateUpdate(null);
  const logoutMenu = useStateUpdate(null);
  const isAuthenticated = useAuth();

  const modalIcon = useStateUpdate("");
  const modalHeading = useStateUpdate("");
  const modalMessage = useStateUpdate("");
  const modalApprove = useStateUpdate("");
  const modalCancel = useStateUpdate("Cancel");
  const openModal = useStateUpdate(false);
  const modalType = useStateUpdate(null);

  useEffect(() => {
    pages.indexOf(pathname) > -1 ||
    pathname.indexOf("/reset-password") > -1 ||
    pathname.indexOf("/registration") > -1
      ? isMenuIcon.update(false)
      : isMenuIcon.update(true);
  }, [pathname]);

  const callConfirm = (type) => {
    openModal.update(false);
    type === "logoutUser" && logOutUser();
    type === "switchToAdmin" && handleMasqueradeClick();
  };

  const logOutUser = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("adminId");
    logout();
    navigate("/login");
    window.location.reload();
  };

  const handleMasqueradeClick = async () => {
    const notaryId = getProfileId();
    const adminId = localStorage.getItem("adminId");
    var result = await masqueradeUser(adminId);
    if (result.status === 200) {
      localStorage.removeItem("adminId");
      navigate("/profile/" + notaryId);
    }
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={openModal}
        type={modalType.state}
        onConfirm={callConfirm}
        icon={modalIcon.state}
        heading={modalHeading.state}
        message={modalMessage.state}
        approveLabel={modalApprove.state}
        cancelLabel={modalCancel.state}
      />
      <ThemeProvider theme={toolbarTheme}>
        <AppBar
          position="sticky"
          open={isMenuIcon.state ? open.state : false}
          color="primary"
        >
          <Toolbar>
            <Container maxWidth={isMenuIcon.state ? "1536" : "lg"}>
              <Grid container item xs={12}>
                <Grid container item xs={6} alignItems="center">
                  {isMenuIcon.state ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => open.update(!open.state)}
                      edge="start"
                      sx={{
                        mr: 2,
                        display: open.state && { xs: "none", md: "block" },
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <img
                      src={logo}
                      alt="logo"
                      onClick={() => navigate("/", { replace: true })}
                    />
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={{ xs: 0, sm: 1, md: 2 }}>
                    {isAuthenticated && (
                      <Fragment>
                        {isNotary && (
                          <Tooltip title="Flag">
                            <IconButton
                              onClick={(e) => flagMenu.update(e.currentTarget)}
                              size="small"
                              color="inherit"
                              aria-controls={
                                Boolean(flagMenu.state)
                                  ? "flag-menu"
                                  : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                Boolean(flagMenu.state) ? "true" : undefined
                              }
                            >
                              <FlagOutlined />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isNotary && (
                          <Tooltip title="Notification">
                            <IconButton
                              onClick={(e) =>
                                notificationMenu.update(e.currentTarget)
                              }
                              size="small"
                              color="inherit"
                              aria-controls={
                                Boolean(notificationMenu.state)
                                  ? "notification-menu"
                                  : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                Boolean(notificationMenu.state)
                                  ? "true"
                                  : undefined
                              }
                            >
                              <NotificationsNone />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Account">
                          <IconButton
                            onClick={(e) => accountMenu.update(e.currentTarget)}
                            size="small"
                            color="inherit"
                            aria-controls={
                              Boolean(accountMenu.state)
                                ? "account-menu"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(accountMenu.state) ? "true" : undefined
                            }
                          >
                            <PermIdentityOutlined />
                          </IconButton>
                        </Tooltip>
                      </Fragment>
                    )}

                    {useAuth() ? (
                      <Button
                        color="inherit"
                        onClick={(e) =>
                          localStorage.getItem("adminId")
                            ? logoutMenu.update(e.currentTarget)
                            : openlogOutUserModal()
                        }
                      >
                        <Typography variant="font13" noWrap>
                          Log out
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        color="inherit"
                        onClick={() => navigate("/login", { replace: true })}
                      >
                        <Typography variant="font13" noWrap>
                          Log in
                        </Typography>
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={notificationMenu.state}
          id="notification-menu"
          open={Boolean(notificationMenu.state)}
          onClose={() => notificationMenu.update(null)}
          onClick={() => notificationMenu.update(null)}
          PaperProps={menuProps.paperProps}
          transformOrigin={menuProps.transformOrigin}
          anchorOrigin={menuProps.anchorOrigin}
        >
          <MenuItem onClick={() => navigate("/notifications")}>
            <ListItemIcon>
              <NotificationsIcon fontSize="small" />
            </ListItemIcon>
            Notifications
          </MenuItem>
          <MenuItem onClick={() => navigate("/notifications/preferences")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Notification Preferences
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={accountMenu.state}
          id="account-menu"
          open={Boolean(accountMenu.state)}
          onClose={() => accountMenu.update(null)}
          onClick={() => accountMenu.update(null)}
          PaperProps={menuProps.paperProps}
          transformOrigin={menuProps.transformOrigin}
          anchorOrigin={menuProps.anchorOrigin}
        >
          <MenuItem onClick={() => navigate("/dashboard")}>
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            Dashboard
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={logoutMenu.state}
          id="account-menu"
          open={Boolean(logoutMenu.state)}
          onClose={() => logoutMenu.update(null)}
          onClick={() => logoutMenu.update(null)}
          PaperProps={menuProps.paperProps}
          transformOrigin={menuProps.transformOrigin}
          anchorOrigin={menuProps.anchorOrigin}
        >
          <MenuItem onClick={() => openSwitchUserModal()}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            Switch to admin
          </MenuItem>
          <MenuItem onClick={() => openlogOutUserModal()}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </Fragment>
  );

  function openlogOutUserModal() {
    openModal.update(true);
    modalIcon.update("warning");
    modalHeading.update("Log out");
    modalMessage.update("Are you sure to logout?");
    modalApprove.update("Log out");
    modalCancel.update("Cancel");
    modalType.update("logoutUser");
  }

  function openSwitchUserModal() {
    openModal.update(true);
    modalIcon.update("warning");
    modalHeading.update("Switch to admin");
    modalMessage.update("Are you sure to switch to admin?");
    modalApprove.update("Switch to admin");
    modalCancel.update("Cancel");
    modalType.update("switchToAdmin");
  }
}

export default NssToolbar;
