import React, { useEffect } from "react";

import {
  Button,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Grid,
  Stack,
  Paper,
  Divider,
  InputBase,
  IconButton,
  TableHead,
  TableSortLabel,
  Box,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { useStateUpdate } from "UseStateUpdate";
import ShowItemsPerPage from "components/common/ShowItemsPerPage";
import {
  WARNING_ORANGE as inactiveColor,
  WARNING_GREY as suspendedColor,
  WARNING_GREEN as activeColor,
  WARNING_RED as deletedColor,
} from "components/common/Constant";
import { rowHoverClickStyle } from "components/common/Styles";
import { formatRating } from "components/common/FormatRating";
import { listNotaryUsers, listNotarySummary } from "service/AdminService";
import CloseIcon from "@mui/icons-material/Close";
import NotaryAdvancedSearch from "./NotaryAdvancedSearch";
import { advanceNotarySearch } from "service/AdminService";
import { getLastActiveAt } from "components/common/GetDateAndTime";
import { quickNotarySearch } from "service/AdminService";

const headCells = [
  {
    id: "first_name",
    label: "Notary Name",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "last_active_at",
    label: "Last activity",
  },
  {
    id: "total_closed_signings",
    label: "Total signing",
  },
  {
    id: "avg_rating",
    label: "Rating",
  },
];

function AdminNotariesTable(props) {
  const { showNotaries, heading, changeHeading, quickSearchParam } = props;
  let navigate = useNavigate();
  const classes = rowHoverClickStyle();
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("desc");
  const orderBy = useStateUpdate("id");
  const notariesList = useStateUpdate([]);
  const filterValue = useStateUpdate("");
  const showAdvanceSearch = useStateUpdate(false);
  const isLoading = useStateUpdate(true);

  const firstName = useStateUpdate("");
  const lastName = useStateUpdate("");
  const city = useStateUpdate("");
  const notaryLanguage = useStateUpdate([]);
  const state = useStateUpdate("");
  const zipCode = useStateUpdate("");
  const minRating = useStateUpdate(1);
  const radius = useStateUpdate("1");
  const streetAddress = useStateUpdate("");
  const county = useStateUpdate("");

  useEffect(() => {
    callFetchNotaries();
  }, [
    rowsPerPage.state,
    page.state,
    orderBy.state,
    order.state,
    showNotaries.state,
  ]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  useEffect(() => {
    filterValue.state === "" && pageCount.state > 0 && callFetchNotaries();
  }, [filterValue.state]);

  const callFetchNotaries = () => {
    showNotaries.state === "showall"
      ? fetchListNotaryUsers()
      : showNotaries.state === "advanceSearch"
      ? fetchListAdvanceSearchNotary()
      : showNotaries.state === "quickSearch"
      ? fetchQuickSearchNotary()
      : fetchListNotarySummary();
  };

  const fetchListNotaryUsers = async () => {
    isLoading.update(true);
    var formData = new FormData();
    formData.append("page", page.state);
    formData.append("limit", rowsPerPage.state);
    formData.append("search", filterValue.state);
    formData.append("sort", order.state.toUpperCase());
    formData.append("orderBy", orderBy.state);
    var result = await listNotaryUsers(formData);
    if (result.status === 200) {
      notariesList.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
    }
    isLoading.update(false);
  };

  const fetchListNotarySummary = async () => {
    isLoading.update(true);
    var result = await listNotarySummary(
      page.state,
      rowsPerPage.state,
      filterValue.state,
      orderBy.state,
      order.state.toUpperCase(),
      showNotaries.state
    );
    if (result.status === 200) {
      notariesList.update(result.data.data.records);
      pageCount.update(result.data.data.pagination.total_pages);
    }
    isLoading.update(false);
  };

  const fetchQuickSearchNotary = async () => {
    let searchParams = getQuickSearchParams();
    var result = await quickNotarySearch(searchParams);
    if (result.status === 200) {
      notariesList.update(result.data.data.data);
      pageCount.update(result.data.data.lastPage);
    }
    isLoading.update(false);
  };

  const getQuickSearchParams = () => {
    var searchParams =
      "quick-search?quick-search-keyword=" +
      quickSearchParam.state +
      "&page=" +
      page.state +
      "&limit=" +
      rowsPerPage.state +
      "&paged=true&order_by=" +
      orderBy.state +
      "&sort_dir=" +
      order.state.toUpperCase();

    return searchParams;
  };

  const fetchListAdvanceSearchNotary = async () => {
    let searchParams = getSearchParams();
    var result = await advanceNotarySearch(searchParams);
    if (result.status === 200) {
      notariesList.update(result.data.data.data);
      pageCount.update(result.data.data.lastPage);
    }
  };

  const getSearchParams = () => {
    var searchParams =
      "search?" +
      (firstName.state !== "" ? "first_name=" + firstName.state + "&" : "") +
      (lastName.state !== "" ? "last_name=" + lastName.state + "&" : "") +
      "min_rating=" +
      minRating.state +
      "&" +
      (notaryLanguage.state.length > 0
        ? "notary_language=" + notaryLanguage.state + "&"
        : "") +
      (streetAddress.state !== ""
        ? "streetAddress=" + streetAddress.state + "&"
        : "") +
      (city.state !== "" ? "city=" + city.state + "&" : "") +
      (county.state !== "" ? "county=" + county.state + "&" : "") +
      (state.state !== "" ? "state=" + state.state + "&" : "") +
      (zipCode.state !== "" ? "zip=" + zipCode.state + "&" : "") +
      "radius=" +
      radius.state +
      "&page=" +
      page.state +
      "&limit=" +
      rowsPerPage.state +
      "&paged=true&order_by=" +
      orderBy.state +
      "&sort_dir=" +
      order.state.toUpperCase();

    return searchParams;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.state === property && order.state === "asc";
    order.update(isAsc ? "desc" : "asc");
    orderBy.update(property);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="font12b" noWrap>
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Grid container p={2}>
          <Grid item xs={6}>
            <Typography variant="font17b" color="secondary.light">
              {heading}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 4 }}
              alignItems="center"
            >
              {showNotaries.state === "showall" && (
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { xs: 250, sm: 350 },
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, width: { xs: 200, sm: 300 } }}
                    placeholder="Search by notary name "
                    value={filterValue.state}
                    onChange={(e) => {
                      filterValue.update(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        event.preventDefault();
                        if (event.target.value !== "") {
                          callFetchNotaries();
                          changeHeading("Notaries");
                          quickSearchParam.update(false);
                        }
                      }
                    }}
                  />
                  {filterValue.state !== "" && (
                    <IconButton
                      onClick={(e) => {
                        filterValue.update("");
                        changeHeading("Notaries");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton
                    sx={{
                      backgroundColor: "secondary.light",
                      borderRadius: "0%",
                    }}
                    onClick={(e) => {
                      if (filterValue.state !== "") {
                        callFetchNotaries();
                        changeHeading("Notaries");
                        quickSearchParam.update(false);
                      }
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              )}

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  showAdvanceSearch.update(!showAdvanceSearch.state);
                  quickSearchParam.update(false);
                }}
              >
                <Typography variant="font14b" color="#000" noWrap>
                  Advanced Search
                </Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>

        {showAdvanceSearch.state && (
          <NotaryAdvancedSearch
            showNotaries={showNotaries}
            firstName={firstName}
            lastName={lastName}
            city={city}
            notaryLanguage={notaryLanguage}
            state={state}
            zipCode={zipCode}
            minRating={minRating}
            radius={radius}
            callSearch={fetchListAdvanceSearchNotary}
            changeHeading={changeHeading}
          />
        )}

        {showNotaries.state !== "showall" && (
          <Grid container justifyContent="flex-end" px={2}>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                showAdvanceSearch.update(false);
                filterValue.update("");
                showNotaries.update("showall");
                quickSearchParam.update(false);
                changeHeading("Notaries");
              }}
            >
              <Typography variant="font12">Show all</Typography>
            </Button>
          </Grid>
        )}

        <Divider />

        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead
                order={order.state}
                orderBy={orderBy.state}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {isLoading.state ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : notariesList.state.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="font15">NO DATA FOUND</Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : (
                  notariesList.state.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        classes={{ hover: classes.hover }}
                        onClick={() => {
                          navigate("/profile/" + row.profileId);
                        }}
                      >
                        <TableCell>
                          <Typography variant="font12">
                            {row.firstName + " " + row.lastName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="font12"
                            color={
                              row.status.toLowerCase() === "active"
                                ? activeColor
                                : row.status.toLowerCase() === "inactive"
                                ? inactiveColor
                                : row.status.toLowerCase() === "deleted"
                                ? deletedColor
                                : suspendedColor
                            }
                          >
                            {row.status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font12">
                            {getLastActiveAt(row.lastActiveAt)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font12">
                            {row.totalClosedSignings}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="font12">
                            {formatRating(row.avgRating)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {notariesList.state.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Grid
                        container
                        direction="row"
                        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        alignItems="center"
                      >
                        <Stack direction="row" spacing={2}>
                          <ShowItemsPerPage
                            perPage={rowsPerPage}
                            items={[10, 20, 50, 100]}
                          />
                          <Pagination
                            count={pageCount.state}
                            showFirstButton
                            showLastButton
                            size="small"
                            onChange={(e, value) => {
                              page.update(value);
                            }}
                          />
                        </Stack>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Divider />
      </Paper>
    </Grid>
  );
}

export default AdminNotariesTable;
